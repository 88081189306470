import {
  Box,
  Grid,
  Stack,
  Typography,
  Paper,
  Button,
  Dialog,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useNavigate, useParams } from "react-router-dom";
import Question from "./Question";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { question_types } from "./Questions";
import { setNotify } from "../../../redux/utils/utilsSlice";
import { useDispatch } from "react-redux";
import Loading from "../../../components/Blockers/Loading";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PreviewQuestions() {
  const { palette } = useTheme();
  const { id, surveyVersionId, surveyId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [survey, setSurvey] = useState({});
  const [loadingSurvey, setLoadingSurvey] = useState(true);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleSave = () => {
    setOpenSaveDialog(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSave = () => {
    setOpenSaveDialog(false);
  };
  console.log(error);

  useEffect(() => {
    setLoadingSurvey(true);
    axios({
      method: "GET",
      url: `${DOMAIN}/surveys/`,
      params: {
        trial_id: id,
        id: surveyId,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    }).then((res) => {
      setSurvey(res.data.payload[0]);
      setLoadingSurvey(false);
    });
  }, [id, surveyId]);

  const handleSubmit = async () => {
    // remove errors from state, if any
    setError(null);
    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/surveys/versions/activate`,
        data: {
          id: surveyVersionId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Survey Activated",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
      navigate(`/trial/${id}/survey`);
    } catch (err) {
      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Something Went Wrong!",
          severity: "error",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    }
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${DOMAIN}/surveys/versions/questions/`,
      params: {
        version_id: surveyVersionId,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    }).then((res) => {
      setQuestions(res.data.payload);
    });
  }, [surveyVersionId]);

  return (
    <Loading loading={loadingSurvey}>
      <Grid
        container
        style={{
          height: "100%",
          // minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* START: Top Nav */}
            <Stack
              direction="column"
              component={Paper}
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: palette.neutral[100],
                zIndex: 101,
              }}
            >
              {/* START: First Row */}
              <Stack
                px="53px"
                py="12px"
                paddingTop="24px"
                paddingBottom="24px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Link
                    key="1"
                    to={`/trial/${id}/survey/${surveyId}/${surveyVersionId}`}
                  >
                    <ArrowBackIcon sx={{ color: palette.neutral.dark }} />
                  </Link>
                  <Typography
                    variant="c20px600"
                    style={{
                      textAlign: "left",
                      color: palette.neutral[900],
                    }}
                  >
                    {survey.title}
                  </Typography>
                </Stack>
              </Stack>
              {/* END: First Row */}
            </Stack>
            {/* END: Top Nav */}
          </Box>
          {/* START:Body*/}
          <Stack
            minHeight="90vh"
            width="100%"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            sx={{ padding: "32px 0px" }}
          >
            {/* START: Body */}
            {questions.length
              ? questions
                  .sort((a, b) => a.order_id - b.order_id)
                  .map((question, index) => (
                    <Question
                      key={question.order_id}
                      index={index}
                      isPreview={true}
                      questions={questions}
                      question={question}
                      question_types={question_types}
                    />
                  ))
              : null}
            {/* END: Body */}
          </Stack>
          <Paper
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              height: "91px",
              overflow: "hidden",
              padding: "24px 48px 24px 48px",
            }}
            elevation={0}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link
                key="1"
                to={`/trial/${id}/survey/${surveyId}/${surveyVersionId}/edit`}
              >
                <Button variant="text" startIcon={<ArrowBackIcon />}>
                  Back to Questions
                </Button>
              </Link>
              <Stack direction="row" alignItems="center" spacing={4}>
                <Button
                  onClick={handleSave}
                  variant="text"
                  endIcon={<FolderOpenOutlinedIcon />}
                >
                  Save
                </Button>
                <BootstrapDialog
                  fullWidth
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={openSaveDialog}
                >
                  <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseSave}
                  >
                    <Typography
                      variant="c18px600"
                      sx={{
                        color: palette.neutral[900],
                      }}
                    >
                      Save
                    </Typography>
                  </BootstrapDialogTitle>
                  <DialogContent dividers>
                    <Typography variant="c16px500" gutterBottom>
                      This survey would be saved but won’t be activated. You can
                      find this survey in “Saved Survey” section, and you can
                      activate it whenever required.
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Link key="1" to={`/trial/${id}/survey`}>
                      <Button
                        endIcon={<SaveOutlinedIcon />}
                        variant="contained"
                        autoFocus
                      >
                        Save
                      </Button>
                    </Link>
                  </DialogActions>
                </BootstrapDialog>
                <Button
                  onClick={handleClickOpen}
                  sx={{
                    padding: "12px 16px 12px 16px",
                    width: "100%",
                    height: "43px",
                    borderRadius: "6px",
                  }}
                  disableElevation
                  variant="contained"
                  endIcon={<SaveOutlinedIcon />}
                >
                  Save & Publish
                </Button>
                <BootstrapDialog
                  fullWidth
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={open}
                >
                  <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                  >
                    <Typography
                      variant="c18px600"
                      sx={{
                        color: palette.neutral[900],
                      }}
                    >
                      Save & Publish
                    </Typography>
                  </BootstrapDialogTitle>
                  <DialogContent dividers>
                    <Typography variant="c16px500" gutterBottom>
                      This survey will be activated and the subjects of this
                      trials will be able to participate in this survey.{" "}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleSubmit}
                      endIcon={<SaveOutlinedIcon />}
                      variant="contained"
                      autoFocus
                    >
                      Save & Publish
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </Stack>
            </Stack>
          </Paper>

          {/* END: Body */}
        </Grid>
      </Grid>
    </Loading>
  );
}
