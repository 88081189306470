import {
  InputLabel,
  Select,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

export const CustomSelect = styled(Select)(({ theme }) => {
  return {
    marginBottom: "20px",
    "label + &": {
      marginTop: theme.spacing(2),
    },
    "& .MuiInputBase-input": {
      borderBottom: "0px",
      fontSize: 16,
      padding: "10px 12px",
      backgroundColor: theme.palette.neutral[100],
      border: `2px solid ${theme.palette.neutral[400]}`,
      borderRadius: "6px",
      "&:focus": {
        borderColor: theme.palette.primary[600],
        boxShadow: `0 0 0 2px #0C6FE280`,
      },
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      padding: 0,
    },
    overflow: "hidden",
    borderRadius: "4px",
  };
});

export const CustomInputLabel = ({ label, required }) => {
  const { palette } = useTheme();

  return (
    <InputLabel
      variant="standard"
      shrink={false}
      style={{
        marginTop: "-30px",
        width: "100%",
      }}
    >
      <Typography
        variant="c16px500"
        style={{
          color: palette.neutral[500],
        }}
      >
        {label}{" "}
        {required && <span style={{ color: palette.neutral[500] }}>*</span>}
      </Typography>
    </InputLabel>
  );
};
