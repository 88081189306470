import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./hoc/ProtectedRoute/Index";
import RedirectIfAuth from "./hoc/RedirectIfAuth/Index";

import urls from "../src/utils/urls.json";

// components
import Layout from "./containers/Layout/Index";

// pages
import EmailVerification from "./containers/Auth/EmailVerification";
import ForgotPassword from "./containers/Auth/ForgotPassword";
import Login from "./containers/Auth/Login";
import ResetPassword from "./containers/Auth/ResetPassword";
import Signup from "./containers/Auth/Signup";
import VerificationEmailSent from "./containers/Auth/VerificationEmailSent";
import ActivateSession from "./containers/Pages/ActivateSession";
import Contact from "./containers/Pages/Contact";
import Profile from "./containers/Pages/Profile";
import Questionnaires from "./containers/Pages/Questionnaires";
import AddQuestionnaire from "./containers/Pages/Questionnaires/AddQuestionnaire";
import Questions from "./containers/Pages/Questionnaires/Questions";
import SessionActivated from "./containers/Pages/SessionActivated";
import SurveyVersion from "./containers/Pages/SurveyVersion";
import AddSurvey from "./containers/Pages/Surveys/AddSurvey";
import AllSurvey from "./containers/Pages/Surveys/AllSurvey";
import PreviewQuestions from "./containers/Pages/Surveys/PreviewQuestions";
import Trial from "./containers/Pages/Trial";
import TrialOverview from "./containers/Pages/TrialOverview/TrialOverview";
import Trials from "./containers/Pages/Trials";
import NewTrial from "./containers/Pages/Trials/NewTrial";
import Onboarding from "./containers/Pages/Trials/Onboarding";

// Providers
import DeviceAndQuestionnaireProvider from "./context/Device&QuestionnaireProvider";
import OnboardingProvider from "./context/OnboardingProvider";
import TaskProvider from "./context/TaskProvider";
import EditQuestionnaire from "./containers/Pages/Questionnaires/EditQuestionnaire";

export default function MyRoutes() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route
            path={urls.activateSession.path}
            element={<ActivateSession />}
          />
          <Route
            path={urls.sessionActivated.path}
            element={<SessionActivated />}
          />
          <Route
            path={urls.home.path}
            element={<ProtectedRoute children={<Trials />} />}
          />
          <Route
            path={urls.newTrial.path}
            element={<ProtectedRoute children={<NewTrial />} />}
          />
          <Route
            path={urls.trialOnboarding.path}
            element={
              <ProtectedRoute
                children={
                  <OnboardingProvider>
                    <Onboarding />
                  </OnboardingProvider>
                }
              />
            }
          />
          <Route
            path={urls.trial.path}
            element={
              <ProtectedRoute
                children={
                  <DeviceAndQuestionnaireProvider>
                    <TaskProvider>
                      <Trial />
                    </TaskProvider>
                  </DeviceAndQuestionnaireProvider>
                }
              />
            }
          />
          <Route
            path={urls.surveys.path}
            element={<ProtectedRoute children={<AllSurvey />} />}
          />
          <Route
            path={urls.surveyVersion.path}
            element={<ProtectedRoute children={<SurveyVersion />} />}
          />
          <Route
            path={urls.newSurvey.path}
            element={<ProtectedRoute children={<AddSurvey />} />}
          />
          <Route
            path={urls.editSurveyVersion.path}
            element={<ProtectedRoute children={<Questions />} />}
          />
          <Route
            path={urls.previewQuestions.path}
            element={<ProtectedRoute children={<PreviewQuestions />} />}
          />
          <Route
            path={urls.trialOverview.path}
            element={<ProtectedRoute children={<TrialOverview />} />}
          />

          <Route
            path={urls.questionnaires.path}
            element={<ProtectedRoute children={<Questionnaires />} />}
          />
          <Route
            path={urls.addQuestionnaire.path}
            element={<ProtectedRoute children={<AddQuestionnaire />} />}
          />
          <Route
            path={urls.editQuestionnaire.path}
            element={<ProtectedRoute children={<EditQuestionnaire />} />}
          />
          <Route
            path={urls.questions.path}
            element={<ProtectedRoute children={<Questions />} />}
          />
          <Route
            path={urls.profile.path}
            element={<ProtectedRoute children={<Profile />} />}
          />
          <Route
            path={urls.contact.path}
            element={<ProtectedRoute children={<Contact />} />}
          />
          <Route
            path={urls.signup.path}
            element={<RedirectIfAuth children={<Signup />} />}
          />
          <Route
            path={urls.verificationEmailSent.path}
            element={<VerificationEmailSent />}
          />
          <Route
            path={urls.emailVerification.path}
            element={<EmailVerification />}
          />
          <Route
            path={urls.forgotPassword.path}
            element={<RedirectIfAuth children={<ForgotPassword />} />}
          />
          <Route
            path={urls.resetPassword.path}
            element={<RedirectIfAuth children={<ResetPassword />} />}
          />
          <Route
            path={urls.login.path}
            element={<RedirectIfAuth children={<Login />} />}
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
