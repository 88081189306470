import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as DotsVerticalIcon } from "../../../assets/trial/dots-vertical.svg";
import {
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/CustomDialog";
import useTasks from "../../../hooks/useTasks";
import { setNotify } from "../../../redux/utils/utilsSlice";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import EditTasks from "../Trial/EditTasks";

const formatDuration = (value) => {
  if (value.includes("-")) {
    const [start, end] = value.split("-").map((num) => `Day ${num}`);
    return `${start} - ${end}`;
  } else {
    return `Day ${value}`;
  }
};

const formatTiming = (value) => {
  const times = value.split("-").map((formattedTime) => {
    const [hour, minute] = formattedTime.split(":");
    const hour24 = parseInt(hour);

    let period = hour24 >= 12 ? "PM" : "AM";

    const formattedHour = (hour24 % 12 === 0 ? 12 : hour24 % 12).toString();
    const formattedMinute = minute || "00";

    return `${formattedHour}:${formattedMinute} ${period}`;
  });

  return times.join(" - ");
};

export default function TaskCard(props) {
  const { palette } = useTheme();
  const dispatch = useDispatch();

  const { fetchDevices, fetchQuestionnaires } = useTasks();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openTaskDelete, setOpenTaskDelete] = useState(false);

  const handleOpenTaskDelete = () => {
    setOpenTaskDelete(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTaskDelete = async () => {
    await deleteTask();
    setAnchorEl(null);
  };

  const handleTaskClose = () => {
    setOpenTaskDelete(false);
    setAnchorEl(null);
  };

  const deleteTask = async () => {
    try {
      const res = await axios({
        method: "DELETE",
        url:
          props.deviceOrQuestionnaire === "questionnaire"
            ? `${DOMAIN}/tasks/questionnaires/`
            : `${DOMAIN}/tasks/devices/`,
        params: { id: props.taskId },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      if (res.status === 200 || res.status === 204) {
        if (res.data.message) {
          dispatch(
            setNotify({
              open: true,
              action: res.data.message,
              severity: "info",
              autoHideDuration: 3000,
              vertical: "bottom",
              horizontal: "right",
            })
          );
        } else {
          dispatch(
            setNotify({
              open: true,
              action: "Task deleted successfully!",
              severity: "success",
              autoHideDuration: 3000,
              vertical: "bottom",
              horizontal: "right",
            })
          );
          fetchDevices();
          fetchQuestionnaires();
        }
        handleTaskClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid
      item
      xs={5.89}
      p={2.5}
      maxWidth="650px"
      sx={{
        borderRadius: "6px",
        border: `1px solid ${palette.neutral[300]}`,
        backgroundColor: palette.neutral[100],
        height: "fit-content",
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack spacing={1}>
          <Typography variant="c12px600" color={palette.neutral[500]}>
            Title
          </Typography>
          <Typography variant="c16px600" color={palette.neutral[700]}>
            {props.title}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            variant="c14px600"
            color={palette.neutral[700]}
            sx={{
              padding: "8px",
              borderRadius: "4px",
              backgroundColor: palette.neutral[200],
            }}
          >
            {props.deviceOrQuestionnaire.charAt(0).toUpperCase() +
              props.deviceOrQuestionnaire.slice(1).toLowerCase()}
          </Typography>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              backgroundColor: palette.neutral[200],
              padding: "8px",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: palette.neutral[300],
              },
              "&:clicked": {
                border: "1px solid #BCBCBC",
              },
            }}
          >
            <DotsVerticalIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Stack>
              <EditTasks
                taskId={props.taskId}
                title={props.title}
                instruction={props.description}
                timing={props.timing}
                duration={props.duration}
                taskType={props.deviceOrQuestionnaire}
              />
              <MenuItem onClick={handleOpenTaskDelete}>
                <Stack direction="row" spacing={1}>
                  <ListItemText
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#BD5250",
                    }}
                  >
                    Delete
                  </ListItemText>
                  <ListItemIcon
                    sx={{
                      height: "16px",
                      width: "16px",
                      color: "#BD5250",
                    }}
                  >
                    <DeleteOutlinedIcon />
                  </ListItemIcon>
                </Stack>
              </MenuItem>
            </Stack>
          </Menu>
        </Stack>
      </Stack>
      <Divider sx={{ marginY: "12px" }} />
      <Stack spacing={1.5}>
        <TaskFeature
          label="Task would appear on following days"
          value={props.duration}
          formator={formatDuration}
        />
        <TaskFeature
          label="Task would appear at following timings"
          value={props.timing}
          formator={formatTiming}
        />
      </Stack>
      <Dialog open={openTaskDelete} onClose={handleTaskClose} fullWidth>
        <CustomDialogTitle onClose={handleTaskClose}>
          <Typography variant="c24px600" color="#2D2D2D">
            Delete Task
          </Typography>
        </CustomDialogTitle>
        <CustomDialogContent>
          <Typography variant="c18px500" mb={3}>
            Are you sure you want to delete this task?
          </Typography>
          <Typography variant="c16px500" color="#4A4A4A">
            All the information related to this task will be lost.
          </Typography>
        </CustomDialogContent>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ padding: "24px" }}
          gap="6px"
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ padding: "8px 16px", width: "50%" }}
            onClick={handleTaskClose}
          >
            No
          </Button>
          <Button
            variant="contained"
            sx={{
              padding: "8px 16px",
              backgroundColor: "#BD5250",
              width: "50%",
            }}
            onClick={handleTaskDelete}
          >
            Yes
          </Button>
        </Stack>
      </Dialog>
    </Grid>
  );
}

function TaskFeature(props) {
  const { palette } = useTheme();

  return (
    <Stack spacing={0.5}>
      <Typography variant="c14px500" color={palette.neutral[700]} mb={0.5}>
        {props.label}
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap={1.25}>
        {props.value.map((item, index) => (
          <Typography
            key={index}
            variant="c14px500"
            color={palette.neutral[700]}
            sx={{
              padding: "4px 8px",
              borderRadius: "4px",
              backgroundColor: palette.neutral[200],
            }}
          >
            {props.formator(item)}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
}
