import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { setActiveTab, setNotify } from "../../redux/utils/utilsSlice";

import {
  Container,
  Typography,
  Stack,
  FormControl,
  Button,
} from "@mui/material";
import axios from "axios";

import {
  CustomInputLabel,
  CustomTextField,
} from "../../components/CustomTextField";
import { DOMAIN } from "../../utils/config";

export default function Contact() {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("contact"));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/contact/`,
        data: {
          email,
          phone,
          message,
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Contacted Successfully",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      setEmail("");
      setPhone("");
      setMessage("");
      setLoading(false);
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.message);
        // set error
        setError(error_object.message);
      } catch {
        // default error message
        setError("Error while contacting");
      }
    }
  };

  return (
    <Container
      maxWidth={false}
      style={{
        padding: "48px",
        height: "100vh",
      }}
    >
      <Typography
        data-cy="page__title"
        variant="c32px600"
        style={{
          textAlign: "left",
        }}
      >
        Contact Us
      </Typography>
      <Stack justifyContent="center" alignItems="center">
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{
            paddingTop: "90px",
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <Stack my={2} direction="column" spacing={4}>
            <FormControl>
              <CustomInputLabel label="Email ID" />
              <CustomTextField
                data-cy="input__email"
                fullWidth
                error={Boolean(error)}
                helperText={error}
                variant="filled"
                placeholder="Email ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                InputProps={{ disableUnderline: true }}
                required
              />
            </FormControl>
            <FormControl>
              <CustomInputLabel label="Enter Your Phone No. (Optional)" />
              <CustomTextField
                data-cy="input__phone"
                fullWidth
                error={Boolean(error)}
                helperText={error}
                variant="filled"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone No."
                type="tel"
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
            <FormControl>
              <CustomInputLabel label="Please Write Your Concern" />
              <CustomTextField
                data-cy="input__message"
                multiline
                rows={4}
                fullWidth
                error={Boolean(error)}
                helperText={error}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant="filled"
                placeholder="Your Concern"
                type="text"
                InputProps={{ disableUnderline: true }}
                required
              />
            </FormControl>
            <Button
              data-cy="button__send"
              disabled={loading}
              disableElevation
              type="submit"
              size="large"
              color="primary"
              variant="contained"
            >
              {loading ? "Sending" : "Send"}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
