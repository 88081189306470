import {
  Grid,
  Stack,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Dialog,
  Button,
  FormControlLabel,
  Checkbox,
  Divider,
  Select,
  Chip,
  RadioGroup,
  Radio,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { CustomTextField } from "../../../components/CustomTextField";
import { useState } from "react";
// import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { DOMAIN } from "../../../utils/config";
import { anyValueTrue, getAuthorization } from "../../../utils/helpers";
import { setNotify } from "../../../redux/utils/utilsSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { Close, ArrowForwardOutlined, Add } from "@mui/icons-material";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

// const CustomWidthTooltip = styled(({ className, ...props }) => (
//   <Tooltip
//     title="If enabled, the survey will be active/functional till the next survey appears."
//     {...props}
//     classes={{ popper: className }}
//   />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: 200,
//   },
// });
const timeZones = [
  {
    value: "BEFORE_TEST",
    label: "Before the test starts ",
  },

  {
    value: "AFTER_TEST",
    label: "After the test ends",
  },
  {
    value: "CUSTOM",
    label: "Custom timing ",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  const { palette } = useTheme();

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: palette.neutral[700] }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const defaultWeekFreq = {
  sun: false,
  mon: false,
  tue: false,
  wed: false,
  thur: false,
  fri: false,
  sat: false,
};

export default function AddSurvey() {
  const { palette } = useTheme();
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [addTiming, setAddTiming] = useState("");
  const [displayTime, setDisplayTime] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [timing, setTiming] = useState(timeZones[0].value);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [activeFor, setActiveFor] = useState("");
  const [timeIndication, setTimeIndication] = useState("min");

  // dates
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // frequency
  const [weekFrequency, setWeekFrequency] = useState(defaultWeekFreq);
  const [dayFrequency, setDayFrequency] = useState("");

  // Disable dates before the specified date, excluding that date
  const shouldDisableDate = (date) => {
    const currentDate = new Date(date);
    currentDate.setHours(0, 0, 0, 0);
    const disabledDateTime = new Date(startDate);
    disabledDateTime.setHours(0, 0, 0, 0);
    return currentDate < disabledDateTime;
  };

  // ERRORS
  const [startDateShowError, setStartDateShowError] = useState(false);
  const [endDateShowError, setEndDateShowError] = useState(false);
  const [frequencyShowError, setFrequencyShowError] = useState(false);
  const [timingShowError, setTimingShowError] = useState(false);
  const [activeForShowError, setActiveForShowError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = async (e) => {
    let hasError = false;

    // remove errors from state, if any
    if (!startDate?.isValid()) {
      setStartDateShowError(true);
      hasError = true;
    }
    if (!endDate?.isValid()) {
      setEndDateShowError(true);
      hasError = true;
    }
    if (!anyValueTrue(weekFrequency) && !Boolean(dayFrequency)) {
      setFrequencyShowError(true);
      hasError = true;
    }
    if (displayTime.length < 1) {
      setTimingShowError(true);
      hasError = true;
    }
    if (!activeFor) {
      setActiveForShowError(true);
      hasError = true;
    }

    if (hasError) return;

    setOpenDialog(false);
  };
  // const handleChange = (event) => {
  //   setTime(event.target.value);
  // };
  const handleAddSurvey = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      let metadata = {};

      if (timing === "CUSTOM") {
        let tempActiveFor = activeFor;
        if (timeIndication === "hr") {
          tempActiveFor = activeFor * 60;
        }

        metadata = {
          custom_timing: {
            start_date: moment(startDate).format("DD/MM/YYYY"),
            end_date: moment(endDate).format("DD/MM/YYYY"),
            frequency: {
              week: weekFrequency,
              day: dayFrequency,
            },
            timings: displayTime.map((i) => moment(i).format("hh:mm A")),
            active_for: tempActiveFor,
          },
        };
      }

      const res = await axios({
        method: "POST",
        url: `${DOMAIN}/surveys/`,
        data: {
          title,
          description,
          trial: id,
          timing,
          metadata,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      setLoading(false);

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Successfully added survey",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      navigate(
        `/trial/${id}/survey/${res.data.id}/${res.data.versions[0].id}/edit`
      );
    } catch (err) {
      // set loading to false
      setLoading(false);
      setError("Error while creating survey");
    }
  };
  // For Entering Custom Time
  const addTime = () => {
    if (addTiming !== "") {
      setDisplayTime([...displayTime, addTiming]);
      setAddTiming("");
    }
  };

  return (
    <Grid
      container
      style={{
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          height: "100%",
        }}
      >
        <Box>
          {/* START: Top Nav */}
          <Stack
            direction="column"
            component={Paper}
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: palette.neutral[100],
              zIndex: 101,
            }}
          >
            {/* START: First Row */}
            <Stack
              px={{ xs: "32px", ll: "48px" }}
              py="24px"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                spacing={0.5}
                justifyContent="space-between"
                alignItems="center"
              >
                <Link key="1" to={`/trial/${id}/survey/`}>
                  <Typography
                    variant="c16px500"
                    sx={{
                      color: palette.neutral[500],
                    }}
                  >
                    All Surveys
                  </Typography>
                </Link>
                <Typography
                  variant="c16px500"
                  sx={{
                    color: palette.neutral[500],
                  }}
                >
                  /
                </Typography>
                <Typography
                  variant="c20px600"
                  style={{
                    color: palette.neutral[900],
                  }}
                >
                  New Survey
                </Typography>
              </Stack>
            </Stack>
            {/* END: First Row */}
          </Stack>
          {/* END: Top Nav */}
          {/* START: Body */}
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ paddingTop: "32px" }}
          >
            <Paper
              elevation={0}
              sx={{
                height: "100%",
                padding: "24px 24px 24px 24px",
                width: "40%",
              }}
            >
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={3}
              >
                <Typography
                  variant="c20px600"
                  sx={{
                    color: palette.neutral[900],
                  }}
                >
                  Schedule Survey
                </Typography>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={1}
                  sx={{ width: "100%" }}
                >
                  <form autoComplete="off" onSubmit={handleAddSurvey}>
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        style={{
                          marginTop: "-25px",
                          width: "100%",
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="c14px400"
                            style={{
                              color: palette.neutral[500],
                            }}
                          >
                            Enter the title of the survey
                          </Typography>
                        </Stack>
                      </InputLabel>

                      <CustomTextField
                        data-cy="input__surveyTitle"
                        fullWidth
                        required
                        variant="filled"
                        placeholder="Enter Title"
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        style={{
                          marginTop: "-25px",
                          width: "100%",
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="c14px400"
                            style={{
                              textAlign: "left",
                              color: palette.neutral[500],
                            }}
                          >
                            Enter a short description of the survey
                          </Typography>
                        </Stack>
                      </InputLabel>
                      <CustomTextField
                        data-cy="input__surveyDescription"
                        fullWidth
                        required
                        variant="filled"
                        placeholder="Enter Short Description"
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </FormControl>{" "}
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        style={{
                          marginTop: "-25px",
                          width: "100%",
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="c14px400"
                            style={{
                              textAlign: "left",
                              color: palette.neutral[500],
                            }}
                          >
                            When should this survey appear?{" "}
                          </Typography>
                        </Stack>
                      </InputLabel>

                      <CustomTextField
                        data-cy="select__appear"
                        select
                        value={timing}
                        onChange={(e) => setTiming(e.target.value)}
                        fullWidth
                      >
                        {timeZones.map((option) => (
                          <MenuItem
                            data-cy={`option__${option.value}`}
                            key={option.value}
                            value={option.value}
                            onClick={
                              option.value === "CUSTOM" ? handleClickOpen : null
                            }
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                      <BootstrapDialog
                        fullWidth
                        onClose={handleCloseDialog}
                        aria-labelledby="customized-dialog-title"
                        open={openDialog}
                      >
                        <BootstrapDialogTitle
                          id="customized-dialog-title"
                          onClose={handleCloseDialog}
                        >
                          <Typography
                            data-cy="dialog__title"
                            variant="c18px600"
                            sx={{
                              color: palette.neutral[900],
                            }}
                          >
                            Custom Timing
                          </Typography>
                        </BootstrapDialogTitle>
                        <DialogContent>
                          <Stack
                            sx={{ width: "100%" }}
                            direction="column"
                            spacing={2}
                          >
                            <Stack
                              direction="row"
                              spacing={6}
                              sx={{
                                padding: "12px 8px 4px 8px",
                                border: "1px solid #F7F7F7",
                                height: "100%",
                                width: "100%",
                                borderRadius: "4px",
                              }}
                            >
                              <Stack direction="column" spacing={1}>
                                <Typography
                                  variant="c12px600"
                                  sx={{
                                    color: palette.neutral[500],
                                  }}
                                >
                                  START DATE
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterMoment}
                                >
                                  <DesktopDatePicker
                                    inputFormat="MM/DD/YYYY"
                                    value={startDate}
                                    onChange={(e) => {
                                      setStartDateShowError(false);
                                      setStartDate(e);
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    renderInput={(params) => (
                                      <CustomTextField
                                        data-cy="datepicker__startDate"
                                        fullWidth
                                        required
                                        variant="filled"
                                        type="text"
                                        {...params}
                                      />
                                    )}
                                    disablePast={true}
                                  />
                                </LocalizationProvider>
                                {startDateShowError && (
                                  <Typography
                                    sx={{
                                      fontSize: "10px",
                                      fontWeight: "400",
                                      color: palette.error.main,
                                      lineHeight: "14.4px",
                                    }}
                                  >
                                    Start Date is required
                                  </Typography>
                                )}
                              </Stack>
                              <Stack direction="column" spacing={1}>
                                <Typography
                                  variant="c12px600"
                                  sx={{
                                    color: palette.neutral[500],
                                  }}
                                >
                                  END DATE
                                </Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterMoment}
                                >
                                  <DesktopDatePicker
                                    inputFormat="MM/DD/YYYY"
                                    value={endDate}
                                    onChange={(e) => {
                                      setEndDateShowError(false);
                                      setEndDate(e);
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    renderInput={(params) => (
                                      <CustomTextField
                                        data-cy="datepicker__endDate"
                                        fullWidth
                                        required
                                        variant="filled"
                                        type="text"
                                        {...params}
                                      />
                                    )}
                                    disablePast={true}
                                    shouldDisableDate={shouldDisableDate}
                                  />
                                </LocalizationProvider>
                                {endDateShowError && (
                                  <Typography
                                    sx={{
                                      fontSize: "10px",
                                      fontWeight: "400",
                                      color: palette.error.main,
                                      lineHeight: "14.4px",
                                    }}
                                  >
                                    End Date is required
                                  </Typography>
                                )}
                              </Stack>
                            </Stack>

                            <Stack
                              direction="row"
                              spacing={6}
                              sx={{
                                padding: "12px 8px 4px 8px",
                                border: "1px solid #F7F7F7",
                                height: "100%",
                                width: "100%",
                                borderRadius: "4px",
                              }}
                            >
                              <Stack direction="column" spacing={1}>
                                <Typography
                                  variant="c12px600"
                                  sx={{
                                    color: palette.neutral[500],
                                  }}
                                >
                                  FREQUENCY
                                </Typography>

                                <Stack direction="row">
                                  <FormControlLabel
                                    data-cy="day__sunday"
                                    control={
                                      <Checkbox
                                        checked={weekFrequency.sun}
                                        onChange={() => {
                                          setFrequencyShowError(false);
                                          setDayFrequency("");
                                          setWeekFrequency({
                                            ...weekFrequency,
                                            sun: !weekFrequency.sun,
                                          });
                                        }}
                                        sx={{ color: palette.neutral[500] }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="c12px400"
                                        sx={{
                                          color: palette.neutral[500],
                                        }}
                                      >
                                        Sun
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    data-cy="day__monday"
                                    control={
                                      <Checkbox
                                        checked={weekFrequency.mon}
                                        onChange={() => {
                                          setFrequencyShowError(false);
                                          setDayFrequency("");
                                          setWeekFrequency({
                                            ...weekFrequency,
                                            mon: !weekFrequency.mon,
                                          });
                                        }}
                                        sx={{ color: palette.neutral[500] }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="c12px400"
                                        sx={{
                                          color: palette.neutral[500],
                                        }}
                                      >
                                        Mon
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    data-cy="day__tuesday"
                                    control={
                                      <Checkbox
                                        checked={weekFrequency.tue}
                                        onChange={() => {
                                          setFrequencyShowError(false);
                                          setDayFrequency("");
                                          setWeekFrequency({
                                            ...weekFrequency,
                                            tue: !weekFrequency.tue,
                                          });
                                        }}
                                        sx={{ color: palette.neutral[500] }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="c12px400"
                                        sx={{
                                          color: palette.neutral[500],
                                        }}
                                      >
                                        Tue
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    data-cy="day__wednesday"
                                    control={
                                      <Checkbox
                                        checked={weekFrequency.wed}
                                        onChange={() => {
                                          setFrequencyShowError(false);
                                          setDayFrequency("");
                                          setWeekFrequency({
                                            ...weekFrequency,
                                            wed: !weekFrequency.wed,
                                          });
                                        }}
                                        sx={{ color: palette.neutral[500] }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="c12px400"
                                        sx={{
                                          color: palette.neutral[500],
                                        }}
                                      >
                                        Wed
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    data-cy="day__thursday"
                                    control={
                                      <Checkbox
                                        checked={weekFrequency.thur}
                                        onChange={() => {
                                          setFrequencyShowError(false);
                                          setDayFrequency("");
                                          setWeekFrequency({
                                            ...weekFrequency,
                                            thur: !weekFrequency.thur,
                                          });
                                        }}
                                        sx={{ color: palette.neutral[500] }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="c12px400"
                                        sx={{
                                          color: palette.neutral[500],
                                        }}
                                      >
                                        Thu
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    data-cy="day__friday"
                                    control={
                                      <Checkbox
                                        checked={weekFrequency.fri}
                                        onChange={() => {
                                          setFrequencyShowError(false);
                                          setDayFrequency("");
                                          setWeekFrequency({
                                            ...weekFrequency,
                                            fri: !weekFrequency.fri,
                                          });
                                        }}
                                        sx={{ color: palette.neutral[500] }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="c12px400"
                                        sx={{
                                          color: palette.neutral[500],
                                        }}
                                      >
                                        Fri
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    data-cy="day__saturday"
                                    control={
                                      <Checkbox
                                        checked={weekFrequency.sat}
                                        onChange={() => {
                                          setFrequencyShowError(false);
                                          setDayFrequency("");
                                          setWeekFrequency({
                                            ...weekFrequency,
                                            sat: !weekFrequency.sat,
                                          });
                                        }}
                                        sx={{ color: palette.neutral[500] }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="c12px400"
                                        sx={{
                                          color: palette.neutral[500],
                                        }}
                                      >
                                        Sat
                                      </Typography>
                                    }
                                  />
                                </Stack>
                                <Divider />
                                <RadioGroup
                                  row
                                  value={dayFrequency}
                                  onChange={(e) => {
                                    setFrequencyShowError(false);
                                    setWeekFrequency(defaultWeekFreq);
                                    setDayFrequency(e.target.value);
                                  }}
                                >
                                  <FormControlLabel
                                    data-cy="radio__daily"
                                    value="1"
                                    control={<Radio />}
                                    label={
                                      <Typography
                                        variant="c12px400"
                                        sx={{
                                          color: palette.neutral[500],
                                        }}
                                      >
                                        Daily
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    data-cy="radio__alternateDay"
                                    value="2"
                                    control={<Radio />}
                                    label={
                                      <Typography
                                        variant="c12px400"
                                        sx={{
                                          color: palette.neutral[500],
                                        }}
                                      >
                                        Alternate Day
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    data-cy="radio__everyThirdDay"
                                    value="3"
                                    control={<Radio />}
                                    label={
                                      <Typography
                                        variant="c12px400"
                                        sx={{
                                          color: palette.neutral[500],
                                        }}
                                      >
                                        Every 3 days
                                      </Typography>
                                    }
                                  />
                                </RadioGroup>
                              </Stack>
                            </Stack>
                            {frequencyShowError && (
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  color: palette.error.main,
                                  lineHeight: "14.4px",
                                }}
                              >
                                Frequency is required
                              </Typography>
                            )}
                            <Stack
                              direction="column"
                              sx={{
                                padding: "12px 8px 4px 8px",
                                border: "1px solid #F7F7F7",
                                height: "100%",
                                width: "100%",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography
                                variant="c12px600"
                                sx={{
                                  color: palette.neutral[500],
                                }}
                              >
                                TIMING
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                {/* <FormControl>
                                  <CustomTextField
                                    sx={{ width: "67px", marginTop: "20px" }}
                                    required
                                    variant="filled"
                                    placeholder="00:00"
                                    type="text"
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(e) => {
                                      setAddTiming(e.target.value);
                                    }}
                                  />
                                </FormControl> */}
                                <LocalizationProvider
                                  dateAdapter={AdapterMoment}
                                >
                                  <TimePicker
                                    ampm={true}
                                    ampmInClock={true}
                                    value={addTiming}
                                    onChange={(e) => {
                                      setTimingShowError(false);

                                      setAddTiming(e);
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                    renderInput={(params) => (
                                      <CustomTextField
                                        data-cy="time__picker"
                                        sx={{
                                          width: "35%",
                                          marginTop: "20px",
                                        }}
                                        fullWidth
                                        required
                                        variant="filled"
                                        {...params}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>

                                <Button
                                  data-cy="button__add"
                                  disableElevation
                                  variant="text"
                                  startIcon={<Add />}
                                  onClick={addTime}
                                >
                                  Add
                                </Button>
                              </Stack>

                              <Stack direction="row" spacing={1}>
                                {displayTime.map((timing, index) => (
                                  <Chip
                                    key={index}
                                    label={moment(timing).format("hh:mm A")}
                                    onDelete={() => {
                                      setDisplayTime(
                                        displayTime.filter((i) => i !== timing)
                                      );
                                    }}
                                    variant="outlined"
                                    deleteIcon={<Close />}
                                  />
                                ))}
                              </Stack>
                              {timingShowError && (
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: "400",
                                    color: palette.error.main,
                                    lineHeight: "14.4px",
                                  }}
                                >
                                  Timing is required
                                </Typography>
                              )}
                            </Stack>
                            <Stack
                              direction="column"
                              sx={{
                                padding: "12px 8px 4px 8px",
                                border: "1px solid #F7F7F7",
                                height: "100%",
                                width: "100%",
                                borderRadius: "4px",
                              }}
                            >
                              <Typography
                                variant="c12px600"
                                sx={{
                                  color: palette.neutral[500],
                                }}
                              >
                                ACTIVE FOR
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <FormControl>
                                  <CustomTextField
                                    data-cy="input__activeFor"
                                    sx={{ width: "100px", marginTop: "20px" }}
                                    required
                                    variant="filled"
                                    placeholder="00"
                                    type="number"
                                    value={activeFor}
                                    onChange={(e) => {
                                      setActiveForShowError(false);
                                      setActiveFor(e.target.value);
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                  />
                                </FormControl>
                                <FormControl>
                                  <Select
                                    data-cy="select__activeFor"
                                    sx={{ width: "77px", height: "42px" }}
                                    required
                                    variant="filled"
                                    type="text"
                                    value={timeIndication}
                                    onChange={(e) => {
                                      setActiveForShowError(false);
                                      setTimeIndication(e.target.value);
                                    }}
                                  >
                                    <MenuItem
                                      data-cy="option__minute"
                                      value="min"
                                    >
                                      min
                                    </MenuItem>
                                    <MenuItem data-cy="option__hour" value="hr">
                                      hr
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Stack>
                              <Divider />
                              {/* <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={Boolean(parseInt(activeFor))}
                                      checked={!Boolean(parseInt(activeFor))}
                                      onChange={(e) => {
                                        setActiveForShowError(false);
                                        if (e.target.checked) {
                                          setActiveFor(0);
                                        } else {
                                          setActiveFor(60);
                                        }
                                      }}
                                      sx={{ color: palette.neutral[500] }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{
                                        fontSize: "12x",
                                        fontWeight: "400",
                                        color: palette.neutral[500],
                                      }}
                                    >
                                      Till next survey appears{" "}
                                    </Typography>
                                  }
                                />
                                <CustomWidthTooltip arrow placement="top">
                                  <HelpOutline sx={{ color: palette.neutral[500] }} />
                                </CustomWidthTooltip>
                              </Stack> */}
                              {activeForShowError && (
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: "400",
                                    color: palette.error.main,
                                    lineHeight: "14.4px",
                                  }}
                                >
                                  Active For is required
                                </Typography>
                              )}
                            </Stack>
                            <Button
                              data-cy="button__done"
                              fullWidth
                              variant="contained"
                              color="primary"
                              size="medium"
                              // sx={{
                              //   backgroundColor: palette.primary[500],
                              //   color: "#fff",
                              // }}
                              onClick={handleClose}
                            >
                              Done
                            </Button>
                          </Stack>
                        </DialogContent>
                        <DialogActions></DialogActions>
                      </BootstrapDialog>
                    </FormControl>
                    {error && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginBottom: "10px",
                        }}
                      >
                        {error}
                      </p>
                    )}
                    <Button
                      data-cy="button__next"
                      disabled={loading}
                      type="submit"
                      endIcon={<ArrowForwardOutlined />}
                      variant="contained"
                      color="primary"
                      size="medium"
                      fullWidth
                    >
                      Next
                    </Button>
                  </form>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
          {/* END: Body */}
        </Box>
      </Grid>
    </Grid>
  );
}
