import { CheckCircleOutline, KeyboardBackspace } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import trash from "../../../assets/common/trash.svg";
import Loading from "../../../components/Blockers/Loading";
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/CustomDialog";
import { setNotify } from "../../../redux/utils/utilsSlice";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import urls from "../../../utils/urls.json";
import Question from "./Question";

export const question_types = [
  {
    value: "text",
    label: "Text",
  },
  {
    value: "number",
    label: "Number",
  },
  {
    value: "mcq",
    label: "Multiple choice",
  },
  {
    value: "scq",
    label: "Single choice",
  },
];

export default function Questions() {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, questionnaireVersionId, questionnaireId } = useParams();
  const [questionnaire, setQuestionnaire] = useState({});
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("Enter Question");
  const [description] = useState("");
  const [loading, setLoading] = useState(false);
  const [swapping, setSwapping] = useState(false);
  const [addQuestion, setAddQuestion] = useState(false);
  const [addOption, setAddOption] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [error, setError] = useState(null);

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const fetchQuestionnaire = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/questionnaire/`,
        params: {
          trial_id: id,
          id: questionnaireId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      setQuestionnaire(res.data.payload[0]);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [questionnaireId, id]);

  const fetchQuestions = useCallback(async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/questionnaire/versions/questions/`,
        headers: {
          Authorization: getAuthorization(),
        },
        params: {
          version_id: questionnaireVersionId,
          deep: true,
        },
      });

      setQuestions(res.data.payload);
    } catch (e) {
      console.error(e);
    }
  }, [questionnaireVersionId]);

  useEffect(() => {
    fetchQuestions();
    fetchQuestionnaire();
  }, [fetchQuestions, fetchQuestionnaire]);

  const handleAddQuestion = async (e) => {
    e.preventDefault();
    setAddQuestion(true);

    try {
      const res = await axios({
        method: "POST",
        url: `${DOMAIN}/questionnaire/versions/questions/`,
        headers: {
          Authorization: getAuthorization(),
        },
        data: {
          title,
          description,
          question_type: question_types[0].value,
          questionnaire_version: questionnaireVersionId,
        },
      });

      setQuestions([
        ...questions,
        {
          id: res.data.id,
          order_id: res.data.order_id,
          title: res.data.title,
          question_type: res.data.question_type,
        },
      ]);

      setAddQuestion(false);

      // notify
    } catch (err) {
      // set loading to false
      setLoading(false);
      setError("Error while adding question");
    }
  };

  const handleDeleteQuestionnaire = async () => {
    await deleteQuestionnaire();
  };

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const closeCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const deleteQuestionnaire = async () => {
    try {
      const res = await axios({
        method: "DELETE",
        url: `${DOMAIN}/questionnaire/`,
        headers: {
          Authorization: getAuthorization(),
        },
        params: {
          id: questionnaireId,
        },
      });

      if (res.status === 204 || res.status === 200) {
        dispatch(
          setNotify({
            open: true,
            action: "Questionnaire deleted successfully!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        closeDeleteDialog();
        navigate(`/trial/${id}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editQuestion = async (updatedValue, question, eventType) => {
    let data = {};
    switch (eventType) {
      case "changeTitle":
        data = {
          ...data,
          title: updatedValue,
        };
        break;
      case "changeQuestionType":
        data = {
          ...data,
          question_type: updatedValue,
        };
        break;
      default:
        break;
    }

    try {
      const res = await axios({
        method: "PATCH",
        url: `${DOMAIN}/questionnaire/versions/questions/`,
        params: { id: question.id },
        headers: {
          Authorization: getAuthorization(),
        },
        data,
      });

      const newQuestions = questions.map((q) => {
        if (q.id === question.id) {
          return {
            ...res.data,
          };
        }
        return q;
      });
      setQuestions(newQuestions);
    } catch (e) {
      console.error(e);
    }
  };

  const reorderQuestions = async (question, direction) => {
    const targetQuestion = questions.find((q) => q === question);
    const targetIndex = questions.indexOf(targetQuestion);
    const swapQuestion = questions[targetIndex + direction];

    setSwapping(true);
    const res = await axios.post(
      `${DOMAIN}/questionnaire/versions/questions/order/`,
      {
        question_one: targetQuestion.id,
        question_two: swapQuestion.id,
        order_one: swapQuestion.order_id,
        order_two: targetQuestion.order_id,
      },
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      window.location.reload();
    }
  };

  const handleNavigateToTrial = () =>
    navigate(urls.trial.path.replace(":id", id));

  const handleNavigateToQuestionnaire = () => {
    navigate(
      `/trial/${id}/questionnaire/${questionnaireId}/${questionnaireVersionId}/edit`
    );
  };

  return (
    <Loading loading={loading}>
      <Grid
        container
        style={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* START: Top Nav */}
            <Stack
              direction="column"
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: palette.neutral[100],
                zIndex: 101,
              }}
            >
              {/* START: First Row */}
              <Stack
                px="32px"
                py="24px"
                paddingTop="24px"
                paddingBottom="24px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Stack direction="row" alignItems="center">
                      <Typography
                        variant="c24px600"
                        style={{
                          textAlign: "left",
                          color: palette.neutral[900],
                        }}
                      >
                        {questionnaire.title}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <IconButton
                        sx={{
                          backgroundColor: palette.neutral[200],
                          borderRadius: "6px",
                          padding: "12px",
                        }}
                        data-cy="button__deleteQuestionnaire"
                        onClick={() => setOpenDeleteDialog(true)}
                      >
                        <img src={trash} alt="" />
                      </IconButton>
                      <Dialog
                        open={openDeleteDialog}
                        onClose={closeDeleteDialog}
                        fullWidth
                      >
                        <CustomDialogTitle onClose={closeDeleteDialog}>
                          Discard Questionnaire
                        </CustomDialogTitle>
                        <CustomDialogContent>
                          <Stack spacing={1} mb={3}>
                            <Typography variant="c18px500">
                              Are you sure you want to discard this
                              questionnaire?
                            </Typography>
                            <Typography
                              variant="c16px500"
                              color={palette.error.main}
                              mb={3}
                            >
                              All the progress made so far will be lost.
                            </Typography>
                          </Stack>
                        </CustomDialogContent>
                        <CustomDialogActions>
                          <Button
                            variant="outlined"
                            fullWidth
                            color="primary"
                            sx={{ padding: "10px 16px" }}
                            onClick={closeDeleteDialog}
                          >
                            No
                          </Button>
                          <Button
                            fullWidth
                            data-cy="link__deleteQuestionnaire"
                            variant="contained"
                            color="error"
                            sx={{ padding: "10px 16px" }}
                            onClick={handleDeleteQuestionnaire}
                          >
                            Yes
                          </Button>
                        </CustomDialogActions>
                      </Dialog>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={questions.length === 0}
                        disableElevation
                        sx={{
                          textAlign: "left",
                          padding: "12px 16px 12px 16px",
                          borderRadius: "6px",
                        }}
                        startIcon={<CheckCircleOutline fontSize="small" />}
                        onClick={() => setOpenCreateDialog(true)}
                      >
                        Create Questionnaire
                      </Button>
                      <Dialog
                        open={openCreateDialog}
                        onClose={closeCreateDialog}
                        fullWidth
                      >
                        <CustomDialogTitle onClose={closeCreateDialog}>
                          Create & Save Questionnaire
                        </CustomDialogTitle>
                        <CustomDialogContent>
                          <Stack spacing={1} mb={3}>
                            <Typography variant="c18px500">
                              Are you sure you want to save this questionnaire?
                            </Typography>
                            <Typography
                              variant="c16px500"
                              color={palette.error.main}
                            >
                              After this step no further changes can be made to
                              this questionnaire.
                            </Typography>
                          </Stack>
                        </CustomDialogContent>
                        <CustomDialogActions>
                          <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            sx={{ padding: "10px 16px" }}
                            onClick={closeCreateDialog}
                          >
                            No
                          </Button>
                          <Button
                            fullWidth
                            data-cy="link__deleteQuestionnaire"
                            variant="contained"
                            color="error"
                            sx={{ padding: "10px 16px" }}
                            onClick={handleNavigateToTrial}
                          >
                            Yes
                          </Button>
                        </CustomDialogActions>
                      </Dialog>
                    </Stack>
                  </Stack>
                  {/* START: Edit Schedule Button */}

                  {/* END: Edit Schedule Button */}
                </Stack>
              </Stack>
              {/* END: First Row */}
            </Stack>
            {/* END: Top Nav */}
          </Box>
          {/* START:Body*/}
          <Stack
            minHeight="90vh"
            width="100%"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            paddingTop={4}
          >
            {/* START: Body */}
            <Stack width="680px" alignItems="flex-start" spacing={3}>
              <Button
                variant="text"
                startIcon={<KeyboardBackspace />}
                sx={{
                  color: palette.primary[600],
                  padding: "unset",
                  "&:hover": {
                    color: palette.primary[700],
                    backgroundColor: palette.primary[100],
                  },
                }}
                onClick={handleNavigateToQuestionnaire}
              >
                Previous Step
              </Button>
              <Typography variant="c18px600" color={palette.neutral[700]}>
                Step 2: Add Questions
              </Typography>
            </Stack>
            {questions.length
              ? questions
                  .sort((a, b) => a.order_id - b.order_id)
                  .map((question, index) => (
                    <Question
                      key={question.order_id}
                      index={index}
                      question={question}
                      editQuestion={editQuestion}
                      reorderQuestions={reorderQuestions}
                      questions={questions}
                      title={title}
                      setTitle={setTitle}
                      question_types={question_types}
                      setQuestions={setQuestions}
                      surveyVersionId={questionnaireVersionId}
                      swapping={swapping}
                      addOption={addOption}
                      setAddOption={setAddOption}
                      addQuestion={addQuestion}
                      setAddQuestion={setAddQuestion}
                    />
                  ))
              : null}
            {/* END: Body */}
            <form onSubmit={handleAddQuestion}>
              <Stack
                sx={{
                  paddingBottom: "62px",
                  paddingLeft: "40px",
                  width: "680px",
                }}
              >
                {error && (
                  <p
                    style={{
                      color: palette.error.main,
                      fontSize: "14px",
                      marginBottom: "10px",
                    }}
                  >
                    {error}
                  </p>
                )}
                <Button
                  data-cy="button__addQuestion"
                  type="submit"
                  disabled={addQuestion}
                  disableElevation
                  sx={{
                    backgroundColor: palette.neutral[100],
                    color: palette.primary[500],
                    padding: "16px 24px",
                  }}
                  startIcon={<AddIcon />}
                >
                  Add Question
                </Button>
              </Stack>
            </form>
          </Stack>
          {/* END: Body */}
        </Grid>
      </Grid>
    </Loading>
  );
}
