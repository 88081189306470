import { Grid } from "@mui/material";
import Loading from "../../../components/Blockers/Loading";
import useTasks from "../../../hooks/useTasks";
import TaskCard from "./TaskCard";

export default function TaskList() {
  const { loading, devices, questionnaires } = useTasks();

  const combinedTasks = [...devices, ...questionnaires];

  return (
    <Loading loading={loading}>
      <Grid container px={3} py={4} gap={2}>
        {combinedTasks.map(
          ({ id, name, questionnaire, description, schedule, timing }) => (
            <TaskCard
              key={id}
              taskId={id}
              title={name}
              description={description}
              deviceOrQuestionnaire={questionnaire ? "questionnaire" : "device"}
              duration={schedule}
              timing={timing}
            />
          )
        )}
      </Grid>
    </Loading>
  );
}
