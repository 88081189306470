import { KeyboardBackspace } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Blockers/Loading";
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/CustomDialog";
import AddQuestionnaireInfo from "./AddQuestionnaireInfo";

export default function AddQuestionnaire() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);

  const closeDiscardDialog = () => {
    setOpenDiscardDialog(false);
  };

  const handleDiscardQuestionnaire = () => navigate(`/trial/${id}`);

  return (
    <Loading>
      <Grid
        container
        style={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* START: Top Nav */}
            <Stack
              direction="column"
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: palette.neutral[100],
                zIndex: 101,
              }}
            >
              {/* START: First Row */}
              <Stack
                px="32px"
                py="24px"
                paddingTop="24px"
                paddingBottom="24px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography
                      variant="c24px600"
                      style={{
                        textAlign: "left",
                        color: palette.neutral[900],
                      }}
                    >
                      New Questionnaire
                    </Typography>
                  </Stack>
                  {/* START: Edit Schedule Button */}

                  {/* END: Edit Schedule Button */}
                </Stack>
              </Stack>
              {/* END: First Row */}
            </Stack>
            {/* END: Top Nav */}
            {/* START:Body*/}
            <Stack
              minHeight="90vh"
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              sx={{ paddingTop: "32px" }}
            >
              {/* START: Body */}
              <Stack width="680px" alignItems="flex-start" spacing={3}>
                <Button
                  variant="text"
                  startIcon={<KeyboardBackspace />}
                  sx={{
                    color: palette.primary[600],
                    padding: "unset",
                    "&:hover": {
                      color: palette.primary[700],
                      backgroundColor: palette.primary[100],
                    },
                  }}
                  onClick={() => setOpenDiscardDialog(true)}
                >
                  Back
                </Button>
                <Dialog
                  open={openDiscardDialog}
                  onClose={closeDiscardDialog}
                  fullWidth
                >
                  <CustomDialogTitle onClose={closeDiscardDialog}>
                    Discard Questionnaire
                  </CustomDialogTitle>
                  <CustomDialogContent>
                    <Stack spacing={1} mb={3}>
                      <Typography variant="c18px500">
                        Are you sure you want to discard this questionnaire?
                      </Typography>
                      <Typography
                        variant="c16px500"
                        color={palette.error.main}
                        mb={3}
                      >
                        All the progress made so far will be lost.
                      </Typography>
                    </Stack>
                  </CustomDialogContent>
                  <CustomDialogActions>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="primary"
                      sx={{ padding: "10px 16px" }}
                      onClick={closeDiscardDialog}
                    >
                      No
                    </Button>
                    <Button
                      fullWidth
                      data-cy="link__deleteQuestionnaire"
                      variant="contained"
                      color="error"
                      sx={{ padding: "10px 16px" }}
                      onClick={handleDiscardQuestionnaire}
                    >
                      Yes
                    </Button>
                  </CustomDialogActions>
                </Dialog>
                <Typography variant="c18px600" color={palette.neutral[700]}>
                  Step 1: Questionnaire Info
                </Typography>
              </Stack>
              <AddQuestionnaireInfo />
              {/* END: Body */}
            </Stack>
          </Box>

          {/* END: Body */}
        </Grid>
      </Grid>
    </Loading>
  );
}
