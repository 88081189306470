import axios from "axios";
import { createContext, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DOMAIN } from "../utils/config";
import { getAuthorization } from "../utils/helpers";

export const OnboardingContext = createContext({
  allDevices: [],
  trialDevices: [],
  fetchDevices: () => {},
});

const OnboardingProvider = ({ children }) => {
  const { id } = useParams();

  const [allDevices, setAllDevices] = useState([]);
  const [trialDevices, setTrialDevices] = useState([]);

  const fetchDevices = useCallback(async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/trials/devices/`,
        params: { trial_id: id },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      setTrialDevices(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  const fetchAllDevices = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/devices/`,
        headers: {
          Authorization: getAuthorization(),
        },
      });

      setAllDevices(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  useEffect(() => {
    fetchAllDevices();
  }, []);

  return (
    <OnboardingContext.Provider
      value={{ trialDevices, allDevices, fetchDevices }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
