import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import trash from "../../../assets/common/trash.svg";
import { CustomDialogTitle } from "../../../components/CustomDialog";
import useOnboarding from "../../../hooks/useOnboarding";
import { setNotify } from "../../../redux/utils/utilsSlice";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";

export const CustomTextField = styled(TextField)(({ theme }) => {
  return {
    marginBottom: "20px",
    "label + &": {
      marginTop: theme.spacing(2),
    },
    "& .MuiInputBase-input": {
      borderBottom: "0px",
      fontSize: 16,
      padding: "10px 12px",
      backgroundColor: theme.palette.neutral[100],
      border: `2px solid ${theme.palette.neutral[400]}`,
      borderRadius: "6px",
      "&:focus": {
        borderColor: theme.palette.primary[600],
        boxShadow: `0 0 0 2px #0C6FE280`,
      },
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      padding: 0,
    },
    overflow: "hidden",
    borderRadius: "4px",
  };
});

export const CustomSelect = styled(Select)(({ theme }) => {
  return {
    marginBottom: "20px",
    "label + &": {
      marginTop: theme.spacing(2),
    },
    "& .MuiInputBase-input": {
      borderBottom: "0px",
      fontSize: 16,
      padding: "10px 12px",
      backgroundColor: theme.palette.neutral[100],
      border: `2px solid ${theme.palette.neutral[400]}`,
      borderRadius: "6px",
      "&:focus": {
        borderColor: theme.palette.primary[600],
        boxShadow: `0 0 0 2px #0C6FE280`,
      },
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      padding: 0,
    },
    overflow: "hidden",
    borderRadius: "4px",
  };
});

export const CustomInputLabel = ({ label, required }) => {
  const { palette } = useTheme();

  return (
    <InputLabel
      variant="standard"
      shrink={false}
      style={{
        marginTop: "-30px",
        width: "100%",
      }}
    >
      <Typography
        variant="c16px500"
        style={{
          color: palette.neutral[500],
        }}
      >
        {label}{" "}
        {required && <span style={{ color: palette.neutral[500] }}>*</span>}
      </Typography>
    </InputLabel>
  );
};

export default function DeleteDevice(props) {
  const { id } = useParams();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { fetchDevices } = useOnboarding();

  const [open, setOpen] = useState(false);

  const handleDeleteDevice = async () => {
    try {
      const res = await axios({
        method: "DELETE",
        url: `${DOMAIN}/trials/devices/`,
        params: { trial_id: id, id: props.deviceId },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      if (res.status === 204 || res.status === 200) {
        dispatch(
          setNotify({
            open: true,
            action: "Device Deleted Successfully!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        setOpen(false);
        fetchDevices();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Box
        sx={{
          height: "32px",
          width: "32px",
          borderRadius: "6px",
          backgroundColor: palette.neutral[200],
        }}
      >
        <IconButton onClick={() => setOpen(true)}>
          <img src={trash} alt="icon" />
        </IconButton>
      </Box>{" "}
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <CustomDialogTitle>
          <Typography variant="c24px600" color={palette.neutral[900]}>
            Delete Device
          </Typography>
        </CustomDialogTitle>
        <Divider style={{ width: "100%" }} />
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="c18px500" color={palette.neutral[900]}>
              Are you sure you want to delete this device?
            </Typography>
            <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
              <Button
                variant="outlined"
                sx={{
                  padding: "10px, 16px, 10px, 16px",
                  width: "50%",
                  color: palette.neutral[500],
                }}
              >
                <Typography variant="c16px500" color={palette.neutral[700]}>
                  No
                </Typography>
              </Button>
              <Button
                sx={{
                  padding: "10px, 16px, 10px, 16px",
                  width: "50%",
                  backgroundColor: "#BD5250",
                  "&:hover": {
                    backgroundColor: "#BD5250", // Hover background color
                  },
                }}
                variant="contained"
                onClick={handleDeleteDevice}
              >
                <Typography variant="c16px500" color={palette.neutral[100]}>
                  Yes
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
