export const trialStatuses = [
  {
    value: "approved",
    label: "Approved",
    color: "#2D81E3",
    backgroundColor: "#E7F2FF",
  },
  {
    value: "init",
    label: "Unapproved",
    color: "#8D1A1A",
    backgroundColor: "#FFE7E7",
  },
  {
    value: "requested",
    label: "Unapproved",
    color: "#4A4A4A",
    backgroundColor: "#E8E8E8",
  },
  {
    value: "live",
    label: "Live",
    color: "#279500",
    backgroundColor: "#D4FFE3",
  },
  {
    value: "ended",
    label: "Ended",
    color: "#2D2D2D",
    backgroundColor: "#E8E8E8",
  },
  {
    value: "completed",
    label: "Completed",
    color: "#279500",
    backgroundColor: "#D4FFE3",
  },
];

export function getTrialStatus(trialStatus) {
  return trialStatuses.find((status) => status.value === trialStatus);
}
