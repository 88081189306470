// Save User Credentials
export const saveUserCredentials = (data) => {
  // helper function to save user credentials to the local storage
  localStorage.setItem("access", data.access);
  localStorage.setItem("refresh", data.refresh);
  localStorage.setItem("user", JSON.stringify(data.user));
};

// Delete User Credentials
export const deleteUserCredentials = (data) => {
  // helper function to delete user credentials from local storage
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  localStorage.removeItem("user");
};

// Get User Credentials
export const getUserCredentials = () => {
  // helper function to fetch user credentials from local storage
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = {};
  }
  return {
    accessToken: localStorage.getItem("access"),
    refreshToken: localStorage.getItem("refresh"),
    user,
  };
};

export const getAuthorization = () => {
  const data = getUserCredentials();
  return `Bearer ${data["accessToken"]}`;
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "win";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return null;
};

export const containsObject = (obj, list) => {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }
  return false;
};

export const anyValueTrue = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      return true;
    }
  }
  return false;
};

export const convertTimeArray = (inputArray) => {
  const outputArray = inputArray.map((timeString) => {
    const times = timeString.split(" - ").map((time) => {
      const [hour, minute] = time.match(/\d+/g);
      const period = time.match(/[APap][Mm]/);

      let hour24 = parseInt(hour);
      if (period && period[0].toLowerCase() === "pm" && hour24 < 12) {
        hour24 += 12;
      } else if (period && period[0].toLowerCase() === "am" && hour24 === 12) {
        hour24 = 0;
      }

      const formattedHour = hour24.toString().padStart(2, "0");
      const formattedMinute = minute ? minute.padStart(2, "0") : "00";

      return `${formattedHour}:${formattedMinute}`;
    });

    return times.join("-");
  });

  return outputArray;
};

export const convertDayRangeArray = (inputArray) => {
  const outputArray = inputArray.map((dayString) => {
    const days = dayString.match(/\d+/g);
    if (days.length === 1) {
      return days[0];
    } else if (days.length === 2) {
      return `${days[0]}-${days[1]}`;
    }
    return "";
  });

  return outputArray;
};

export const formatDuration = (value) => {
  if (value.includes("-")) {
    const [start, end] = value.split("-").map((num) => `Day ${num}`);
    return `${start} - ${end}`;
  } else {
    return `Day ${value}`;
  }
};

export const reverseConvertTimeArray = (inputArray) => {
  const outputArray = inputArray.map((formattedTime) => {
    const times = formattedTime.split("-").map((formattedTimeString) => {
      const [hour, minute] = formattedTimeString.split(":");
      const hour24 = parseInt(hour);
      const isPM = hour24 >= 12;

      const period = isPM ? "PM" : "AM";

      let hour12 = hour24 % 12 || 12;
      const formattedHour = hour12.toString().padStart(2, "0");
      const formattedMinute = minute.padStart(2, "0");

      return `${formattedHour}:${formattedMinute} ${period}`;
    });

    return times.join(" - ");
  });

  return outputArray;
};

export function calculatePercentage(value, total) {
  // Check if the inputs are valid numbers
  if (isNaN(value) || isNaN(total) || total === 0) {
    return "N/A";
  }

  // Calculate the percentage with one decimal point
  const percentage = (value / total) * 100;
  const roundedPercentage = percentage.toFixed(1);

  return `${roundedPercentage}%`;
}

export function countByStatus(dataArray, status) {
  // Check if the input array is valid
  if (!Array.isArray(dataArray)) {
    return "Invalid input array";
  }

  // Filter the array based on the specified status
  const filteredArray = dataArray.filter((item) => item.status === status);

  // Return the count
  return filteredArray.length;
}

export function updateRangeArray(rangeArray, newValue) {
  const newRange = newValue.toString();

  const [newStart, newEnd] = newRange.includes("-")
    ? newRange.split("-").map(Number)
    : [Number(newRange), Number(newRange)];

  // Check if the new value or range overlaps with any existing range
  const overlappingRange = rangeArray.find((range) => {
    const [start, end] = range.split("-").map(Number);
    return (
      (newStart >= start && newStart <= end) ||
      (newEnd >= start && newEnd <= end)
    );
  });

  if (overlappingRange) {
    // If the new value or range overlaps with an existing range, merge them
    const [start, end] = overlappingRange.split("-").map(Number);
    const mergedStart = Math.min(start, newStart);
    const mergedEnd = Math.max(end, newEnd);
    const mergedRange = `${mergedStart}-${mergedEnd}`;

    // Remove the overlapping range from the array
    rangeArray = rangeArray.filter((range) => range !== overlappingRange);

    // Add the merged range to the array
    rangeArray.push(mergedRange);

    return rangeArray;
  }

  // No overlapping range, insert the new range or value in the correct position
  let inserted = false;
  rangeArray = rangeArray.map((range) => {
    const [start, end] = range.split("-").map(Number);

    if (newEnd < start && !inserted) {
      // Insert new range before the current range
      inserted = true;
      return newRange;
    } else if (newStart > end && !inserted) {
      // Insert new range after the current range
      return range;
    } else if (newStart <= start && newEnd >= end) {
      // The new range completely covers the current range
      inserted = true;
      return newRange;
    } else if (newStart === end + 1) {
      // Merge with the current range if the new value is one more than the end
      inserted = true;
      return `${start}-${newEnd}`;
    } else if (newEnd === start - 1) {
      // Merge with the current range if the new value is one less than the start
      inserted = true;
      return `${newStart}-${end}`;
    } else {
      return range;
    }
  });

  if (!inserted) {
    // If the new range is at the end, add it to the array
    rangeArray.push(newRange);
  }

  return rangeArray;
}
