import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";

import { ReactComponent as OverviewIcon } from "../../../assets/trial/exclamationmark.svg";
import { StyledTooltip } from "../../../components/CustomTooltip";
import ShareTrial from "./ShareTrial";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import { getTrialStatus } from "../../../utils/status";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  const { palette } = useTheme();

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: palette.neutral[700] }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// NOTE: TrialMoreOption component is button on Trial page with Survey button
export default function TrialMoreOption({ trial, isOwner }) {
  const { palette } = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const userId = JSON.parse(localStorage.getItem("user")).id;

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {/* {dialogData && ( */}
      <StyledTooltip title="Trial Overview ">
        <IconButton
          sx={{
            backgroundColor: palette.neutral[200],
            padding: "8px",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: palette.neutral[300],
            },
            "&:clicked": {
              border: "1px solid #BCBCBC",
            },
          }}
          onClick={(e) => {
            handleClickOpen();
          }}
        >
          <OverviewIcon />
        </IconButton>
      </StyledTooltip>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
        <BootstrapDialogTitle onClose={handleCloseDialog}>
          <Stack
            borderBottom="1px solid #E8E8E8"
            sx={{
              padding: "24px 24px 24px 0px",
            }}
          >
            <Typography variant="c20px600" color={palette.neutral[900]}>
              Trial Info
            </Typography>
          </Stack>
        </BootstrapDialogTitle>
        <DialogContent>
          <Stack
            spacing={2}
            sx={{
              width: "640px",
            }}
          >
            <Stack
              spacing={1}
              sx={{
                paddingBottom: "16px",
                borderBottom: "1px solid #E8E8E8",
              }}
            >
              <Typography variant="c14px500" color={palette.neutral[700]}>
                TRIAL NAME
              </Typography>
              <Typography variant="c18px600" color={palette.neutral[700]}>
                {trial.name}
              </Typography>
            </Stack>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                paddingBottom: "16px",
                borderBottom: "1px solid #E8E8E8",
              }}
            >
              <Stack spacing={1} width="50%">
                <Typography variant="c14px500" color={palette.neutral[700]}>
                  TRIAL STATUS{" "}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    variant="c14px600"
                    sx={{
                      color: trial.status
                        ? getTrialStatus(trial.status).color
                        : "inherit",
                      padding: "4px 8px",
                      backgroundColor: trial.status
                        ? getTrialStatus(trial.status).backgroundColor
                        : "inherit",
                      borderRadius: "4px",
                    }}
                  >
                    {trial.status && getTrialStatus(trial.status).label}
                  </Typography>
                </Box>
              </Stack>
              <Stack spacing={1} width="50%">
                <Typography variant="c14px500" color={palette.neutral[700]}>
                  TRIAL ACCESS{" "}
                </Typography>
                <Stack
                  direction="row"
                  sx={{
                    padding: "4px 8px",
                    borderRadius: "4px",
                    border: "1px solid #E8E8E8",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                    width: "40% ",
                  }}
                >
                  <Typography
                    variant="c14px500"
                    sx={{
                      color: palette.neutral[500],
                    }}
                  >
                    {userId === trial.created_by ? "Owner" : "Collaborator"}{" "}
                  </Typography>
                  {/* <img
              src={userCircle}
              alt="icon"
              width="16px"
              height="16px
            "
            /> */}
                </Stack>
              </Stack>
            </Stack>
            <Stack
              spacing={1}
              sx={{
                paddingBottom: "16px",
                borderBottom: "1px solid #E8E8E8",
              }}
            >
              <Typography variant="c14px500" color={palette.neutral[700]}>
                TRIAL DESCRIPTION
              </Typography>
              <Typography variant="c16px500" color={palette.neutral[700]}>
                {trial.description}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              sx={{
                paddingBottom: "16px",
                borderBottom: "1px solid #E8E8E8",
              }}
            >
              <Typography variant="c14px500" color={palette.neutral[700]}>
                ORGANISATION NAME{" "}
              </Typography>
              <Typography variant="c16px500" color={palette.neutral[700]}>
                {trial.org_name}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              sx={{
                paddingBottom: "16px",
                borderBottom: "1px solid #E8E8E8",
              }}
            >
              <Typography variant="c14px500" color={palette.neutral[700]}>
                TOTAL NO. OF SUBJECTS
              </Typography>
              <Typography variant="c16px500" color={palette.neutral[700]}>
                {trial.max_sub}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              sx={{
                paddingBottom: "16px",
                borderBottom: "1px solid #E8E8E8",
              }}
            >
              <Typography variant="c14px500" color={palette.neutral[700]}>
                TRIAL DURATION
              </Typography>
              <Typography variant="c16px500" color={palette.neutral[700]}>
                {trial.duration} Days
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              sx={{
                paddingBottom: "16px",
                borderBottom: "1px solid #E8E8E8",
              }}
            >
              <Typography variant="c14px500" color={palette.neutral[700]}>
                EXPECTED TRIAL END DATE
              </Typography>
              <Typography variant="c16px500" color={palette.neutral[700]}>
                {trial.end_date}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      {/* )} */}

      {isOwner ? <ShareTrial trialId={trial.id} /> : null}
    </Stack>
  );
}
