import {
  Grid,
  LinearProgress,
  Stack,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Loading from "../../../components/Blockers/Loading";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import { calculatePercentage } from "../../../utils/calculatePercentage";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, backgroundColor }) => ({
    height: 6,
    borderRadius: 6,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 6,
      backgroundColor: backgroundColor || theme.palette.primary[700],
    },
  })
);

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[700],
  fontSize: theme.typography.fontSize.sm,
  fontWeight: theme.typography.fontWeightRegular,
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[900],
  fontSize: theme.typography.fontSize.lg,
  fontWeight: theme.typography.fontWeightBold,
}));

const StyledCard = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.neutral["main"],
  borderRadius: "6px",
  border: `1px solid ${theme.palette.neutral[300]}`,
  gap: theme.spacing(2),
}));

export const StyledStat = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[700],
  fontSize: theme.typography.fontSize.md,
  fontWeight: theme.typography.fontWeightMedium,
}));

export default function OverviewStats({ trialId }) {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  const { palette } = useTheme();

  const fetchOverviewStats = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/trials/overview/stats/`,
        params: {
          id: trialId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      setStats(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [trialId]);

  useEffect(() => {
    fetchOverviewStats();
  }, [fetchOverviewStats]);

  return (
    <Loading loading={loading}>
      <Grid container spacing={3}>
        <Grid item xs sx={{ minWidth: 280 }}>
          <StyledCard>
            <StyledTitle>Subjects</StyledTitle>
            <Stack>
              <StyledSubtitle>Total Subjects</StyledSubtitle>
              <StyledStat>
                {stats?.active_subjects_count + stats?.inactive_subjects_count}
              </StyledStat>
            </Stack>
            <Stack>
              <StyledSubtitle>Total Active Subjects</StyledSubtitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <StyledStat>{stats?.active_subjects_count}</StyledStat>
                <StyledStat>
                  {calculatePercentage(
                    stats?.active_subjects_count,
                    stats?.active_subjects_count +
                      stats?.inactive_subjects_count
                  )}
                  %
                </StyledStat>
              </Stack>
              <BorderLinearProgress
                variant="determinate"
                value={calculatePercentage(
                  stats?.active_subjects_count,
                  stats?.active_subjects_count + stats?.inactive_subjects_count
                )}
              />
            </Stack>
            <Stack>
              <StyledSubtitle>Total Inactive Subjects</StyledSubtitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <StyledStat>{stats?.inactive_subjects_count}</StyledStat>
                <StyledStat>
                  {calculatePercentage(
                    stats?.inactive_subjects_count,
                    stats?.active_subjects_count +
                      stats?.inactive_subjects_count
                  )}
                  %
                </StyledStat>
              </Stack>
              <BorderLinearProgress
                variant="determinate"
                backgroundColor={palette.neutral[400]}
                value={calculatePercentage(
                  stats?.inactive_subjects_count,
                  stats?.active_subjects_count + stats?.inactive_subjects_count
                )}
              />
            </Stack>
          </StyledCard>
        </Grid>
        <Grid item xs sx={{ minWidth: 280 }}>
          <StyledCard>
            <StyledTitle>Tests</StyledTitle>
            <Stack>
              <StyledSubtitle>Total Tests Taken</StyledSubtitle>
              <StyledStat>{stats?.total_test_taken}</StyledStat>
            </Stack>
            <Stack>
              <StyledSubtitle>Total Successful Taken</StyledSubtitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <StyledStat>{stats?.success_test_count}</StyledStat>
                <StyledStat>
                  {calculatePercentage(
                    stats?.success_test_count,
                    stats?.total_test_taken
                  )}
                  %
                </StyledStat>
              </Stack>
              <BorderLinearProgress
                variant="determinate"
                value={calculatePercentage(
                  stats?.success_test_count,
                  stats?.total_test_taken
                )}
              />
            </Stack>
            <Stack>
              <StyledSubtitle>Total Failed Tests</StyledSubtitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <StyledStat>{stats?.failed_test_count}</StyledStat>
                <StyledStat>
                  {calculatePercentage(
                    stats?.failed_test_count,
                    stats?.total_test_taken
                  )}
                  %
                </StyledStat>
              </Stack>
              <BorderLinearProgress
                variant="determinate"
                backgroundColor={palette.neutral[400]}
                value={calculatePercentage(
                  stats?.failed_test_count,
                  stats?.total_test_taken
                )}
              />
            </Stack>
          </StyledCard>
        </Grid>
        <Grid item xs sx={{ minWidth: 280 }}>
          <StyledCard>
            <StyledTitle>Surveys</StyledTitle>
            <Stack>
              <StyledSubtitle>Total Active Surveys</StyledSubtitle>
              <StyledStat>{stats?.active_survey_count}</StyledStat>
            </Stack>
            <Stack>
              <StyledSubtitle>Total Saved Surveys</StyledSubtitle>
              <StyledStat>{stats?.saved_survey_count}</StyledStat>
            </Stack>
            <Stack>
              <StyledSubtitle>Total Inactive Surveys</StyledSubtitle>
              <StyledStat>{stats?.inactive_survey_count}</StyledStat>
            </Stack>
          </StyledCard>
        </Grid>
      </Grid>
    </Loading>
  );
}
