import axios from "axios";
import { createContext, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DOMAIN } from "../utils/config";
import { getAuthorization } from "../utils/helpers";

export const DeviceAndQuestionnaireContext = createContext({
  questionnaires: [],
  devices: [],
  quesLoading: true,
  devisLoading: true,
});

const DeviceAndQuestionnaireProvider = ({ children }) => {
  const { id } = useParams();

  const [questionnaires, setQuestionnaires] = useState([]);
  const [devices, setDevices] = useState([]);
  const [quesLoading, setQuesLoading] = useState(true);
  const [devisLoading, setDevisLoading] = useState(true);

  const fetchTrialDevices = useCallback(async () => {
    try {
      setDevisLoading(true);
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/trials/devices/`,
        params: { trial_id: id },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      if (res.status === 200) {
        setDevices(res.data);
        setDevisLoading(false);
      }
    } catch (err) {
      console.log(err);
      setDevisLoading(false);
    }
  }, [id]);

  const fetchTrialQuestionnaires = useCallback(async () => {
    setQuesLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/questionnaire/`,
        params: { trial_id: id },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      if (res.status === 200) {
        setQuestionnaires(res.data.payload);
        setQuesLoading(false);
      }
    } catch (err) {
      console.log(err);
      setQuesLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchTrialDevices();
  }, [fetchTrialDevices]);

  useEffect(() => {
    fetchTrialQuestionnaires();
  }, [fetchTrialQuestionnaires]);

  return (
    <DeviceAndQuestionnaireContext.Provider
      value={{ questionnaires, quesLoading, devices, devisLoading }}
    >
      {children}
    </DeviceAndQuestionnaireContext.Provider>
  );
};

export default DeviceAndQuestionnaireProvider;
