import { Stack, Pagination, Typography, useTheme } from "@mui/material";

export default function CustomPagination({
  disabled,
  last_page_no,
  limit,
  handlePaginationChange,
}) {
  const { palette } = useTheme();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        position: "sticky",
        bottom: "0",
        backgroundColor: palette.neutral[100],
      }}
    >
      {/* START: First Row */}
      <Stack
        px="48px"
        py="12px"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Pagination
          count={last_page_no}
          disabled={disabled}
          onChange={handlePaginationChange}
        />
        <Typography
          variant="c14px500"
          style={{
            color: palette.neutral[500],
          }}
        >
          Showing {limit} results
        </Typography>
      </Stack>
      {/* END: First Row */}
    </Stack>
  );
}
