import { styled, useTheme } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => {
  const { palette } = useTheme();
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: palette.neutral[500],
      color: palette.neutral[100],
      boxShadow: theme.shadows[1],
      fontSize: 14,
      fontweight: 500,
      padding: "8px",
    },
  };
});
