import {
  Autorenew,
  Check,
  KeyboardBackspace,
  Search,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField as TextFieldBase,
  Tooltip,
  Typography,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { styled } from "@mui/material/styles";

import axios from "axios";
import Loading from "../../../components/Blockers/Loading";
import NoData from "../../../components/Blockers/NoData";
import CustomPagination from "../../../components/CustomPagination";
import { setNotify } from "../../../redux/utils/utilsSlice";
import { DOMAIN } from "../../../utils/config";
import { countByStatus, getAuthorization } from "../../../utils/helpers";
import AddSubjects from "./AddSubjects";
// import AddSession from "./AddSession";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import DataGridTable from "./DataGridTable";
import DownloadOptions from "./DownloadOptions";
import GenerateTable from "./GenerateTable";

import line from "../../../assets/common/Line.svg";
import useDeviceAndQuestionnaire from "../../../hooks/useDevice&Questionnaire";
import AddTask from "./AddTask";
import DevicesAndQuestionnaires from "./Devices&Questionnaires";
import ShareTrial from "./ShareTrial";
import SubjectCard from "./SubjectCard";
import TaskInfo from "./TaskInfo";
import TaskList from "./TaskList";
import TrialMoreOption from "./TrialMoreOption";

import { getTrialStatus } from "../../../utils/status";

const filterOptions = [
  "All",
  "Subjects with expiring tasks",
  "Status: Not Joined",
  "Status: Onboarded",
  "Status: Completed",
];

const tabCommonStyles = {
  fontSize: "16px",
  paddingX: "0",
  ml: "24px",
};

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "48vw",
  },
  "@media (min-width: 1526px)": {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "52vw",
    },
  },
  "@media (min-width: 1920px)": {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "56vw",
    },
  },
});

const TextField = styled(TextFieldBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "0px",
    padding: "10px",
    fontSize: 16,
  },
  overflow: "hidden",
  borderRadius: "4px",
}));

///////// Tab component ///////////
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function Trial(props) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { palette, typography } = useTheme();

  const [loading, setLoading] = useState(false);
  const [loadingSubjects, setLoadingSubjects] = useState(false);

  const [activeOnly, setActiveOnly] = useState(false);
  const [trial, setTrial] = useState({});
  const [subjects, setSubjects] = useState([]);
  const [createdHistory, setCreatedHistory] = useState([]);

  // table generation
  const [tableGenerated, setTableGenerated] = useState(false);

  const [search, setSearch] = useState("");

  // react-router-dom navigator
  const { id } = useParams();

  // pagination data
  const limit = 10;
  const [page, setPage] = useState(1);

  const saver = useSelector((state) => state.utils.saver);

  const userId = JSON.parse(localStorage.getItem("user")).id;

  const { devices, questionnaires, quesLoading, devisLoading } =
    useDeviceAndQuestionnaire();

  // redux
  const dispatch = useDispatch();

  const activateTrial = async () => {
    try {
      const res = await axios({
        method: "PUT",
        url: `${DOMAIN}/trials/activate-trial/`,
        headers: {
          Authorization: getAuthorization(),
        },
        data: {
          trial_id: id,
          is_active: true,
        },
      });

      if (res.status === 200) {
        dispatch(
          setNotify({
            open: true,
            action: "Trial activated successfully!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        fetchTrial();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTrial = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/trials/`,
        params: {
          id,
          deep: true,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      if (res.status === 200) {
        const data = res.data[0];
        setTrial(data);

        if (
          data.metadata &&
          data.metadata.history &&
          data.metadata.history.subject &&
          data.metadata.history.subject.created
        ) {
          setCreatedHistory(data.metadata.history.subject.created);
        }

        setLoading(false);
      }
    } catch (err) {
      console.log("ERROR:", err);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchTrial();
  }, [fetchTrial]);

  const fetchSubjects = useCallback(
    (page) => {
      setSubjects([]);
      setLoadingSubjects(true);

      axios({
        method: "GET",
        url: `${DOMAIN}/trials/subjects/`,
        params: {
          trial_id: id,
          page,
          limit,
          deep: true,
          status: activeOnly ? "subject.registered" : null,
          search,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      })
        .then((res) => {
          setSubjects(res.data.payload);
          setLoadingSubjects(false);
        })
        .catch((err) => {
          console.log("ERROR:", err);
          setLoadingSubjects(false);
        });
    },
    [id, search, activeOnly]
  );

  useEffect(() => {
    if (page === 1) {
      fetchSubjects(page);
    }
  }, [id, fetchSubjects, page]);

  const breadcrumbs = () => (
    <Stack direction="row" alignItems="center" marginRight="240px" spacing={3}>
      <Stack direction="row">
        <Link key="1" to="/">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <KeyboardBackspace sx={{ color: palette.neutral[700] }} />
            <Typography variant="c18px500" sx={{ color: palette.neutral[700] }}>
              All Trials
            </Typography>
          </Stack>
        </Link>

        <Typography px={1}>/</Typography>
        <CustomWidthTooltip
          key="2"
          title={trial.name}
          placement="bottom-start"
          arrow
        >
          <Typography
            sx={{
              color: palette.neutral[900],
              fontWeight: typography.fontWeightMedium,
              fontSize: "18px",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              textOverflow: "ellipsis",
            }}
          >
            {trial.name}
          </Typography>
        </CustomWidthTooltip>
      </Stack>
      <Stack>
        {trial.status ? (
          <Typography
            variant="c14px600"
            sx={{
              color: getTrialStatus(trial.status).color,
              padding: "4px 8px",
              backgroundColor: getTrialStatus(trial.status).backgroundColor,
              borderRadius: "4px",
            }}
          >
            {getTrialStatus(trial.status).label}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );

  const handleDownloadPDF = ({ data, _case }) => {
    // notify
    dispatch(
      setNotify({
        open: true,
        action: "Fetching PDF",
        severity: "info",
        autoHideDuration: 3000,
        vertical: "bottom",
        horizontal: "right",
      })
    );

    axios({
      method: "GET",
      url: `${DOMAIN}/trials/pdf/download/`,

      params: {
        trial_id: id,
        created_at_ts: data.created_at_ts,
        start_uid: data.start_uid,
        end_uid: data.end_uid,
        case: _case,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        const file_url = res.data.url;

        // notify
        dispatch(
          setNotify({
            open: true,
            action: "Downloading PDF",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );

        window.open(file_url, "_blank");
      })
      .catch((err) => {
        dispatch(
          setNotify({
            open: true,
            action: "The PDF is not ready yet, please try after some time.",
            severity: "error",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        console.log("ERROR:", err);
      });
  };

  const getIcon = (iconName) => {
    const iconStyle = {
      fontSize: "16px",
      marginRight: "5px",
      marginBottom: "-3.5px",
      color: "#999",
    };
    switch (iconName) {
      case "autoRenew":
        return <Autorenew style={iconStyle} />;
      case "Check":
        return <Check style={iconStyle} />;
      default:
        return null;
    }
  };

  return (
    <Loading loading={loading}>
      <Grid
        container
        style={{
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* START: Top Nav */}
            <Stack
              direction="column"
              // component={Paper}
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: palette.neutral[100],
                zIndex: 101,
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.07)",
              }}
            >
              {/* START: First Row */}
              <Stack
                // px={{ xs: "32px", ll: "48px" }}
                // paddingTop="32px"
                sx={{ padding: "32px 24px 20px 24px" }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  spacing={2}
                >
                  {breadcrumbs()}
                  {saver.open && (
                    <>
                      <Typography
                        style={{
                          fontSize: "14px",
                          textAlign: "left",
                          color: "#999",
                        }}
                      >
                        {getIcon(saver.action && saver.action.icon)}
                        {saver.title}
                      </Typography>
                    </>
                  )}
                </Stack>
                {/* Trial Overview and Share Trial */}
                <Stack direction="row" spacing={1}>
                  {trial.status === "approved" ? (
                    <Button
                      sx={{
                        backgroundColor: "#00993E",
                        color: palette.neutral[100],
                        fontSize: "16px",
                        fontWeight: "500",
                        padding: "10px 16px",
                      }}
                      onClick={activateTrial}
                    >
                      Go Live
                    </Button>
                  ) : null}
                  {trial && (
                    <TrialMoreOption
                      trial={trial}
                      isOwner={trial.partner === userId}
                    />
                  )}
                  <ShareTrial trialId={id} />
                </Stack>
              </Stack>
              {/* END: First Row */}
              {/* START: Second Row */}
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  px: "32px",
                }}
              >
                <Tabs value={value} onChange={handleChange}>
                  <Tab
                    label="Subjects"
                    sx={{
                      ...tabCommonStyles,
                      ml: 0,
                    }}
                  />
                  <Tab
                    label="Data"
                    sx={{
                      ...tabCommonStyles,
                    }}
                  />
                  <Tab
                    label="Devices & Questionnaires"
                    data-cy="devicesAndQuestionnaires"
                    sx={{
                      ...tabCommonStyles,
                    }}
                  />
                  <Tab
                    label="Tasks"
                    data-cy="tasks"
                    sx={{
                      ...tabCommonStyles,
                    }}
                  />
                </Tabs>
              </Box>
              {/* START: Third Row */}
              <TabPanel value={value} index={0}>
                <Stack
                  px={{ xs: "32px", ll: "48px" }}
                  py="12px"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    spacing={5}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormControl>
                      <TextField
                        fullWidth
                        variant="filled"
                        placeholder="Search by subject ID"
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setPage(1);
                          setSearch(e.target.value);
                        }}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={activeOnly}
                            color="primary"
                            style={{ marginRight: "4px" }}
                            onChange={() => {
                              setActiveOnly(!activeOnly);
                              setPage(1);
                              // fetchSubjects(1);
                            }}
                          />
                        }
                        label="Show subjects with active sessions"
                      />
                    </FormGroup>
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <AddSubjects id={id} />
                    <DownloadOptions
                      title="SOPs"
                      options={createdHistory}
                      onDownload={(item) =>
                        handleDownloadPDF({ data: item, _case: "sop" })
                      }
                      startIcon={
                        <FileDownloadOutlinedIcon
                          sx={{ color: palette.neutral[700] }}
                        />
                      }
                    />
                    <DownloadOptions
                      title="Subjects Table"
                      options={createdHistory}
                      onDownload={(item) =>
                        handleDownloadPDF({
                          data: item,
                          _case: "subjects-table",
                        })
                      }
                      startIcon={
                        <FileDownloadOutlinedIcon
                          sx={{ color: palette.neutral[700] }}
                        />
                      }
                    />
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Stack
                  px={{ xs: "32px", ll: "48px" }}
                  py="12px"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    spacing={5}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <GenerateTable setTableGenerated={setTableGenerated} />
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Stack
                  px={{ xs: "32px", ll: "48px" }}
                  py="12px"
                  direction="row"
                  alignItems="center"
                >
                  <Loading loading={devisLoading || quesLoading}>
                    <Stack
                      direction="row"
                      spacing={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="c16px500"
                        color={palette.neutral[700]}
                      >
                        {devices.length} Devices
                      </Typography>
                      <Typography
                        variant="c16px500"
                        color={palette.neutral[700]}
                      >
                        {questionnaires.length} Questionnaires
                      </Typography>
                    </Stack>
                  </Loading>
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Stack
                  px={{ xs: "32px", ll: "48px" }}
                  py="12px"
                  direction="row"
                  alignItems="center"
                >
                  <AddTask
                    trialDuration={trial.duration}
                    trialStatus={trial.status}
                  />
                </Stack>
              </TabPanel>
              {/* END: Third Row */}
            </Stack>
            <TabPanel value={value} index={0}>
              {/* START: Body */}
              <Grid
                container
                px={{ xs: "32px", ll: "48px" }}
                padding="24px"
                style={{
                  zIndex: 100,
                }}
                // spacing={2}
              >
                {trial.status === "approved" ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      width="100%"
                      spacing={1}
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="c18px600"
                        color={palette.neutral[700]}
                      >
                        {`${subjects.length} Subjects`}
                      </Typography>
                      <img src={line} alt="line" />
                      <Typography
                        variant="c14px500"
                        I
                        color={palette.neutral[700]}
                      >
                        {`${countByStatus(
                          subjects,
                          "subject.registered"
                        )} Onboarded`}
                      </Typography>
                      <img src={line} alt="line" />
                      <Typography
                        variant="c14px500"
                        color={palette.neutral[700]}
                      >
                        {`${countByStatus(
                          subjects,
                          "subject.created"
                        )} Not Joined`}
                      </Typography>
                      <img src={line} alt="line" />
                      <Typography
                        variant="c14px500"
                        color={palette.neutral[700]}
                      >
                        {`${countByStatus(
                          subjects,
                          "subject.completed"
                        )} Completed`}
                      </Typography>
                    </Stack>
                    <Stack sx={{ marginBottom: "10px" }}>
                      <FormControl variant="outlined">
                        <Select
                          variant="outlined"
                          // onChange={handleChange}
                          multiple
                          // value={filters}
                          defaultValue={[filterOptions[0]]}
                          renderValue={() => "Filter"}
                          sx={{
                            backgroundColor: palette.neutral[100],
                            borderRadius: "6px",
                            height: "36px",
                          }}
                        >
                          {filterOptions.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              sx={{
                                borderBottom:
                                  name === filterOptions[0]
                                    ? "1px solid #e2e2e2"
                                    : "none ",
                              }}
                            >
                              <Checkbox />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Stack>
                ) : null}
                <Stack width="100%" spacing={3}>
                  {loadingSubjects ? (
                    <Loading loading={loadingSubjects} height="70vh" />
                  ) : (trial.status === "approved" ||
                      trial.status === "live") &&
                    subjects &&
                    subjects.length ? (
                    subjects.map((item, index) => (
                      <SubjectCard
                        key={index}
                        {...item}
                        trialStatus={trial.status}
                      />
                    ))
                  ) : (
                    <NoData
                      heading={`${subjects.length} Subject(s) Added`}
                      message="Note: Subjects info would appear here, once the trial is approved!"
                      height="70vh"
                      hideIllustration={true}
                    />
                  )}
                </Stack>
              </Grid>
              {/* END: Body */}
              {/* START: CustomPagination */}
              {(page > 1 ||
                (subjects.payload && subjects.payload.length >= limit)) && (
                <CustomPagination
                  disabled={loadingSubjects}
                  last_page_no={subjects.last_page_no}
                  limit={subjects.payload && subjects.payload.length}
                  page={page}
                  handlePaginationChange={(_, value) => {
                    // fetchSubjects(value);
                    setPage(value);
                  }}
                />
              )}
              {/* END: CustomPagination */}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Stack sx={{ padding: "20px 24px" }}>
                {tableGenerated && <DataGridTable />}
              </Stack>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DevicesAndQuestionnaires />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Stack direction="row" alignItems="flex-start">
                <TaskList />
                {/* <TaskInfo /> */}
              </Stack>
            </TabPanel>
          </Box>

          {/* END: Second Row */}

          {/* END: Top Nav */}
        </Grid>
      </Grid>
    </Loading>
  );
}

export default Trial;
