import { ReactComponent as CompletedIcon } from "../assets/trial/completed.svg";
import { ReactComponent as MissedIcon } from "../assets/trial/missed.svg";
import { ReactComponent as ExpringIcon } from "../assets/trial/expiring.svg";
import { ReactComponent as StartIcon } from "../assets/trial/starting.svg";

export const taskStatuses = [
  {
    label: "Completed",
    color: "#007D33",
    icon: CompletedIcon,
  },
  {
    label: "Missed",
    color: "#B53836",
    icon: MissedIcon,
  },
  {
    label: "Live",
    color: "#B96F00",
    icon: ExpringIcon,
  },
  {
    label: "Upcoming",
    color: "#7E7E7E",
    icon: StartIcon,
  },
];

export const findLabelOfStatus = (status) => {
  const lowerCaseStatus = status.toLowerCase();

  for (const taskStatus of taskStatuses) {
    const lowerCaseLabel = taskStatus.label.toLowerCase();

    if (lowerCaseStatus.includes(lowerCaseLabel)) {
      return taskStatus;
    }
  }
};
