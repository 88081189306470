import { Grid, Stack, Typography, Paper, Button, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import Loading from "../../../components/Blockers/Loading";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled, useTheme } from "@mui/material/styles";
import { toCamelCase } from "../../../utils/camelCase";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
  },
});

export default function AllSurvey() {
  const [loading, setLoading] = useState(true);
  const [savedVersions, setSavedVersions] = useState([]);
  const [activeVersions, setActiveVersions] = useState([]);
  const [inactiveVersions, setInactiveVersions] = useState([]);
  const [trial, setTrial] = useState({});

  const { palette } = useTheme();

  const getSchedule = (props) => {
    switch (props.schedule) {
      case "BEFORE_TEST":
        return {
          text: "Before Test Starts",
          color: palette.success["main"],
          tooltip: "Before Test Starts",
        };
      case "AFTER_TEST":
        return {
          text: "After Test Ends",
          color: palette.neutral[500],
          tooltip: "After Test Ends",
        };
      default:
        return {
          text: "Custom Timimg",
          color: palette.primary[500],
          tooltip: `Start Date: ${props.timing.start_date}  End Date: ${props.timing.end_date} Timing: ${props.timing.timings} Active: ${props.timing.active_for}  `,
        };
    }
  };

  const navigator = useNavigate();

  const { id } = useParams();

  const userId = useSelector((state) => state.auth.user.id);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${DOMAIN}/trials/`,
      params: {
        id,
        deep: false,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        const data = res.data.payload[0];

        setTrial(data);
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  }, [id]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${DOMAIN}/surveys/`,
      params: {
        trial_id: id,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    }).then((res) => {
      const surveys = res.data.payload;
      let tempSavedVersions = [];
      let tempActiveVersions = [];
      let tempInactiveVersions = [];
      let tempOtherVersions = [];

      for (let i = 0; i < surveys.length; i++) {
        const survey = surveys[i];
        for (let j = 0; j < survey.versions.length; j++) {
          const surveyVersion = survey.versions[j];
          if (surveyVersion.status === "saved") {
            tempSavedVersions = [
              ...tempSavedVersions,
              {
                surveyId: survey.id,
                surveyVersionId: surveyVersion.id,
                title: survey.title,
                schedule: surveyVersion.timing,
                timing: surveyVersion.metadata.custom_timing,
                version: surveyVersion.version_number,
                status: surveyVersion.status,
                previousVersion: surveyVersion.previous_version?.version_number,
              },
            ];
          } else if (surveyVersion.status === "active") {
            tempActiveVersions = [
              ...tempActiveVersions,
              {
                surveyId: survey.id,
                surveyVersionId: surveyVersion.id,
                title: survey.title,
                schedule: surveyVersion.timing,
                timing: surveyVersion.metadata.custom_timing,
                version: surveyVersion.version_number,
                status: surveyVersion.status,
              },
            ];
          } else if (surveyVersion.status === "inactive") {
            tempInactiveVersions = [
              ...tempInactiveVersions,
              {
                surveyId: survey.id,
                surveyVersionId: surveyVersion.id,
                title: survey.title,
                schedule: surveyVersion.timing,
                timing: surveyVersion.metadata.custom_timing,
                version: surveyVersion.version_number,
                status: surveyVersion.status,
              },
            ];
          } else {
            tempOtherVersions = [
              ...tempOtherVersions,
              {
                surveyId: survey.id,
                surveyVersionId: surveyVersion.id,
                title: survey.title,
                schedule: surveyVersion.timing,
                timing: surveyVersion.metadata.custom_timing,
                version: surveyVersion.version_number,
                status: surveyVersion.status,
              },
            ];
          }
        }
      }
      setSavedVersions(tempSavedVersions);
      setActiveVersions(tempActiveVersions);
      setInactiveVersions(tempInactiveVersions);
      setLoading(false);
    });
  }, [id]);

  const Row = (props) => (
    <Grid
      container
      rowSpacing={1}
      sx={{
        padding: "2px 48px 2px",
        paddingX: { xs: "32px", ll: "48px" },
      }}
    >
      <Grid item xs={8} ll={9}>
        <Paper
          sx={{
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            height: "100%",
            padding: "12px 16px 12px 16px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigator(
              `/trial/${id}/survey/${props.surveyId}/${props.surveyVersionId}/`
            );
          }}
          elevation={0}
          data-cy={`survey__${toCamelCase(props.title)}`}
        >
          {props.status === "saved" ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                variant="c16px600"
                sx={{ color: palette.neutral[900] }}
              >
                {props.title}
              </Typography>
              {props.previousVersion && (
                <Typography
                  variant="c14px500"
                  sx={{ color: palette.neutral[500] }}
                >
                  (Derived from: V{props.previousVersion})
                </Typography>
              )}
            </Stack>
          ) : (
            <Typography variant="c16px600" sx={{ color: palette.neutral[900] }}>
              {props.title}
            </Typography>
          )}
        </Paper>
      </Grid>
      <Grid item xs={3} ll={2}>
        <Paper
          sx={{
            borderRadius: "0px",
            height: "100%",
            padding: "12px 16px 12px 0px",
          }}
          elevation={0}
        >
          <CustomWidthTooltip
            title={getSchedule(props).tooltip}
            arrow
            placement="left"
          >
            <Box
              sx={{
                padding: "4px 8px 4px 8px",
                backgroundColor: palette.neutral[200],
                display: "inline-block",
                borderRadius: "2px",
              }}
            >
              <Typography
                variant="c14px500"
                sx={{
                  color: palette.neutral[700],
                  width: "auto",
                  cursor: "pointer",
                }}
                align="left"
              >
                {getSchedule(props).text}
              </Typography>
            </Box>
          </CustomWidthTooltip>
        </Paper>
      </Grid>

      <Grid item xs={1}>
        <Paper
          sx={{
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
            height: "100%",
            padding: "12px 16px 12px 0px",
          }}
          elevation={0}
        >
          {props.status === "saved" ? null : (
            <Box
              sx={{
                padding: "4px 8px 4px 8px",
                backgroundColor: palette.neutral[200],
                display: "inline-block",
                borderRadius: "2px",
              }}
            >
              <Typography
                variant="c14px500"
                sx={{
                  color: palette.neutral[700],
                }}
              >
                V{props.version}
              </Typography>
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      style={{
        height: "100%",
        marginBottom: "40px",
      }}
    >
      <Loading loading={loading}>
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          {!savedVersions.length &&
          !activeVersions.length &&
          !inactiveVersions.length ? (
            <>
              {/* START:Body of no data page */}
              <Box>
                {/* START: Top Nav */}
                <Stack
                  direction="column"
                  // component={Paper}
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: palette.neutral[100],
                    zIndex: 101,
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.07)",
                  }}
                >
                  {/* START: First Row */}
                  <Stack
                    px={{ xs: "32px", ll: "48px" }}
                    py="24px"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Link key="1" to={`/trial/${id}`}>
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <ArrowBackIcon sx={{ color: palette.neutral.dark }} />
                        <Typography
                          data-cy="page__title"
                          variant="c18px600"
                          style={{
                            textAlign: "left",
                            color: palette.neutral[900],
                          }}
                        >
                          All Surveys
                        </Typography>
                      </Stack>
                    </Link>
                  </Stack>
                  {/* END: First Row */}
                </Stack>
                {/* END: Top Nav */}
              </Box>
              <Stack
                minHeight="90vh"
                width="100%"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                {trial && trial.partner === userId ? (
                  <>
                    <Link key="1" to={`/trial/${id}/survey/new`}>
                      <Button
                        data-cy="button__newSurvey"
                        disableElevation
                        sx={{
                          backgroundColor: palette.neutral[100],
                          color: palette.primary[500],
                          padding: "16px 24px 16px 24px ",
                          width: "688px",
                        }}
                        startIcon={<AddIcon />}
                      >
                        New Survey
                      </Button>
                    </Link>
                    <Typography
                      variant="c16px500"
                      style={{
                        textAlign: "left",
                        color: palette.neutral[500],
                      }}
                    >
                      No survey has been created yet
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant="c16px500"
                    style={{
                      textAlign: "left",
                      color: palette.neutral[500],
                    }}
                  >
                    No surveys found
                  </Typography>
                )}
              </Stack>
              {/* END: Body of no data page */}
            </>
          ) : (
            <>
              <Box>
                {/* START: Top Nav */}
                <Stack
                  direction="column"
                  // component={Paper}
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: palette.neutral[100],
                    zIndex: 101,
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.07)",
                  }}
                >
                  {/* START: First Row */}
                  <Stack
                    px={{ xs: "32px", ll: "48px" }}
                    py="24px"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Link key="1" to={`/trial/${id}`}>
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <ArrowBackIcon sx={{ color: palette.neutral.dark }} />
                        <Typography
                          variant="c18px600"
                          style={{
                            textAlign: "left",
                            color: palette.neutral[900],
                          }}
                        >
                          All Surveys
                        </Typography>
                      </Stack>
                    </Link>
                    <Stack>
                      {trial && trial.partner === userId ? (
                        <Link to={`/trial/${id}/survey/new`}>
                          <Button
                            data-cy="button__newSurvey"
                            variant="outlined"
                            disableElevation
                            sx={{
                              backgroundColor: palette.neutral[100],
                              color: palette.primary[500],
                            }}
                            startIcon={<AddIcon />}
                          >
                            New Survey
                          </Button>
                        </Link>
                      ) : null}
                    </Stack>
                  </Stack>
                  {/* END: First Row */}
                </Stack>
                {/* END: Top Nav */}
              </Box>
              {/* START: Body */}
              {/* ACTIVE SURVEYS */}

              <Grid
                container
                rowSpacing={1}
                sx={{
                  padding: "32px 48px 12px",
                  paddingX: { xs: "32px", ll: "48px" },
                }}
              >
                <Grid item xs={8} ll={9}>
                  <Paper
                    sx={{
                      borderTopLeftRadius: "6px",
                      borderBottomLeftRadius: "6px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      height: "100%",
                      padding: "16px 14px 16px 14px",
                    }}
                    elevation={0}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Box
                        sx={{
                          backgroundColor: palette.success["main"],
                          color: palette.neutral[100],
                          borderRadius: "6px",
                          paddingY: "4px",
                          paddingX: "8px",
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <FiberManualRecordIcon fontSize="small" />
                          <Typography
                            variant="c16px600"
                            sx={{ color: palette.neutral[100] }}
                          >
                            Active Surveys
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={3} ll={2}>
                  <Paper
                    sx={{
                      borderRadius: "0px",
                      height: "100%",
                      padding: "16px 0px 16px 0px",
                    }}
                    elevation={0}
                  >
                    <Typography
                      variant="c16px600"
                      sx={{
                        color: palette.neutral[700],
                      }}
                    >
                      Schedule
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Paper
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      borderTopRightRadius: "6px",
                      borderBottomRightRadius: "6px",
                      height: "100%",
                      padding: "16px 0px 16px 0px",
                    }}
                    elevation={0}
                  >
                    <Typography
                      variant="c16px600"
                      sx={{
                        color: palette.neutral[700],
                      }}
                    >
                      Version
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              {activeVersions.length ? (
                activeVersions.map((item, index) => (
                  <Row key={index} {...item} />
                ))
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: "2px 48px 2px",
                    paddingX: { xs: "32px", ll: "48px" },
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: "6px",
                      height: "100%",
                      padding: "12px 16px 12px 16px",
                      cursor: "pointer",
                    }}
                    elevation={0}
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="c16px600"
                        sx={{
                          color: palette.neutral[900],
                        }}
                      >
                        No Surveys Found{" "}
                      </Typography>
                    </Stack>{" "}
                  </Paper>
                </Grid>
              )}

              {/* SAVED SURVEYS */}
              <Grid
                container
                rowSpacing={1}
                sx={{
                  padding: "32px 48px 12px",
                  paddingX: { xs: "32px", ll: "48px" },
                }}
              >
                <Grid item xs={8} ll={9}>
                  <Paper
                    sx={{
                      borderTopLeftRadius: "6px",
                      borderBottomLeftRadius: "6px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      height: "100%",
                      padding: "16px 14px 16px 14px",
                    }}
                    elevation={0}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Box
                        sx={{
                          backgroundColor: palette.primary[500],
                          color: palette.neutral[100],
                          borderRadius: "6px",
                          paddingY: "4px",
                          paddingX: "8px",
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <FiberManualRecordIcon fontSize="small" />
                          <Typography variant="c16px600">
                            Saved Surveys
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={3} ll={2}>
                  <Paper
                    sx={{
                      borderRadius: "0px",
                      height: "100%",
                      padding: "16px 0px 16px 0px",
                    }}
                    elevation={0}
                  >
                    <Typography
                      variant="c16px600"
                      sx={{
                        color: palette.neutral[700],
                      }}
                    >
                      Schedule
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Paper
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      borderTopRightRadius: "6px",
                      borderBottomRightRadius: "6px",
                      height: "100%",
                      padding: "16px 0px 16px 0px",
                    }}
                    elevation={0}
                  ></Paper>
                </Grid>
              </Grid>
              {savedVersions.length ? (
                savedVersions.map((item, index) => (
                  <Row key={index} {...item} />
                ))
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: "2px 48px 2px 48px",
                    paddingX: { xs: "32px", ll: "48px" },
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: "6px",
                      height: "100%",
                      padding: "12px 16px 12px 16px",
                      cursor: "pointer",
                    }}
                    elevation={0}
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="c16px600"
                        sx={{
                          color: palette.neutral[900],
                        }}
                      >
                        No Surveys Found{" "}
                      </Typography>
                    </Stack>{" "}
                  </Paper>
                </Grid>
              )}
              {/* INACTIVE SURVEYS */}
              <Grid
                container
                rowSpacing={1}
                sx={{
                  padding: "32px 48px 12px",
                  paddingX: { xs: "32px", ll: "48px" },
                }}
              >
                <Grid item xs={8} ll={9}>
                  <Paper
                    sx={{
                      borderTopLeftRadius: "6px",
                      borderBottomLeftRadius: "6px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      height: "100%",
                      padding: "16px 14px 16px 14px",
                    }}
                    elevation={0}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Box
                        sx={{
                          backgroundColor: palette.neutral[500],
                          color: palette.neutral[100],
                          borderRadius: "6px",
                          paddingY: "4px",
                          paddingX: "8px",
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <FiberManualRecordIcon fontSize="small" />
                          <Typography variant="c16px600">
                            Inactive Surveys
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>

                <Grid item xs={3} ll={2}>
                  <Paper
                    sx={{
                      borderRadius: "0px",
                      height: "100%",
                      padding: "16px 0px 16px 0px",
                    }}
                    elevation={0}
                  >
                    <Typography
                      variant="c16px600"
                      sx={{
                        color: palette.neutral[700],
                      }}
                    >
                      Schedule
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Paper
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      borderTopRightRadius: "6px",
                      borderBottomRightRadius: "6px",
                      height: "100%",
                      padding: "16px 0px 16px 0px",
                    }}
                    elevation={0}
                  >
                    <Typography
                      variant="c16px600"
                      sx={{
                        color: palette.neutral[700],
                      }}
                    >
                      Version
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              {inactiveVersions.length ? (
                inactiveVersions.map((item, index) => (
                  <Row key={index} {...item} />
                ))
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: "2px 48px 2px",
                    paddingX: { xs: "32px", ll: "48px" },
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: "6px",
                      height: "100%",
                      padding: "12px 16px 12px 16px",
                      cursor: "pointer",
                    }}
                    elevation={0}
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="c16px600"
                        sx={{
                          color: palette.neutral[900],
                        }}
                      >
                        No Surveys Found{" "}
                      </Typography>
                    </Stack>{" "}
                  </Paper>
                </Grid>
              )}
              {/* END: Body */}
            </>
          )}
        </Grid>
      </Loading>
    </Grid>
  );
}
