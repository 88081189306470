import axios from "axios";
import { createContext, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DOMAIN } from "../utils/config";
import { getAuthorization } from "../utils/helpers";

export const TaskContext = createContext({
  devices: [],
  questionnaires: [],
  loading: true,
  fetchDevices: () => {},
  fetchQuestionnaires: () => {},
});

const TaskProvider = ({ children }) => {
  const { id } = useParams();
  const [devices, setDevices] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchDevices = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/tasks/devices/`,
        headers: {
          Authorization: getAuthorization(),
        },
        params: { trial_id: id },
      });

      if (res.status === 200) {
        setDevices(res.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [id]);

  const fetchQuestionnaires = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/tasks/questionnaires/`,
        headers: {
          Authorization: getAuthorization(),
        },
        params: { trial_id: id },
      });

      if (res.status === 200) {
        setQuestionnaires(res.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchDevices();
    fetchQuestionnaires();
  }, [fetchDevices, fetchQuestionnaires]);

  return (
    <TaskContext.Provider
      value={{
        devices,
        questionnaires,
        loading,
        fetchDevices,
        fetchQuestionnaires,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export default TaskProvider;
