import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Chip,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CustomTextField } from "../../../components/CustomTextField";
import { TimePicker } from "@mui/x-date-pickers";
// import { styled } from "@mui/material/styles";
// import { tooltipClasses } from "@mui/material/Tooltip";
import { Add, ExpandMore, Close } from "@mui/icons-material";
import { useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { anyValueTrue, getAuthorization } from "../../../utils/helpers";
import { setNotify } from "../../../redux/utils/utilsSlice";

// const CustomWidthTooltip = styled(({ className, ...props }) => (
//   <Tooltip
//     title="If enabled, the survey will be active/functional till the next survey appears."
//     {...props}
//     classes={{ popper: className }}
//   />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: 200,
//   },
// });

const defaultWeekFreq = {
  sun: false,
  mon: false,
  tue: false,
  wed: false,
  thur: false,
  fri: false,
  sat: false,
};
const timeZones = [
  {
    value: "BEFORE_TEST",
    label: "Before the test starts",
  },

  {
    value: "AFTER_TEST",
    label: "After the test ends",
  },
  {
    value: "CUSTOM",
    label: "Custom timing",
  },
];
export default function EditSchedule({ surveyVersion }) {
  const { surveyVersionId } = useParams();
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const [loading, setLoading] = useState(false);
  const defaultCustomTiming = surveyVersion.metadata?.custom_timing;

  const [addTiming, setAddTiming] = useState("");

  const [startDate, setStartDate] = useState(
    moment(defaultCustomTiming?.start_date, "DD/MM/YYYY") || ""
  );
  const [endDate, setEndDate] = useState(
    moment(defaultCustomTiming?.end_date, "DD/MM/YYYY") || ""
  );
  const [weekFrequency, setWeekFrequency] = useState(
    defaultCustomTiming?.frequency?.week || defaultWeekFreq
  );
  const [dayFrequency, setDayFrequency] = useState(
    defaultCustomTiming?.frequency?.day || ""
  );
  const [activeFor, setActiveFor] = useState(
    defaultCustomTiming?.active_for || ""
  );
  const [timeIndication, setTimeIndication] = useState("min");
  const [displayTime, setDisplayTime] = useState(
    defaultCustomTiming?.timings?.map((t) => moment(t, "hh:mm A")) || []
  );

  const [timing, setTiming] = useState(
    surveyVersion.timing || timeZones[0].value
  );

  // ERRORS
  const [startDateShowError, setStartDateShowError] = useState(false);
  const [endDateShowError, setEndDateShowError] = useState(false);
  const [frequencyShowError, setFrequencyShowError] = useState(false);
  const [timingShowError, setTimingShowError] = useState(false);
  const [activeForShowError, setActiveForShowError] = useState(false);

  // Disable dates before the specified date, excluding that date
  const shouldDisableDate = (date) => {
    const currentDate = new Date(date);
    currentDate.setHours(0, 0, 0, 0);
    const disabledDateTime = new Date(startDate);
    disabledDateTime.setHours(0, 0, 0, 0);
    return currentDate < disabledDateTime;
  };

  const handleEditSurvey = async (e) => {
    // handle form submit
    e.preventDefault();

    // set loading to true
    setLoading(true);

    try {
      // make API request
      let metadata = {};

      if (timing === "CUSTOM") {
        let hasError = false;

        // remove errors from state, if any
        if (!startDate.isValid()) {
          setStartDateShowError(true);
          hasError = true;
        }
        if (!endDate.isValid()) {
          setEndDateShowError(true);
          hasError = true;
        }
        if (!anyValueTrue(weekFrequency) && !Boolean(dayFrequency)) {
          setFrequencyShowError(true);
          hasError = true;
        }
        if (displayTime.length < 1) {
          setTimingShowError(true);
          hasError = true;
        }
        if (!activeFor) {
          setActiveForShowError(true);
          hasError = true;
        }

        if (hasError) {
          setLoading(false);
          return;
        }

        let tempActiveFor = activeFor;
        if (timeIndication === "hr") {
          tempActiveFor = activeFor * 60;
        }

        metadata = {
          custom_timing: {
            start_date: moment(startDate).format("DD/MM/YYYY"),
            end_date: moment(endDate).format("DD/MM/YYYY"),
            frequency: {
              week: weekFrequency,
              day: dayFrequency,
            },
            timings: displayTime.map((i) => moment(i).format("hh:mm A")),
            active_for: tempActiveFor,
          },
        };
      }

      await axios({
        method: "PATCH",
        url: `${DOMAIN}/surveys/versions/`,
        params: {
          id: surveyVersionId,
        },
        data: {
          timing,
          metadata,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      setLoading(false);

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Successfully Updated survey",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      // navigate(
      //   `/trial/${id}/survey/${res.data.id}/${res.data.versions[0].id}/edit`
      // );

      window.location.reload();
    } catch (err) {
      // set loading to false
      setLoading(false);
      console.log(err);
    }
  };
  const addTime = () => {
    if (addTiming !== "") {
      setDisplayTime([...displayTime, addTiming]);
      setAddTiming("");
    }
  };

  return (
    <>
      <Stack sx={{ width: "100%" }} direction="column" spacing={2}>
        <Stack sx={{ width: "100%" }} direction="column">
          <Stack
            direction="row"
            sx={{
              padding: "12px 8px 4px 8px",
              border: "1px solid #F7F7F7",
              height: "100%",
              width: "100%",
            }}
          >
            <FormControlLabel
              data-cy="option__beforeSleep"
              control={
                <Checkbox
                  checked={timing === timeZones[0]?.value}
                  onChange={() => {
                    setTiming(timeZones[0]?.value);
                  }}
                  name="jason"
                />
              }
              label={timeZones[0].label}
            />
          </Stack>
          <Stack
            sx={{
              padding: "12px 8px 4px 8px",
              border: "1px solid #F7F7F7",
              height: "100%",
              width: "100%",
            }}
          >
            <FormControlLabel
              data-cy="option__afterSleep"
              control={
                <Checkbox
                  checked={timing === timeZones[1]?.value}
                  onChange={() => {
                    setTiming(timeZones[1]?.value);
                  }}
                  name="jason"
                />
              }
              label={timeZones[1].label}
            />
          </Stack>
          <Stack
            sx={{
              border: "1px solid #F7F7F7",
              height: "100%",
              width: "100%",
            }}
          >
            <Accordion
              expanded={timing === timeZones[2].value}
              onChange={(e, expanded) => {
                if (expanded) {
                  setTiming(timeZones[2].value);
                } else {
                  setTiming(timeZones[0].value);
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="c14px500">Custom Timing</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack sx={{ width: "100%" }} direction="column" spacing={2}>
                  <Stack
                    direction="row"
                    spacing={6}
                    sx={{
                      padding: "12px 8px 4px 8px",
                      border: "1px solid #F7F7F7",
                      height: "100%",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                  >
                    <Stack direction="column" spacing={1}>
                      <Typography
                        variant="c12px600"
                        sx={{
                          color: palette.neutral[500],
                        }}
                      >
                        START DATE
                      </Typography>

                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                          value={startDate}
                          onChange={(e) => {
                            setStartDateShowError(false);
                            setStartDate(e);
                          }}
                          inputFormat="MM/DD/YYYY"
                          InputProps={{ disableUnderline: true }}
                          renderInput={(params) => (
                            <CustomTextField
                              data-cy="datepicker__startDate"
                              fullWidth
                              required
                              variant="filled"
                              type="text"
                              {...params}
                            />
                          )}
                          disablePast={true}
                        />
                      </LocalizationProvider>
                      {startDateShowError && (
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: "400",
                            color: palette.error.main,
                            lineHeight: "14.4px",
                          }}
                        >
                          Start Date is required
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <Typography
                        variant="c12px600"
                        sx={{
                          color: palette.neutral[500],
                        }}
                      >
                        END DATE
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                          value={endDate}
                          onChange={(e) => {
                            setEndDateShowError(false);
                            setEndDate(e);
                          }}
                          inputFormat="MM/DD/YYYY"
                          InputProps={{ disableUnderline: true }}
                          renderInput={(params) => (
                            <CustomTextField
                              data-cy="datepicker__endDate"
                              fullWidth
                              required
                              variant="filled"
                              type="text"
                              {...params}
                            />
                          )}
                          disablePast={true}
                          shouldDisableDate={shouldDisableDate}
                        />
                      </LocalizationProvider>
                      {endDateShowError && (
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: "400",
                            color: palette.error.main,
                            lineHeight: "14.4px",
                          }}
                        >
                          End Date is required
                        </Typography>
                      )}
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={6}
                    sx={{
                      padding: "12px 8px 4px 8px",
                      border: "1px solid #F7F7F7",
                      height: "100%",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                  >
                    <Stack direction="column" spacing={1}>
                      <Typography
                        variant="c12px600"
                        sx={{
                          color: palette.neutral[500],
                        }}
                      >
                        FREQUENCY
                      </Typography>

                      <Stack direction="row">
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ color: palette.neutral[500] }}
                              checked={weekFrequency.sun}
                              onChange={() => {
                                setFrequencyShowError(false);
                                setDayFrequency("");
                                setWeekFrequency({
                                  ...weekFrequency,
                                  sun: !weekFrequency.sun,
                                });
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="c12px400"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              Sun
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          data-cy="day__sunday"
                          control={
                            <Checkbox
                              sx={{ color: palette.neutral[500] }}
                              checked={weekFrequency.mon}
                              onChange={() => {
                                setFrequencyShowError(false);
                                setDayFrequency("");
                                setWeekFrequency({
                                  ...weekFrequency,
                                  mon: !weekFrequency.mon,
                                });
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="c12px400"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              Mon
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={weekFrequency.tue}
                              onChange={() => {
                                setFrequencyShowError(false);
                                setDayFrequency("");
                                setWeekFrequency({
                                  ...weekFrequency,
                                  tue: !weekFrequency.tue,
                                });
                              }}
                              sx={{ color: palette.neutral[500] }}
                            />
                          }
                          label={
                            <Typography
                              variant="c12px400"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              Tue
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={weekFrequency.wed}
                              onChange={() => {
                                setFrequencyShowError(false);
                                setDayFrequency("");
                                setWeekFrequency({
                                  ...weekFrequency,
                                  wed: !weekFrequency.wed,
                                });
                              }}
                              sx={{ color: palette.neutral[500] }}
                            />
                          }
                          label={
                            <Typography
                              variant="c12px400"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              Wed
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={weekFrequency.thur}
                              onChange={() => {
                                setFrequencyShowError(false);
                                setDayFrequency("");
                                setWeekFrequency({
                                  ...weekFrequency,
                                  thur: !weekFrequency.thur,
                                });
                              }}
                              sx={{ color: palette.neutral[500] }}
                            />
                          }
                          label={
                            <Typography
                              variant="c12px400"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              Thu
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={weekFrequency.fri}
                              onChange={() => {
                                setFrequencyShowError(false);
                                setDayFrequency("");
                                setWeekFrequency({
                                  ...weekFrequency,
                                  fri: !weekFrequency.fri,
                                });
                              }}
                              sx={{ color: palette.neutral[500] }}
                            />
                          }
                          label={
                            <Typography
                              variant="c12px400"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              Fri
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          data-cy="day__saturday"
                          control={
                            <Checkbox
                              checked={weekFrequency.sat}
                              onChange={() => {
                                setFrequencyShowError(false);
                                setDayFrequency("");
                                setWeekFrequency({
                                  ...weekFrequency,
                                  sat: !weekFrequency.sat,
                                });
                              }}
                              sx={{ color: palette.neutral[500] }}
                            />
                          }
                          label={
                            <Typography
                              variant="c12px400"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              Sat
                            </Typography>
                          }
                        />
                      </Stack>
                      <Divider />
                      <RadioGroup
                        row
                        value={dayFrequency}
                        onChange={(e) => {
                          setFrequencyShowError(false);
                          setWeekFrequency(defaultWeekFreq);
                          setDayFrequency(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          data-cy="radio__daily"
                          value="1"
                          control={<Radio />}
                          label={
                            <Typography
                              variant="c12px400"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              Daily
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          data-cy="radio__alternateDay"
                          value="2"
                          control={<Radio />}
                          label={
                            <Typography
                              variant="c12px400"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              Alternate Day
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          data-cy="radio__everyThirdDay"
                          value="3"
                          control={<Radio />}
                          label={
                            <Typography
                              variant="c12px400"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              Every 3 days
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </Stack>
                  </Stack>
                  {frequencyShowError && (
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: palette.error.main,
                        lineHeight: "14.4px",
                      }}
                    >
                      Frequency is required
                    </Typography>
                  )}

                  <Stack
                    direction="column"
                    sx={{
                      padding: "12px 8px 4px 8px",
                      border: "1px solid #F7F7F7",
                      height: "100%",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      variant="c12px600"
                      sx={{
                        color: palette.neutral[500],
                      }}
                    >
                      TIMING
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          ampm={true}
                          ampmInClock={true}
                          value={addTiming}
                          onChange={(e) => {
                            setTimingShowError(false);
                            setAddTiming(e);
                          }}
                          InputProps={{ disableUnderline: true }}
                          renderInput={(params) => (
                            <CustomTextField
                              data-cy="time__picker"
                              sx={{
                                width: "35%",
                                marginTop: "20px",
                              }}
                              fullWidth
                              required
                              variant="filled"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>

                      <Button
                        data-cy="button__add"
                        disableElevation
                        variant="text"
                        startIcon={<Add />}
                        onClick={addTime}
                      >
                        Add
                      </Button>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      {displayTime.map((timing, index) => (
                        <Chip
                          value={addTiming}
                          key={index}
                          label={moment(timing).format("hh:mm A")}
                          onDelete={() => {
                            setDisplayTime(
                              displayTime.filter((i) => i !== timing)
                            );
                          }}
                          variant="outlined"
                          deleteIcon={<Close />}
                        />
                      ))}
                    </Stack>
                    {timingShowError && (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: "400",
                          color: palette.error.main,
                          lineHeight: "14.4px",
                        }}
                      >
                        Timing is required
                      </Typography>
                    )}
                  </Stack>
                  <Stack
                    direction="column"
                    sx={{
                      padding: "12px 8px 4px 8px",
                      border: "1px solid #F7F7F7",
                      height: "100%",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      variant="c12px600"
                      sx={{
                        color: palette.neutral[500],
                      }}
                    >
                      ACTIVE FOR
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <FormControl>
                        <CustomTextField
                          data-cy="input__activeFor"
                          sx={{ width: "100px", marginTop: "20px" }}
                          required
                          variant="filled"
                          placeholder="00"
                          type="number"
                          value={activeFor}
                          onChange={(e) => {
                            setActiveForShowError(false);
                            setActiveFor(e.target.value);
                          }}
                          InputProps={{ disableUnderline: true }}
                        />
                      </FormControl>
                      <FormControl>
                        <Select
                          data-cy="select__activeFor"
                          sx={{ width: "77px", height: "42px" }}
                          required
                          variant="filled"
                          type="text"
                          value={timeIndication}
                          onChange={(e) => {
                            setActiveForShowError(false);
                            setTimeIndication(e.target.value);
                          }}
                        >
                          <MenuItem data-cy="option__minute" value="min">
                            min
                          </MenuItem>
                          <MenuItem data-cy="option__hour" value="hr">
                            hr
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                    <Divider />
                    {/* <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={Boolean(parseInt(activeFor))}
                            checked={!Boolean(parseInt(activeFor))}
                            onChange={(e) => {
                              setActiveForShowError(false);
                              if (e.target.checked) {
                                setActiveFor(0);
                              } else {
                                setActiveFor(60);
                              }
                            }}
                            sx={{ color: palette.neutral[500] }}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              fontSize: "12x",
                              fontWeight: "400",
                              color: palette.neutral[500],
                            }}
                          >
                            Till next survey appears{" "}
                          </Typography>
                        }
                      />
                      <CustomWidthTooltip arrow placement="top">
                        <HelpOutline sx={{ color: palette.neutral[500] }} />
                      </CustomWidthTooltip>
                    </Stack> */}
                    {activeForShowError && (
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: "400",
                          color: palette.error.main,
                          lineHeight: "14.4px",
                        }}
                      >
                        Active For is required
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
        <Button
          data-cy="button__done"
          onClick={handleEditSurvey}
          disabled={loading}
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: palette.primary[500],
            color: palette.neutral[100],
          }}
        >
          Done
        </Button>
      </Stack>
    </>
  );
}
