import { Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import AddQuestionnaire from "./AddQuestionnaire";
import Devices from "./Devices";
import Questionnaires from "./Questionnaires";

export default function DevicesAndQuestionnaires() {
  const { palette } = useTheme();

  return (
    <Grid
      container
      px={{ xs: "32px", ll: "48px" }}
      paddingTop="32px"
      sx={{ backgroundColor: palette.primary[100] }}
    >
      <Grid item xs>
        <Devices />
      </Grid>
      <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
      <Grid item xs>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="c18px600">Questionnaires</Typography>
          <AddQuestionnaire />
        </Stack>
        <Questionnaires />
      </Grid>
    </Grid>
  );
}
