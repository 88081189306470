import { Stack, Typography, useTheme } from "@mui/material";
import Loading from "../../../components/Blockers/Loading";
import useDeviceAndQuestionnaire from "../../../hooks/useDevice&Questionnaire";

function QuestionnaireCard(props) {
  const { palette } = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        backgroundColor: palette.neutral[100],
        border: `1px solid ${palette.neutral[300]}`,
        borderRadius: "6px",
        padding: "20px",
      }}
    >
      <Stack spacing={0.75}>
        <Typography variant="c12px600" color={palette.neutral[500]}>
          Questionnaire
        </Typography>
        <Typography variant="c16px600" color={palette.neutral[700]}>
          {props.questionnaire.title}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default function Questionnaires() {
  const { questionnaires, quesLoading } = useDeviceAndQuestionnaire();

  return (
    <Loading loading={quesLoading}>
      <Stack spacing={1} marginTop={1.5}>
        {questionnaires.map((questionnaire) => (
          <QuestionnaireCard
            key={questionnaire.id}
            questionnaire={questionnaire}
          />
        ))}
      </Stack>
    </Loading>
  );
}
