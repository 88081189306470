import { ArrowRightAlt, Close } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import {
  AccordionDetails,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { findLabelOfStatus } from "../../../utils/subjectTaskStatus";
import { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const titleCellStyles = {
  fontSize: "16px",
  fontWeight: 500,
  color: "#2D2D2D",
};

const dataCellStyles = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#2D2D2D",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  const { palette } = useTheme();

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: palette.neutral[700] }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SubjectTaskList(props) {
  const { palette } = useTheme();
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const renderTasks = () => {
    if (props.tasks && props.tasks.length > 0)
      return (
        <>
          {props.tasks.map((task) => {
            const { color, label, icon: Icon } = findLabelOfStatus(task.status);

            return (
              <TableRow
                key={task.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: palette.neutral[900],
                  }}
                >
                  {task.task || task.title}
                </TableCell>
                <TableCell
                  sx={{
                    ...dataCellStyles,
                  }}
                >
                  {task.device || task.questionnaire}
                </TableCell>
                <TableCell
                  sx={{
                    ...dataCellStyles,
                  }}
                >
                  {task.schedule_time}
                </TableCell>
                <TableCell
                  sx={{
                    ...dataCellStyles,
                    color,
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Icon />
                    <Typography
                      sx={{
                        ...dataCellStyles,
                        color,
                      }}
                    >
                      {label}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </>
      );
  };

  return (
    <AccordionDetails
      style={{
        padding: "8px 16px",
      }}
    >
      <Stack direction="row" alignItems="start" spacing={2} my={2}>
        <Stack>
          <Typography
            variant="c14px500"
            color={palette.neutral[500]}
            style={{
              textAlign: "left",
            }}
          >
            Secret Key
          </Typography>
          <Typography
            variant="c16px500"
            color={palette.neutral[900]}
            style={{
              textAlign: "left",
            }}
          >
            {props.secretKey}
          </Typography>
        </Stack>
        <Stack>
          <Typography
            variant="c14px500"
            color={palette.neutral[500]}
            style={{
              textAlign: "left",
            }}
          >
            Current Day
          </Typography>
          <Typography
            variant="c16px500"
            color={palette.neutral[900]}
            style={{
              textAlign: "left",
            }}
          >
            {props.currentDay}
          </Typography>
        </Stack>
      </Stack>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: `1px solid ${palette.neutral[300]}`,
          borderRadius: "6px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: palette.neutral[200] }}>
              <TableCell
                sx={{
                  ...titleCellStyles,
                }}
              >
                Today's Task
              </TableCell>
              <TableCell
                sx={{
                  ...titleCellStyles,
                }}
              >
                Device/Questionnaire
              </TableCell>
              <TableCell
                sx={{
                  ...titleCellStyles,
                }}
              >
                Timing
              </TableCell>
              <TableCell
                sx={{
                  ...titleCellStyles,
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTasks()}</TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={1.5}
      >
        <Button
          variant="text"
          sx={{
            color: palette.primary[700],
            borderRadius: "6px",
            "&:hover": {
              color: palette.primary[700],
              backgroundColor: palette.neutral[100],
            },
          }}
          endIcon={<ArrowRightAlt />}
          onClick={handleClickOpen}
        >
          View Previous Days
        </Button>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
          >
            <Stack width="100px">
              <Typography
                variant="c14px500"
                color={palette.neutral[500]}
                style={{
                  textAlign: "left",
                }}
              >
                Subject Id
              </Typography>
              <Typography
                variant="c16px500"
                color={palette.neutral[900]}
                style={{
                  textAlign: "left",
                }}
              >
                001
              </Typography>
            </Stack>
            <Divider sx={{ marginTop: "10px" }} />
          </BootstrapDialogTitle>
          <DialogContent>
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{
                border: `1px solid ${palette.neutral[300]}`,
                borderRadius: "6px",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: palette.neutral[200] }}>
                    <TableCell
                      sx={{
                        ...titleCellStyles,
                      }}
                    >
                      Day 15/60
                    </TableCell>
                    <TableCell
                      sx={{
                        ...titleCellStyles,
                      }}
                    >
                      Device/Questionnaire
                    </TableCell>
                    <TableCell
                      sx={{
                        ...titleCellStyles,
                      }}
                    >
                      Timing
                    </TableCell>
                    <TableCell
                      sx={{
                        ...titleCellStyles,
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.tasks.map((task) => {
                    const { color, icon: Icon } = findLabelOfStatus(
                      task.status
                    );

                    return (
                      <TableRow
                        key={uuid()}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            color: palette.neutral[900],
                          }}
                        >
                          {task.title}
                        </TableCell>
                        <TableCell
                          sx={{
                            ...dataCellStyles,
                          }}
                        >
                          {task.deviceOrQuestionnaire}
                        </TableCell>
                        <TableCell
                          sx={{
                            ...dataCellStyles,
                          }}
                        >
                          {task.timing}
                        </TableCell>
                        <TableCell
                          sx={{
                            ...dataCellStyles,
                            color,
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Icon />
                            <Typography sx={{ ...dataCellStyles, color }}>
                              {task.status}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </BootstrapDialog>
      </Stack>
    </AccordionDetails>
  );
}
