import axios from "axios";

import { getAuthorization } from "../utils/helpers";
import { DOMAIN } from "../utils/config";

export const getTrialDeviceServices = async (params) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${DOMAIN}/devices/`,
      params,
      headers: {
        Authorization: getAuthorization(),
      },
    });

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const createTrialDeviceServices = async (params, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${DOMAIN}/trials/devices/services/`,
      params,
      headers: {
        Authorization: getAuthorization(),
      },
      data,
    });

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updateTrialDeviceServices = async (params, data) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${DOMAIN}/trials/devices/services/`,
      params,
      headers: {
        Authorization: getAuthorization(),
      },
      data,
    });

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const deleteTrialDeviceServices = async (params) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: `${DOMAIN}/trials/devices/services/`,
      params,
      headers: {
        Authorization: getAuthorization(),
      },
    });

    return res;
  } catch (err) {
    console.log(err);
  }
};
