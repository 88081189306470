import * as React from "react";
import { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSelector, useDispatch } from "react-redux";
import {
  setCategories,
  setBiomarkerKeys,
  setValues,
  setSelectedBiomarkerKeys,
  setCheckedBiomarkersWithId,
} from "../../../redux/biomarkers/biomarkerSlice";
import axios from "axios";

import { getAuthorization } from "../../../utils/helpers";
import { CUSTOM_CATEGORY_NAME, DOMAIN } from "../../../utils/config";
import { useParams } from "react-router-dom";
import AddBiomarker from "./AddBiomarker";
import DeleteBiomarker from "./DeleteBiomarker";
import download from "../../../assets/common/download.svg";
const DEFAULT_VALUE = false;
const getNestedDefaultValuedArray = (array, nestingKey) => {
  // returns array from provided array with value as DEFAULT_VALUE (eg. `false`),
  // eg. [false, false, false]
  return array[nestingKey].map(() => DEFAULT_VALUE);
};

const getDefaultValuedArray = (array, nestingKey) => {
  // returns array from provided array with value as array of DEFAULT_VALUE,
  // eg. [[false, false], [false]]

  return array.map((item) => getNestedDefaultValuedArray(item, nestingKey));
};

// function for sorting biormarkers
export const sortBiomarkers = (biomarkers, biomarkerCategories) => {
  let biomarkersCategorized = [];
  if (biomarkers?.length) {
    //creating the categorized biomarkerObj for storing biomarkers acc. to categories
    biomarkerCategories.forEach((category, index) => {
      biomarkersCategorized[category.name?.toLowerCase()] = {
        name: category.name?.toLowerCase(),
        editable: category.name === CUSTOM_CATEGORY_NAME,
        index,
        id: category?.id,
        biomarkers: [],
      };
    });
    //categorizing biomarkers
    biomarkers.forEach((biomarker) => {
      biomarkersCategorized = {
        ...biomarkersCategorized,
        [biomarker?.category?.name]: {
          ...biomarkersCategorized[biomarker?.category?.name],
          biomarkers: [
            ...(biomarkersCategorized[biomarker?.category?.name]?.biomarkers ??
              []),
            {
              index: biomarkersCategorized[biomarker?.category?.name]
                ?.biomarkers?.length
                ? biomarkersCategorized[biomarker?.category?.name]?.biomarkers
                    ?.length
                : 0,
              name: biomarker?.name,
              description: biomarker?.description,
              editable: biomarker?.category?.name === CUSTOM_CATEGORY_NAME,
              id: biomarker?.id,
            },
          ],
        },
      };
    });
    //creating the same data format
    biomarkersCategorized = Object.keys(biomarkersCategorized).map(
      (category) => biomarkersCategorized[category]
    );
  }

  return biomarkersCategorized;
};

export default function GenerateTable(props) {
  const { palette } = useTheme();
  const { id } = useParams();
  // value type as follows,
  // [[false, false], [false]]
  // length of array is dependent on provided array
  // and length of sub array is dependent on provided array's sub array
  const [loading, setLoading] = useState(true);
  const [generateTableDialog, setgenerateTableDialog] = useState(false);
  const [checked, setChecked] = useState([]);

  // redux
  const categories = useSelector((state) => state.biomarkers.categories);
  const values = useSelector((state) => state.biomarkers.values);

  // get selected biomarker keys from the redux store
  const checkedBiomarkers = useSelector(
    (state) => state.biomarkers.checkedBiomarkersWithId
  );

  const dispatch = useDispatch();

  // for loading purpose
  const [generating, setGenerating] = useState(false);
  // if any error while generating table
  const [error, setError] = useState(null);
  //for biomarker table
  const [biomarkers, setBiomarkers] = useState([]);
  const [isGettingCategories] = useState(false);
  const [isFetchingBiomarkers, setIsFetchingBiomarkers] = useState(false);
  const [biomarkerCategories] = useState([]);

  useEffect(() => {
    if (!Boolean(categories.length)) {
      dispatch(setCategories(biomarkers));
    }
    setChecked(getDefaultValuedArray(biomarkers, "biomarkers"));
    setLoading(false);
  }, [dispatch, categories, biomarkers]);

  //for fetching biomarker categories
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setIsGettingCategories(true);
  //       const response = await axios({
  //         url: `${DOMAIN}/biomarkers/categories/`,
  //         method: "GET",
  //         params: {
  //           trial_id: id,
  //           description: "N/A",
  //         },
  //         headers: {
  //           Authorization: getAuthorization(),
  //         },
  //       });
  //       setBiomarkerCategories(response.data.payload);
  //       setIsGettingCategories(false);
  //     } catch (error) {
  //       setIsGettingCategories(false);
  //     }
  //   })();
  // }, [dispatch, id]);

  const updateCheckedBiomarkers = React.useCallback(() => {
    const updatedChecked = checkedBiomarkers.find(
      (biomarker) => biomarker.id === id
    );

    setChecked(updatedChecked.checked);
  }, [checkedBiomarkers, id]);

  const fetchBioMarkers = React.useCallback(async () => {
    try {
      setgenerateTableDialog(true);
      setIsFetchingBiomarkers(true);
      const response = await axios({
        url: `${DOMAIN}/biomarkers/`,
        method: "GET",
        params: {
          trial_id: id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      //sorting biomarkers and categorizing them
      dispatch(setBiomarkerKeys(response.data.payload));
      const sortedBiomarkers = sortBiomarkers(
        response?.data?.payload,
        biomarkerCategories
      );
      setBiomarkers(sortedBiomarkers);
      setIsFetchingBiomarkers(false);

      // updating checked biomarkers after fetching biomarkers
      updateCheckedBiomarkers();
    } catch (error) {
      setIsFetchingBiomarkers(false);
    }
  }, [dispatch, biomarkerCategories, id, updateCheckedBiomarkers]);

  const fetchBioMarkersValues = React.useCallback(
    async (biomarkersSelected) => {
      try {
        setIsFetchingBiomarkers(true);
        const response = await axios({
          url: `${DOMAIN}/biomarkers/values/`,
          method: "GET",
          params: {
            trial_id: id,
            biomarkers: JSON.stringify(biomarkersSelected),
          },
          headers: {
            Authorization: getAuthorization(),
          },
        });
        dispatch(setValues(response.data));
        setIsFetchingBiomarkers(false);
      } catch (error) {
        setIsFetchingBiomarkers(false);
      }
    },
    [dispatch, id]
  );

  const handleCheckAll = () => {
    // to mark all biomarkers as checked or unchacked

    // checking if all biomarkers are already checked
    const allTrue = checked
      .map((i) => i.every((i) => i === true))
      .every((i) => i === true);

    // get updated checked array
    const updatedChecked = checked.map((item) => {
      // mapping through checked array

      // mapping through nested checked array
      // value is decided on the basis of `allTrue`
      return item.map(() => (allTrue ? false : true));
    });

    // setting updated value of `checked`
    setChecked(updatedChecked);
  };

  const handleCheckCategory = (category) => {
    // to mark all biomarkers as checked or unchacked on a particular category

    // checking if all biomarkers of the category are already checked
    const allTrue = checked[category.index].every((i) => i === true);

    // get updated checked array
    const updatedChecked = checked.map((item, index) => {
      // mapping through checked array
      if (index === category.index) {
        // when the category id found, values are updated
        // value is decided on the basis of `allTrue` for that particular category
        return checked[category.index].map(() => (allTrue ? false : true));
      }
      return item;
    });

    // setting updated value of `checked` for the category
    setChecked(updatedChecked);
  };

  const handleCheck = (category, biomarker) => {
    // to mark a biomarker as checked or unchacked

    // looping throuh all categories biomarkers
    const updatedChecked = checked.map((item, index) => {
      // when the categorie id found, to which the biomarker belongs
      if (index === category.index) {
        // looping through all biomarkers in that particular category
        return item.map((nestedItem, nestedIndex) => {
          // checking for the biomarker in the category it belongs to
          if (nestedIndex === biomarker.index) {
            // fliping the boolean value of `check`
            // returning the updated biomarker value
            return !nestedItem;
          }
          // returning the unchanged biomarker value
          return nestedItem;
        });
      }
      // returning the updated category
      return item;
    });

    // setting the updated `checked` array
    setChecked(updatedChecked);
  };

  const generateTable = async () => {
    // generate table, by calling API

    setGenerating(true);
    setError(null);
    let biomarkersSelected = [];
    // get name of the biomarkers that are checked
    for (var i = 0; i < checked.length; i++) {
      // loop through checked categories
      for (var j = 0; j < checked[i].length; j++) {
        // loop through biomarkers in categories
        if (checked[i][j]) {
          // if biomarker is checked, append it to the list
          biomarkersSelected = [
            ...biomarkersSelected,
            categories[i]["biomarkers"][j]["id"],
          ];
        }
      }
    }

    if (!biomarkers.length) {
      setError("Please select atleast 1 biomarker");
      setGenerating(false);
      return;
    }
    dispatch(setSelectedBiomarkerKeys(biomarkersSelected));
    dispatch(
      setCheckedBiomarkersWithId({
        id,
        checked,
      })
    );
    await fetchBioMarkersValues(biomarkersSelected);

    props.setTableGenerated(true);
    setgenerateTableDialog(false);
    setGenerating(false);
  };

  return (
    !loading && (
      <>
        <Button
          aria-label="select merge strategy"
          aria-haspopup="menu"
          variant="filled"
          sx={{
            backgroundColor: palette.primary[500],
            color: palette.neutral[100],
            padding: "12px",
            borderRadius: "6px",
            gap: "6px",
          }}
          onClick={fetchBioMarkers}
          endIcon={<img src={download} alt="icon" />}
          disabled={isGettingCategories}
        >
          {values.length ? "Update" : "Export"} Table
        </Button>
        <CustomDialog
          open={generateTableDialog}
          close={() => setgenerateTableDialog(false)}
          title="Select Biomarkers"
          setOpen={setgenerateTableDialog}
          sx={{ paddingBottom: 0 }}
          content={
            <>
              {values.length ? (
                <Stack direction="row" spacing={2}>
                  <AddBiomarker
                    id={id}
                    startIcon={<AddIcon />}
                    categories={categories}
                    setCategories={setCategories}
                    style={{
                      backgroundColor: palette.primary[200],
                      color: palette.primary[500],
                    }}
                    variant="conatained"
                  />

                  <DeleteBiomarker
                    id={id}
                    categories={categories}
                    biomarkerCategories={biomarkerCategories}
                  />
                </Stack>
              ) : null}
              <Stack>
                {!isFetchingBiomarkers && (
                  <FormControlLabel
                    label={`All Biomarkers (${checked
                      .map((item) => item.length)
                      .reduce((i, j) => i + j, 0)})`}
                    control={
                      <Checkbox
                        // checking if every biomarker in each category is checked
                        checked={checked
                          .map((i) => i.every((i) => i === true))
                          .every((i) => i === true)}
                        onChange={handleCheckAll}
                      />
                    }
                  />
                )}

                <Stack>
                  {(!isFetchingBiomarkers &&
                    checked.length &&
                    biomarkers.map(
                      (category, index) => {
                        // !checked[category.index].length ? null : (
                        // looping through category array
                        return (
                          <div key={index}>
                            <FormControlLabel
                              label={`${category.name}(${category.biomarkers.length})`}
                              control={
                                <Checkbox
                                  // checking if every biomarker in this category is checked
                                  checked={
                                    Boolean(checked[category.index].length) &&
                                    checked[category.index].every(
                                      (i) => i === true
                                    )
                                  }
                                  onChange={() => handleCheckCategory(category)}
                                />
                              }
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                              }}
                            >
                              {category.biomarkers.map(
                                (biomarker, subIndex) => (
                                  // looping through biomarker array in this category
                                  <FormControlLabel
                                    key={subIndex}
                                    label={`${biomarker.name} (${biomarker.description})`}
                                    control={
                                      <Checkbox
                                        // checking if this biomarker in checked
                                        checked={
                                          checked[category.index][
                                            biomarker.index
                                          ]
                                        }
                                        onChange={() =>
                                          handleCheck(category, biomarker)
                                        }
                                      />
                                    }
                                  />
                                )
                              )}
                            </Box>
                          </div>
                        );
                      }
                      // )
                    )) || (
                    <Stack
                      sx={{
                        width: "100%",
                        height: "100%",
                        minHeight: "300px",
                      }}
                    >
                      <CircularProgress sx={{ margin: "auto" }} />
                    </Stack>
                  )}
                </Stack>
              </Stack>
              {error ? (
                <>
                  <br />
                  <Typography
                    variant="body2"
                    sx={{ color: palette.error.main }}
                  >
                    {error}
                  </Typography>
                  <br />
                </>
              ) : null}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "start",
                  width: "100%",
                  position: "sticky",
                  bottom: "0",
                  backgroundColor: palette.neutral[100],
                  padding: "10px 10px",
                }}
              >
                <Button
                  disableElevation
                  disabled={generating}
                  variant="contained"
                  onClick={generateTable}
                  bg="#10A44B"
                >
                  {generating ? "Generating" : "Generate"} Table
                </Button>
              </Box>
            </>
          }
        />
      </>
    )
  );
}
