import {
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import Loading from "../../../components/Blockers/Loading";
import { EditOutlined, VisibilityOutlined } from "@mui/icons-material";
import EditTrial from "./EditTrial";

export default function TrialInfo() {
  const { palette } = useTheme();
  const [loading, setLoading] = useState(true);
  const [trial, setTrial] = useState([]);
  const [sharedWith, setSharedWith] = useState([]);

  const { id } = useParams();

  const fetchTrial = useCallback(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${DOMAIN}/trials/`,
      params: {
        id,
        deep: false,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        const data = res.data.payload[0];
        setTrial(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    fetchTrial();
  }, [fetchTrial]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${DOMAIN}/trials/share/`,
      params: {
        trial_id: id,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        setSharedWith(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ padding: "12px" }}>
        <Typography variant="c20px600" sx={{ color: palette.neutral[900] }}>
          Trial Information
        </Typography>

        <Loading loading={loading}>
          <>
            <Paper
              sx={{
                height: "100%",
                padding: "12px",
                backgroundColor: palette.neutral[100],
                border: "1px solid #E8E8E8",
                borderRadius: "6px",
              }}
              elevation={0}
            >
              <Stack direction="column" spacing={0.5}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="c14px400"
                    sx={{ color: palette.neutral[700] }}
                  >
                    Trial Name
                  </Typography>
                  <EditTrial
                    trialName={trial.name}
                    trialDescription={trial.description}
                    updateValue="name"
                    fetchUpdatedTrial={fetchTrial}
                  />
                </Stack>
                <Divider
                  sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
                />{" "}
                <Typography
                  // variant="c16px500"
                  sx={{
                    color: palette.neutral[700],
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {trial.name}
                </Typography>
              </Stack>
            </Paper>
            <Paper
              sx={{
                height: "100%",
                padding: "12px",
                backgroundColor: palette.neutral[100],
                border: "1px solid #E8E8E8",
                borderRadius: "6px",
              }}
              elevation={0}
            >
              <Stack direction="column" spacing={0.5}>
                <Typography
                  variant="c14px400"
                  sx={{
                    color: palette.neutral[700],
                  }}
                >
                  Trial Created On{" "}
                </Typography>
                <Divider
                  sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
                />{" "}
                <Typography
                  variant="c16px500"
                  sx={{ color: palette.neutral[700] }}
                >
                  {moment(trial.created_on).format("D MMMM, YYYY")}
                </Typography>
              </Stack>
            </Paper>

            {trial.description && (
              <Paper
                sx={{
                  height: "100%",
                  padding: "12px",
                  backgroundColor: palette.neutral[100],
                  border: "1px solid #E8E8E8",
                  borderRadius: "6px",
                }}
                elevation={0}
              >
                <Stack direction="column" spacing={0.5}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="c14px400"
                      sx={{
                        color: palette.neutral[700],
                      }}
                    >
                      Trial Description{" "}
                    </Typography>
                    <EditTrial
                      trialName={trial.name}
                      trialDescription={trial.description}
                      updateValue="description"
                      fetchUpdatedTrial={fetchTrial}
                    />
                  </Stack>
                  <Divider
                    sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
                  />{" "}
                  <Typography
                    variant="body1"
                    style={{ color: palette.neutral[700] }}
                  >
                    {trial.description}
                  </Typography>
                </Stack>
              </Paper>
            )}

            <Paper
              sx={{
                height: "100%",
                padding: "12px",
                backgroundColor: palette.neutral[100],
                border: "1px solid #E8E8E8",
                borderRadius: "6px",
              }}
              elevation={0}
            >
              <Stack direction="column" spacing={0.5}>
                <Typography
                  variant="c14px400"
                  sx={{
                    color: palette.neutral[700],
                  }}
                >
                  Created By
                </Typography>
                <Divider
                  sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
                />{" "}
                <Stack direction="column">
                  <Typography
                    variant="c16px500"
                    sx={{
                      color: palette.neutral[700],
                    }}
                  >
                    {trial?.partner_details?.first_name}{" "}
                    {trial?.partner_details?.last_name}
                  </Typography>
                  <Typography
                    variant="c14px500"
                    sx={{
                      color: palette.primary[700],
                    }}
                  >
                    {trial?.partner_details?.email}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>

            {sharedWith.length ? (
              <Paper
                sx={{
                  height: "100%",
                  padding: "12px",
                  backgroundColor: palette.neutral[100],
                  border: "1px solid #E8E8E8",
                  borderRadius: "6px",
                }}
                elevation={0}
              >
                <Stack direction="column">
                  <Typography
                    variant="c14px400"
                    sx={{
                      color: palette.neutral[700],
                    }}
                  >
                    Collaborator(s)
                  </Typography>
                  <Divider
                    sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
                  />{" "}
                  {sharedWith.map((item, index) => (
                    <Stack
                      direction="row"
                      key={index}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="column" sx={{ paddingTop: "12px" }}>
                        <Typography
                          variant="c16px500"
                          sx={{
                            color: palette.neutral[700],
                          }}
                        >
                          {item.user.first_name} {item.user.last_name}
                        </Typography>
                        <Typography
                          variant="c14px500"
                          sx={{
                            color: palette.primary[700],
                          }}
                        >
                          {item.user.email}
                        </Typography>
                      </Stack>
                      <IconButton
                        size="large"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        sx={{
                          padding: 0,
                        }}
                      >
                        {item.permissions === "read" ? (
                          <VisibilityOutlined
                            sx={{ height: "16px", width: "16px" }}
                          />
                        ) : (
                          <EditOutlined
                            sx={{ height: "16px", width: "16px" }}
                          />
                        )}
                      </IconButton>
                    </Stack>
                  ))}
                </Stack>
              </Paper>
            ) : null}
          </>
        </Loading>
      </Stack>
    </>
  );
}
