import { useState, useRef } from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

import moment from "moment";

export default function DropDownButton(props) {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup disableElevation aria-label="split button">
        <Button
          sx={{
            color: palette.neutral[700],
            fontWeight: 500,
            fontSize: "16px",
          }}
          ref={anchorRef}
          size="large"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          startIcon={props.startIcon}
          variant="text"
        >
          {props.title}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper style={{ height: "auto", width: "auto" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem
                    disabled
                    divider={true}
                    style={{
                      width: "100%",
                      border: "1px solid #F7F7F7",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <Typography variant="c12px500">Subjects</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="c12px500">Date</Typography>
                      </Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>
                  </MenuItem>
                  {props.options.map((option, index) => (
                    <MenuItem
                      disableTouchRipple
                      divider={true}
                      key={index}
                      autoFocus={false}
                      style={{
                        width: "100%",
                        paddingRight: 0,
                        border: "1px solid #F7F7F7",
                      }}
                    >
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={3}>
                          <Typography
                            variant="c12px500"
                            style={{
                              marginTop: "7px",
                            }}
                          >
                            {option.start_uid}-{option.end_uid}.pdf
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="c12px500"
                            style={{
                              marginTop: "7px",
                            }}
                          >
                            {moment(option.created_at).format("MMM D")}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            sx={{ padding: "0px" }}
                            onClick={() => props.onDownload(option)}
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            Download
                          </Button>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
