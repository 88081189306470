import { ArrowBack } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Biomarkers from "./Biomarkers";
import OverviewStats from "./OverviewStats";
import TrialInfo from "./TrialInfo";

export default function TrialOverview() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { palette } = useTheme();

  return (
    <Stack direction="row" height="100vh">
      <Stack width="100%" position="sticky" top="0" zIndex="1">
        <Stack
          direction="row"
          alignItems="center"
          px={4}
          py={3}
          spacing={1.5}
          sx={{
            backgroundColor: palette.neutral[100],
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.07)",
          }}
        >
          <ArrowBack
            sx={{ color: palette.neutral[900], cursor: "pointer" }}
            // onClick={() => navigate(`/trial/${id}`)}
            onClick={() => navigate(-1)}
          />
          <Typography variant="c20px600" color={palette.neutral[900]}>
            Trial Overview
          </Typography>
        </Stack>
        <Stack
          style={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 72px)",
          }}
        >
          <Stack margin={4} spacing={3}>
            <OverviewStats trialId={id} />
            <Biomarkers trialId={id} />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        width="392px"
        sx={{
          backgroundColor: palette.neutral[100],
          borderLeft: `1px solid ${palette.neutral[300]}`,
        }}
      >
        <TrialInfo />
      </Stack>
    </Stack>
  );
}
