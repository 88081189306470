import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CustomDialog from "../../../components/CustomDialog";
import { CustomTextField } from "../../../components/CustomTextField";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import { ReactComponent as ShareIcon } from "../../../assets/trial/share.svg";
import { StyledTooltip } from "../../../components/CustomTooltip";
import { isValidEmail } from "../../../utils/emailValid";

export default function ShareTrial(props) {
  const [shareTrialDialog, setshareTrialDialog] = useState(false);

  // new
  const [email, setEmail] = useState("");
  const [permission, setPermission] = useState("read");
  const [sharing, setSharing] = useState(false);
  const [error, setError] = useState("");
  const [errorEmail, setErrorEmail] = useState(null);

  const { palette, spacing } = useTheme();

  // existing
  const [newPermission, setNewPermission] = useState({
    id: 0,
    permission: null,
    loading: false,
  });

  const [sharedWith, setSharedWith] = useState([]);

  const fetchSharedWith = useCallback(async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/trials/share/`,
        params: {
          trial_id: props.trialId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      if (res.status === 200) {
        setSharedWith(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [props.trialId]);

  useEffect(() => {
    fetchSharedWith();
  }, [fetchSharedWith]);

  const handleShareTrial = async (e) => {
    e.preventDefault();
    setError("");
    setErrorEmail(null);
    setSharing(true);

    if (!email) {
      setErrorEmail("Email is required");
      setSharing(false);
      return;
    }

    if (!isValidEmail(email)) {
      setErrorEmail("Email is not valid");
      setSharing(false);
      return;
    }

    try {
      const res = await axios({
        method: "POST",
        url: `${DOMAIN}/trials/share/`,
        params: {
          trial_id: props.trialId,
        },
        data: {
          email,
          permission,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      if (res) {
        setSharing(false);
        setSharedWith([...sharedWith, res.data]);
        setEmail("");
        setPermission("read");
      }
    } catch (err) {
      try {
        const errorJson = JSON.parse(err.request.response);
        setError(errorJson.details);
      } catch {
        setError("Unable to share");
      }
      setSharing(false);
    }
  };

  const handlePermissionChange = async (e, id, email) => {
    setNewPermission({
      ...newPermission,
      id: id,
      permission: e.target.value,
      loading: true,
    });

    if (e.target.value === "remove") {
      try {
        const res = await axios({
          method: "DELETE",
          url: `${DOMAIN}/trials/share/`,
          params: {
            trial_id: props.trialId,
            id: id,
          },
          headers: {
            Authorization: getAuthorization(),
          },
        });

        if (res) {
          let newShareWith = sharedWith.filter((item) => item.id !== id);
          setSharedWith(newShareWith);
          setNewPermission({
            id: 0,
            permission: null,
            loading: false,
          });
        }
      } catch (err) {
        try {
          const errorJson = JSON.parse(err.request.response);
          console.log(errorJson);
        } catch {
          console.log("Unable to remove");
        }
        setNewPermission({
          id: 0,
          permission: null,
          loading: false,
        });
      }
      return;
    }

    axios({
      method: "PATCH",
      url: `${DOMAIN}/trials/share/`,
      params: {
        trial_id: props.trialId,
        id: id,
      },
      body: {
        permission: e.target.value,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        let newShareWith = sharedWith.map((item, index) => {
          if (item.id === id) {
            return {
              ...item,
              permissions: e.target.value,
            };
          }
          return item;
        });
        setSharedWith(newShareWith);
        setNewPermission({
          id: 0,
          permission: null,
          loading: false,
        });
      })
      .catch((err) => {
        try {
          const errorJson = JSON.parse(err.request.response);
          console.log(errorJson);
        } catch {
          console.log("Unable to edit permission");
        }
        setNewPermission({
          id: 0,
          permission: null,
          loading: false,
        });
      });
  };

  return (
    <>
      <StyledTooltip title="Share Trial">
        <IconButton
          data-cy="icon__shareTrial"
          sx={{
            backgroundColor: palette.neutral[200],
            padding: spacing(1),
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: palette.neutral[300],
            },
            "&:clicked": {
              border: `1px solid ${palette.neutral[400]}`,
            },
          }}
          onClick={() => setshareTrialDialog(true)}
        >
          <ShareIcon />
        </IconButton>
      </StyledTooltip>

      <CustomDialog
        open={shareTrialDialog}
        setOpen={setshareTrialDialog}
        content={
          <Stack>
            <FormControl fullWidth>
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={2}>
                  <CustomTextField
                    data-cy="input__email"
                    error={errorEmail ? true : false}
                    helperText={errorEmail}
                    required
                    fullWidth
                    variant="filled"
                    placeholder="Enter email ID"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                  <FormControl
                    size="small"
                    sx={{
                      width: "100%",
                      maxWidth: "150px",
                    }}
                  >
                    <Select
                      data-cy="select__permission"
                      id="select"
                      value={permission}
                      onChange={(e) => setPermission(e.target.value)}
                    >
                      <MenuItem data-cy="option__view" value={"read"}>
                        can view
                      </MenuItem>
                      <MenuItem data-cy="option__edit" value={"write"}>
                        can edit
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Typography
                  variant="caption"
                  sx={{ color: palette.error.main }}
                >
                  {error}
                </Typography>
                {sharedWith.map((item, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          newPermission.id === item.id ? "gray" : "inherit",
                      }}
                    >
                      {item.user_details.email}
                    </Typography>
                    <FormControl
                      size="small"
                      sx={{
                        width: "100%",
                        maxWidth: "150px",
                      }}
                    >
                      <Select
                        disabled={newPermission.id === item.id}
                        id="select"
                        value={
                          newPermission.id === item.id
                            ? newPermission.permission
                            : item.permissions
                        }
                        onChange={(e) =>
                          handlePermissionChange(
                            e,
                            item.id,
                            item.user_details.email
                          )
                        }
                      >
                        <MenuItem value={"read"}>can view</MenuItem>
                        <MenuItem value={"write"}>can edit</MenuItem>
                        <MenuItem
                          value={"remove"}
                          sx={{ color: palette.error.main }}
                        >
                          remove
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                ))}
              </Stack>
              <br />
              <Button
                data-cy="button__shareTrial"
                disableElevation
                disabled={sharing}
                type="submit"
                fullWidth
                variant="outlined"
                color="primary"
                startIcon={<ShareIcon />}
                onClick={handleShareTrial}
              >
                Share Trial
              </Button>
            </FormControl>
          </Stack>
        }
      />
    </>
  );
}
