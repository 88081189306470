import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material ui modules
import Box from "@mui/material/Box";

// components
import Sidebar from "../../components/Sidebar";
import Notify from "../../components/Notify";
import { setSaver } from "../../redux/utils/utilsSlice";
import { useMediaQuery } from "@mui/material";

// Layout Component: Defines the structure of the application
function Layout({ children }) {
  const isSmallScreen = useMediaQuery("(max-width: 1440px)");
  const DRAWER_WIDTH = isSmallScreen ? 104 : 232;
  const [open, setOpen] = useState(true);

  const auth = useSelector((state) => state.auth);
  const saver = useSelector((state) => state.utils.saver);

  const dispatch = useDispatch();

  useEffect(() => {
    if (saver.autoHideDuration) {
      setTimeout(() => {
        dispatch(
          setSaver({
            open: false,
          })
        );
      }, saver.autoHideDuration);
    }
  }, [saver, dispatch]);

  return (
    <Box sx={{ display: "flex" }} className="Layout-Component">
      {auth.isAuthenticated ? (
        <Sidebar drawerWidth={DRAWER_WIDTH} open={open} setOpen={setOpen} />
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { lg: `calc(100% - ${DRAWER_WIDTH}px)` },
        }}
        className="Layout-Main-Area"
      >
        <Box
          className="Layout-Main-Area-Body"
          sx={{
            height: "calc(100% - 90px)",
          }}
        >
          {children}
        </Box>
      </Box>
      <Notify />
    </Box>
  );
}

export default Layout;
