import { Stack, Button, Typography, useTheme } from "@mui/material";
import Img from "../../assets/SearchingLaptop.png";

function NoData({
  message,
  heading,
  actionText,
  action,
  height,
  hideIllustration,
}) {
  const { palette } = useTheme();
  return (
    <Stack
      minHeight={height ? height : "90vh"}
      width="100%"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      {!hideIllustration ? <img src={Img} alt="illustrations" /> : null}
      {heading ? (
        <Typography variant="c18px600" color={palette.neutral[700]}>
          {heading}
        </Typography>
      ) : null}
      <Typography
        variant="c14px500"
        style={{
          paddingTop: !hideIllustration ? "30px" : "0px",
          color: palette.neutral[500],
        }}
      >
        {message || "No data found"}
      </Typography>

      {action ? (
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={action}
        >
          {actionText || "Try Again"}
        </Button>
      ) : null}
    </Stack>
  );
}

export default NoData;
