import {
  Grid,
  Paper,
  Stack,
  Typography,
  Accordion as MuiAccordion,
  styled,
  useTheme,
  AccordionSummary,
} from "@mui/material";
import SendNotification from "./SendNotification";
import SubjectTaskList from "./SubjectTaskList";
import { ExpandMore } from "@mui/icons-material";
import { calculatePercentage } from "../../../utils/helpers";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export default function SubjectCard(props) {
  const { palette } = useTheme();

  let totalTasks = 0;
  let completedTasks = 0;
  let pendingTasks = 0;
  let missedTasks = 0;
  if (props.all_tasks && props.all_tasks.length) {
    totalTasks = props.all_tasks.length;
  }
  if (props.all_tasks && props.all_tasks.length) {
    completedTasks = props.all_tasks.filter(
      (task) => task.status === "completed"
    ).length;
  }
  if (props.all_tasks && props.all_tasks.length) {
    pendingTasks = props.all_tasks.filter(
      (task) => task.status === "pending"
    ).length;
  }
  if (props.all_tasks && props.all_tasks.length) {
    missedTasks = props.all_tasks.filter(
      (task) => task.status === "missed"
    ).length;
  }

  const subjectTaskList = () => {
    if (
      props.trialStatus === "live" &&
      props.all_tasks &&
      props.all_tasks.length
    )
      return (
        <SubjectTaskList tasks={props.all_tasks} secretKey={props.secret_id} />
      );
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper elevation={0}>
          <Accordion
            elevation={0}
            style={{
              backgroundColor: palette.neutral[100],
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              style={{
                padding: "8px 16px",
                borderBottom: "1px solid #F7F7F7",
              }}
            >
              <Stack direction="row" alignItems="start" spacing={2}>
                <Stack width="100px">
                  <Typography
                    variant="c14px500"
                    color={palette.neutral[500]}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Subject Id
                  </Typography>
                  <Typography
                    variant="c16px500"
                    color={palette.neutral[900]}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {props.id}
                  </Typography>
                </Stack>
                <Stack width="100px">
                  <Typography
                    variant="c14px500"
                    color={palette.neutral[500]}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Status
                  </Typography>
                  <Typography
                    variant="c16px500"
                    color={
                      props.status.toLowerCase() === "subject.registered"
                        ? palette.primary[600]
                        : palette.neutral[900]
                    }
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {props.status === "subject.created"
                      ? "Not Joined"
                      : "Onboarded"}
                  </Typography>
                </Stack>
                <Stack width="100px">
                  <Typography
                    variant="c14px500"
                    color={palette.neutral[500]}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Total Tasks
                  </Typography>
                  <Typography
                    variant="c16px500"
                    color={palette.neutral[900]}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {totalTasks}
                  </Typography>
                </Stack>
                <Stack width="100px">
                  <Typography
                    variant="c14px500"
                    color={palette.neutral[500]}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Completed
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="c16px500"
                      color={palette.neutral[900]}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {completedTasks}
                    </Typography>
                    <Typography
                      variant="c16px500"
                      color="#007D33"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {`(${calculatePercentage(completedTasks, totalTasks)})`}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack width="100px">
                  <Typography
                    variant="c14px500"
                    color={palette.neutral[500]}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Missed
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="c16px500"
                      color={palette.neutral[900]}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {`(${calculatePercentage(missedTasks, totalTasks)})`}
                    </Typography>
                    {props.missedPercentage && (
                      <Typography
                        variant="c16px500"
                        color="#B53836"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {`(${props.missedPercentage || "N/A"})`}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <Stack width="100px">
                  <Typography
                    variant="c14px500"
                    color={palette.neutral[500]}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Pending
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="c16px500"
                      color={palette.neutral[900]}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {pendingTasks}
                    </Typography>
                    <Typography
                      variant="c16px500"
                      color="#0C6FE2"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {`(${calculatePercentage(pendingTasks, totalTasks)})`}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              {/* Send Notification */}
              <SendNotification
                subjectId={props.id}
                enabled={props.sessions && props.sessions.length}
              />
            </AccordionSummary>
            {subjectTaskList()}
          </Accordion>
        </Paper>
      </Grid>
    </>
  );
}
