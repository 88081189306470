import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useDispatch } from "react-redux";
import arrowback from "../../../assets/common/arrowLeft.svg";
import trash from "../../../assets/common/trash.svg";
import Loading from "../../../components/Blockers/Loading";
import { CustomDialogTitle } from "../../../components/CustomDialog";
import { setNotify } from "../../../redux/utils/utilsSlice";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import asterisk from "../../../assets/common/asterisk.svg";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import AddDevice from "./AddDevice";
import TrialDevicesList from "./TrialDevicesList";

export default function Onboarding() {
  const [deleteTrialDialog, setDeleteTrialDialog] = useState(false);
  const [submitTrialDialog, setSubmitTrialDialog] = useState(false);
  const [trial, setTrial] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [error, setError] = useState(null);

  const { palette } = useTheme();
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setError(null);
    // set loading to true
    setLoading(true);
    axios({
      method: "GET",
      url: `${DOMAIN}/trials/`,
      params: {
        id,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        console.log(res.data);
        const data = res.data[0];
        setTrial(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  }, [id]);

  const handleUpdateTrial = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setSaving(true);

    try {
      // make API request
      const res = await axios({
        method: "PATCH",
        url: `${DOMAIN}/trials/`,
        params: {
          id,
        },
        data: {
          name: trial.name,
          description: trial.description,
          org_name: trial.org_name,
          max_sub: trial.max_sub,
          end_date: moment(trial.end_date).format("YYYY-MM-DD"),
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      console.log(res.data);
      const data = res.data;
      setTrial(data);
      setSaving(false);

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Successfully updated trial",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    } catch (err) {
      // set loading to false
      setSaving(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.message);
        // set error
        setError(error_object.message);
      } catch {
        // default error message
        setError("Error while updating trial");
      }
    }
  };

  const handleDelete = async () => {
    // remove errors from state, if any
    setError(null);
    try {
      // make API request
      await axios({
        method: "DELETE",
        url: `${DOMAIN}/trials/`,

        data: {
          id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Trial Deleted",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      navigate(`/`);
    } catch (err) {
      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Something Went Wrong!",
          severity: "error",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    }
  };

  const handleSubmit = async () => {
    // remove errors from state, if any
    setError(null);
    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/trials/request-approval/`,

        data: {
          id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Submitted for Approval",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      navigate(`/`);
    } catch (err) {
      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Something Went Wrong!",
          severity: "error",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    }
  };

  return (
    <Loading loading={loading}>
      <Grid
        container
        style={{
          height: "100%",
          // minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* START: Top Nav */}
            <Stack
              direction="column"
              // component={Paper}
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: palette.neutral[100],
                zIndex: 101,
              }}
            >
              {/* START: First Row */}
              <Stack
                px="32px"
                py="24px"
                paddingTop="24px"
                paddingBottom="24px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => navigate(-1)}
                  >
                    <IconButton>
                      {/* <ArrowBackIcon sx={{ color: palette.neutral.dark }} /> */}
                      <img src={arrowback} alt="" />
                    </IconButton>
                    <Typography
                      variant="c20px600"
                      style={{
                        textAlign: "left",
                        color: palette.neutral[900],
                      }}
                    >
                      {trial.name}
                    </Typography>
                  </Stack>
                  {/* START: Edit Schedule Button */}

                  {/* END: Edit Schedule Button */}
                </Stack>
              </Stack>
              {/* END: First Row */}
            </Stack>
            {/* END: Top Nav */}
            {/* START:Body*/}
            <Stack
              minHeight="90vh"
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              sx={{ paddingTop: "32px" }}
            >
              {/* START: Body */}

              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "688px",
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  sx={{
                    width: "100%",
                  }}
                  spacing={3}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      height: "100%",
                      padding: "32px 24px",
                      width: "100%",
                      border: "1px solid #E8E8E8",
                      borderRadius: "6px",
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={3}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                          width: "100%",
                          alignItems: "center",
                          alignSelf: "stretch",
                        }}
                      >
                        <Typography variant="c20px500">Trial Info</Typography>
                        <Button
                          disabled={saving}
                          type="submit"
                          onClick={handleUpdateTrial}
                          sx={{
                            padding: "12px",
                            backgroundColor: palette.primary[500],
                            color: palette.neutral[100],
                            borderRadius: "6px",
                          }}
                        >
                          {saving ? "Saving" : "Save"}
                        </Button>
                      </Stack>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={3}
                        sx={{ width: "100%" }}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            style={{
                              marginTop: "-25px",
                              width: "100%",
                            }}
                          >
                            <Stack direction="row" alignItems="center">
                              <Typography
                                variant="c14px400"
                                style={{
                                  textAlign: "left",
                                  color: palette.neutral[500],
                                }}
                              >
                                Trial Name
                              </Typography>
                              <img
                                src={asterisk}
                                alt="icon"
                                style={{ marginBottom: "10px" }}
                              />
                            </Stack>
                          </InputLabel>
                          <TextField
                            fullWidth
                            required
                            error={error ? true : false}
                            helperText={error}
                            variant="outlined"
                            placeholder="Enter Trial Name"
                            type="text"
                            InputProps={{ disableUnderline: true }}
                            onChange={(e) =>
                              setTrial({
                                ...trial,
                                name: e.target.value,
                              })
                            }
                            value={trial.name}
                            sx={{
                              marginTop: "17px",
                              "& .MuiInputBase-input": {
                                padding: "12px",
                                borderRadius: "6px",
                              },
                            }}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            style={{
                              marginTop: "-25px",
                              width: "100%",
                            }}
                          >
                            <Stack direction="row" alignItems="center">
                              <Typography
                                variant="c14px400"
                                style={{
                                  textAlign: "left",
                                  color: palette.neutral[500],
                                }}
                              >
                                Trial Description
                              </Typography>
                              <img
                                src={asterisk}
                                style={{ marginBottom: "10px" }}
                                alt="icon"
                              />
                            </Stack>
                          </InputLabel>
                          <TextField
                            fullWidth
                            required
                            multiline
                            error={error ? true : false}
                            helperText={error}
                            variant="outlined"
                            placeholder="Enter Trial Description"
                            type="text"
                            InputProps={{
                              disableUnderline: true,
                              padding: "2px",
                            }}
                            onChange={(e) =>
                              setTrial({
                                ...trial,
                                description: e.target.value,
                              })
                            }
                            value={trial.description}
                            sx={{
                              marginTop: "17px",
                            }}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            style={{
                              marginTop: "-25px",
                              width: "100%",
                            }}
                          >
                            <Stack direction="row" alignItems="center">
                              <Typography
                                variant="c14px400"
                                style={{
                                  textAlign: "left",
                                  color: palette.neutral[500],
                                }}
                              >
                                Organisation Name
                              </Typography>
                              <img
                                src={asterisk}
                                style={{ marginBottom: "10px" }}
                                alt="icon"
                              />
                            </Stack>
                          </InputLabel>
                          <TextField
                            fullWidth
                            required
                            multiline
                            error={error ? true : false}
                            helperText={error}
                            variant="outlined"
                            placeholder="Enter Organisation Name"
                            type="text"
                            InputProps={{
                              disableUnderline: true,
                              padding: "2px",
                            }}
                            onChange={(e) =>
                              setTrial({
                                ...trial,
                                org_name: e.target.value,
                              })
                            }
                            value={trial.org_name}
                            sx={{
                              marginTop: "17px",
                            }}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            style={{
                              marginTop: "-25px",
                              width: "100%",
                            }}
                          >
                            <Stack direction="row" alignItems="center">
                              <Typography
                                variant="c14px400"
                                style={{
                                  textAlign: "left",
                                  color: palette.neutral[500],
                                }}
                              >
                                Total no. of Subjects
                              </Typography>
                              <img
                                src={asterisk}
                                style={{ marginBottom: "10px" }}
                                alt="icon"
                              />
                            </Stack>
                          </InputLabel>
                          <TextField
                            fullWidth
                            required
                            error={error ? true : false}
                            helperText={error}
                            variant="outlined"
                            placeholder="Enter Total no. of Subjects"
                            type="text"
                            InputProps={{ disableUnderline: true }}
                            onChange={(e) =>
                              setTrial({
                                ...trial,
                                max_sub: e.target.value,
                              })
                            }
                            value={trial.max_sub}
                            sx={{
                              marginTop: "17px",
                              "& .MuiInputBase-input": {
                                padding: "12px",
                                borderRadius: "6px",
                              },
                            }}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            style={{
                              marginTop: "-25px",
                              width: "100%",
                            }}
                          >
                            <Stack direction="row" alignItems="center">
                              <Typography
                                variant="c14px400"
                                style={{
                                  textAlign: "left",
                                  color: palette.neutral[500],
                                }}
                              >
                                Expected Trial End Date
                              </Typography>
                              <img
                                src={asterisk}
                                style={{ marginBottom: "10px" }}
                                alt="icon"
                              />
                            </Stack>
                          </InputLabel>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                              inputFormat="MM/DD/YYYY"
                              value={trial.end_date}
                              onChange={(e) =>
                                setTrial({
                                  ...trial,
                                  end_date: e,
                                })
                              }
                              InputProps={{ disableUnderline: true }}
                              renderInput={(params) => (
                                <TextField
                                  data-cy="datepicker__startDate"
                                  fullWidth
                                  required
                                  variant="outlined"
                                  placeholder="DD/MM/YYYY"
                                  type="text"
                                  InputProps={{ disableUnderline: true }}
                                  {...params}
                                  sx={{
                                    marginTop: "17px",
                                    "& .MuiInputBase-input": {
                                      padding: "12px",
                                      borderRadius: "6px",
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          {/* <TextField
                    fullWidth
                    required
                    variant="outlined"
                    placeholder="DD/MM/YYYY"
                    error={error ? true : false}
                    helperText={error}
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => setEndDate(e.target.value)}
                    sx={{
                      marginTop: "17px",
                      "& .MuiInputBase-input": {
                        padding: "12px",
                        borderRadius: "6px",
                      },
                    }}
                  /> */}
                        </FormControl>
                      </Stack>
                    </Stack>
                  </Paper>
                  <TrialDevicesList />
                  {/* <Paper
                    elevation={0}
                    sx={{
                      height: "100%",
                      padding: "32px 24px",
                      width: "100%",
                      borderRadius: "6px",
                      border: "1px solid #E8E8E8",
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={3}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                          width: "100%",
                          alignItems: "center",
                          alignSelf: "stretch",
                        }}
                      >
                        <Typography
                          variant="c16px500"
                          sx={{ color: palette.neutral[700] }}
                        >
                          Device 1
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          <EditDevice />

                          <DeleteDevice />
                        </Stack>
                      </Stack>
                      <Divider style={{ width: "100%", marginTop: "12px" }} />
                      <Stack direction="row" spacing={25}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          spacing={2}
                          sx={{
                            width: "100%",
                            alignItems: "center",
                            alignSelf: "stretch",
                          }}
                        >
                          <Typography
                            variant="c12px500"
                            sx={{ color: palette.neutral[700] }}
                          >
                            Device
                          </Typography>
                          <Typography
                            variant="c16px500"
                            sx={{ color: palette.neutral[700] }}
                          >
                            Nonin
                          </Typography>{" "}
                        </Stack>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          spacing={2}
                          sx={{
                            width: "100%",
                          }}
                        >
                          {" "}
                          <Typography
                            variant="c12px500"
                            sx={{ color: palette.neutral[700] }}
                          >
                            Services
                          </Typography>
                          <Typography
                            variant="c16px500"
                            sx={{ color: palette.neutral[700] }}
                          >
                            Services #2
                          </Typography>{" "}
                          <Typography
                            variant="c16px500"
                            sx={{ color: palette.neutral[700] }}
                          >
                            Services #3
                          </Typography>
                        </Stack>
                      </Stack>
                      <Divider style={{ width: "100%", marginTop: "12px" }} />
                      <Stack direction="column" spacing={1}>
                        <Typography
                          variant="c12px500"
                          color={palette.neutral[700]}
                        >
                          Submission(s) per subject
                        </Typography>
                        <Typography
                          variant="c16px500"
                          color={palette.neutral[700]}
                        >
                          48
                        </Typography>
                      </Stack>
                    </Stack>
                  </Paper> */}
                  <Stack
                    sx={{
                      width: "100%",
                    }}
                  >
                    <AddDevice />
                  </Stack>
                </Stack>
              </Stack>
              {/* END: Body */}
            </Stack>
            <Paper
              sx={{
                position: "sticky",
                bottom: 0,
                left: 0,
                right: 0,
                height: "91px",
                overflow: "hidden",
                padding: "24px 48px 24px 48px",
              }}
              elevation={0}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                // sx={{ padding: "36px 48px 0px 48px" }  }
              >
                <Button
                  variant="outlined"
                  endIcon={<img src={trash} alt="" />}
                  sx={{
                    padding: "12px, 16px, 12px, 16px",
                    border: "1px solid #BD5250",
                    borderRadius: "6px",
                    color: "#BD5250",
                    gap: "6px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  onClick={() => setDeleteTrialDialog(true)}
                >
                  Delete
                </Button>
                <Dialog
                  open={deleteTrialDialog}
                  onClose={() => setDeleteTrialDialog(false)}
                  fullWidth
                >
                  <CustomDialogTitle
                    onClose={() => setDeleteTrialDialog(false)}
                  >
                    <Typography variant="c24px600" color={palette.neutral[900]}>
                      Delete Trial
                    </Typography>
                  </CustomDialogTitle>
                  <Divider style={{ width: "100%" }} />
                  <DialogContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="c18px500"
                        color={palette.neutral[900]}
                      >
                        Are you sure you want to delete this trial?
                      </Typography>
                      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                        <Button
                          variant="outlined"
                          sx={{
                            padding: "10px, 16px, 10px, 16px",
                            width: "50%",
                            color: palette.neutral[500],
                          }}
                        >
                          <Typography
                            variant="c16px500"
                            color={palette.neutral[700]}
                          >
                            No
                          </Typography>
                        </Button>
                        <Button
                          onClick={handleDelete}
                          sx={{
                            padding: "10px, 16px, 10px, 16px",
                            width: "50%",
                            backgroundColor: "#BD5250",
                            "&:hover": {
                              backgroundColor: "#BD5250", // Hover background color
                            },
                          }}
                          variant="contained"
                        >
                          <Typography
                            variant="c16px500"
                            color={palette.neutral[100]}
                          >
                            Yes
                          </Typography>
                        </Button>
                      </Stack>
                    </Stack>
                  </DialogContent>
                </Dialog>
                <Button
                  sx={{
                    padding: "12px 16px 12px 16px",
                    borderRadius: "6px",
                    backgroundColor: palette.primary[500],
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  onClick={() => setSubmitTrialDialog(true)}
                  variant="contained"
                  disableElevation
                >
                  Submit for approval
                </Button>
                <Dialog
                  open={submitTrialDialog}
                  onClose={() => setSubmitTrialDialog(false)}
                  fullWidth
                >
                  <CustomDialogTitle
                    onClose={() => setSubmitTrialDialog(false)}
                  >
                    <Typography variant="c24px600" color={palette.neutral[900]}>
                      Submit for Approval
                    </Typography>
                  </CustomDialogTitle>
                  <Divider style={{ width: "100%" }} />
                  <DialogContent>
                    <Stack spacing={3}>
                      <Stack spacing={1}>
                        <Typography
                          variant="c18px500"
                          color={palette.neutral[900]}
                        >
                          Are you sure you want to submit this trial for
                          approval?{" "}
                        </Typography>
                        <Typography
                          variant="body1"
                          color={palette.neutral[700]}
                          alignSelf="stretch"
                        >
                          Once you submit this trial for approval, you won’t be
                          able to make any device related changes.
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                        <Button
                          onClose={() => setSubmitTrialDialog(false)}
                          variant="outlined"
                          sx={{
                            padding: "10px, 16px, 10px, 16px",
                            width: "50%",
                            color: palette.neutral[500],
                          }}
                        >
                          <Typography
                            variant="c16px500"
                            color={palette.neutral[700]}
                          >
                            No
                          </Typography>
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          sx={{
                            padding: "10px, 16px, 10px, 16px",
                            width: "50%",
                            backgroundColor: palette.primary[500],
                            "&:hover": {
                              backgroundColor: palette.primary[500], // Hover background color
                            },
                          }}
                          variant="contained"
                        >
                          <Typography
                            variant="c16px500"
                            color={palette.neutral[100]}
                          >
                            Yes
                          </Typography>
                        </Button>
                      </Stack>
                    </Stack>
                  </DialogContent>
                </Dialog>
              </Stack>
            </Paper>
          </Box>

          {/* END: Body */}
        </Grid>
      </Grid>
    </Loading>
  );
}
