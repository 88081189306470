import { Add } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createTrialDeviceServices,
  deleteTrialDeviceServices,
} from "../../../api/services";
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/CustomDialog";
import useOnboarding from "../../../hooks/useOnboarding";
import { setNotify } from "../../../redux/utils/utilsSlice";
import { DOMAIN } from "../../../utils/config";
import { findUniqueFromArray } from "../../../utils/findUnique";
import { getAuthorization } from "../../../utils/helpers";

export const CustomTextField = styled(TextField)(({ theme }) => {
  return {
    marginBottom: "20px",
    "label + &": {
      marginTop: theme.spacing(2),
    },
    "& .MuiInputBase-input": {
      borderBottom: "0px",
      fontSize: 16,
      padding: "10px 12px",
      backgroundColor: theme.palette.neutral[100],
      border: `2px solid ${theme.palette.neutral[400]}`,
      borderRadius: "6px",
      "&:focus": {
        borderColor: theme.palette.primary[600],
        boxShadow: `0 0 0 2px #0C6FE280`,
      },
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      padding: 0,
    },
    overflow: "hidden",
    borderRadius: "4px",
  };
});

export const CustomSelect = styled(Select)(({ theme }) => {
  return {
    marginBottom: "20px",
    "label + &": {
      marginTop: theme.spacing(2),
    },
    "& .MuiInputBase-input": {
      borderBottom: "0px",
      fontSize: 16,
      padding: "10px 12px",
      backgroundColor: theme.palette.neutral[100],
      border: `2px solid ${theme.palette.neutral[400]}`,
      borderRadius: "6px",
      "&:focus": {
        borderColor: theme.palette.primary[600],
        boxShadow: `0 0 0 2px #0C6FE280`,
      },
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      padding: 0,
    },
    overflow: "hidden",
    borderRadius: "4px",
  };
});

export const CustomInputLabel = ({ label, required }) => {
  const { palette } = useTheme();

  return (
    <InputLabel
      variant="standard"
      shrink={false}
      style={{
        marginTop: "-30px",
        width: "100%",
      }}
    >
      <Typography
        variant="c16px500"
        style={{
          color: palette.neutral[500],
        }}
      >
        {label}{" "}
        {required && <span style={{ color: palette.neutral[500] }}>*</span>}
      </Typography>
    </InputLabel>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddDevice() {
  const { id } = useParams();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { trialDevices, allDevices, fetchDevices } = useOnboarding();

  const [open, setOpen] = useState(false);

  const [services, setServices] = useState([]);

  const [device, setDevice] = useState("");
  const [serviceName, setServiceName] = useState([]);
  const [subjects, setSubjects] = useState(null);

  const [deviceRes, setDeviceRes] = useState({});

  const [loading] = useState(false);

  const handleAddDevice = async () => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `${DOMAIN}/trials/devices/`,
        params: { trial_id: id, id: deviceRes.id },
        headers: {
          Authorization: getAuthorization(),
        },
        data: { trial: id, device: deviceRes.device, subm_per_subj: subjects },
      });

      if (res.status === 201 || res.status === 200) {
        dispatch(
          setNotify({
            open: true,
            action: "Device Created Successfully!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        setOpen(false);
        fetchDevices();
        setDevice("");
        setServiceName([]);
        setSubjects(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectServices = (event) => {
    const {
      target: { value },
    } = event;
    setServiceName(typeof value === "string" ? value.split(",") : value);

    const removedName = serviceName.find((service) => !value.includes(service));
    if (removedName) {
      const service = services.find((service) => service.name === removedName);
      deleteTrialDeviceServices({
        trial_id: id,
        trial_device_id: deviceRes.id,
        id: service.id,
      });
    }

    const addedName = value.find((service) => !serviceName.includes(service));
    if (addedName) {
      const service = services.find((service) => service.name === addedName);
      createTrialDeviceServices(
        { trial_id: id, trial_device_id: deviceRes.id, service_id: service.id },
        { trial_device: deviceRes.device, service: service.id }
      );
    }
  };

  const handleDeviceChange = async (e) => {
    const deviceId = e.target.value;
    setDevice(deviceId);

    try {
      const res = await axios({
        method: "POST",
        url: `${DOMAIN}/trials/devices/`,
        params: {
          trial_id: id,
          device_id: deviceId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
        data: { trial: id, device },
      });

      setDeviceRes(res.data);
      setServiceName([]);

      const services = allDevices.find(
        (device) => device.id === deviceId
      ).service_set;
      setServices(services);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Add />}
        sx={{
          padding: "10px 16px",
          textTransform: "capitalize",
          marginBottom: "24px",
        }}
        onClick={() => setOpen(true)}
      >
        Add Device
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <CustomDialogTitle onClose={() => setOpen(false)}>
          Add Device
        </CustomDialogTitle>
        <CustomDialogContent>
          <FormControl fullWidth>
            <CustomInputLabel label="Devices" required />
            <CustomSelect
              fullWidth
              displayEmpty
              value={device}
              onChange={handleDeviceChange}
              disabled={!!device}
            >
              <MenuItem value="" disabled>
                Select a device
              </MenuItem>
              {findUniqueFromArray(allDevices, trialDevices).map((device) => (
                <MenuItem key={device.id} value={device.id}>
                  {device.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
          <FormControl fullWidth>
            <CustomInputLabel label="Services" />
            <CustomSelect
              multiple
              displayEmpty
              value={serviceName}
              onChange={handleSelectServices}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) =>
                selected.length === 0 ? "Select services" : selected.join(", ")
              }
              MenuProps={MenuProps}
            >
              {services.map((service) => (
                <MenuItem key={service.id} value={service.name}>
                  <Checkbox checked={serviceName.indexOf(service.name) > -1} />
                  <ListItemText primary={service.name} />
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
          <FormControl fullWidth>
            <CustomInputLabel label="Submissions per subject" />
            <CustomTextField
              //   error={titleError ? true : false}
              //   helperText={titleError}
              fullWidth
              variant="filled"
              placeholder="Enter no. of submissions per subject"
              type="text"
              InputProps={{ disableUnderline: true }}
              value={subjects}
              onChange={(e) => setSubjects(e.target.value)}
              required
            />
          </FormControl>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button
            data-cy="button__done"
            type="submit"
            disabled={loading || !subjects}
            sx={{
              flex: 1,
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "500",
              color: palette.neutral[100],
              borderRadius: "6px",
              backgroundColor: palette.primary[500],
              padding: "10px 16px",
              lineHeight: "19.36px",
              "&:hover": {
                color: palette.neutral[100],
                backgroundColor: palette.primary[500],
              },
              "&:disabled": {
                color: palette.neutral[100],
                backgroundColor: palette.primary[500],
                opacity: 0.5,
              },
            }}
            startIcon={<Add />}
            onClick={handleAddDevice}
          >
            {loading ? "Adding" : "Add Device"}
          </Button>
        </CustomDialogActions>
      </Dialog>
    </>
  );
}
