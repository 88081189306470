import { useState } from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils/utilsSlice";

import { Button, FormControl, Stack } from "@mui/material";
import CustomDialog from "../../../components/CustomDialog";
import {
  CustomInputLabel,
  CustomTextField,
} from "../../../components/CustomTextField";
import { getAuthorization } from "../../../utils/helpers";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";

export default function ChangePassword(props) {
  const [open, setOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const [loading, setLoading] = useState(false);
  const [errorOldPassword, setErrorOldPassword] = useState([]);
  const [errorPassword1, setErrorPassword1] = useState([]);
  const [errorPassword2, setErrorPassword2] = useState([]);
  const [error, setError] = useState([]);

  // redux
  const dispatch = useDispatch();

  const handleAddSubject = async () => {
    // handle form submit

    // remove errors from state, if any
    setError([]);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/auth/password/change/`,
        data: {
          old_password: oldPassword,
          new_password1: newPassword1,
          new_password2: newPassword2,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Successfully updated password!",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      // close dialog
      setOpen(false);
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);

        // set error
        setErrorOldPassword(error_object.old_password || []);
        setErrorPassword1(error_object.new_password1 || []);
        setErrorPassword2(error_object.new_password2 || []);
      } catch {
        // default error message
        setError(["Error while changing password"]);
      }
    }
  };

  return (
    <>
      <CustomInputLabel
        label="Change Password"
        testId="icon__password"
        editAction={() => setOpen(true)}
      />
      <CustomDialog
        open={open}
        setOpen={setOpen}
        title="Update Password"
        content={
          <Stack spacing={2} pt="15px">
            <form autoComplete="off">
              <FormControl fullWidth>
                <CustomInputLabel label="Enter Old Password" />
                <CustomTextField
                  data-cy="input__oldPassword"
                  fullWidth
                  error={errorOldPassword.length ? true : false}
                  helperText={errorOldPassword[0]}
                  variant="filled"
                  placeholder="Old Password"
                  type="password"
                  InputProps={{ disableUnderline: true }}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <CustomInputLabel label="Enter New Password" />
                <CustomTextField
                  data-cy="input__newPassword"
                  fullWidth
                  error={errorPassword1.length ? true : false}
                  helperText={errorPassword1[0]}
                  variant="filled"
                  placeholder="New Password"
                  type="password"
                  InputProps={{ disableUnderline: true }}
                  value={newPassword1}
                  onChange={(e) => setNewPassword1(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <CustomInputLabel label="Confirm New Password" />
                <CustomTextField
                  data-cy="input__newConfirmPassword"
                  fullWidth
                  error={errorPassword2.length || error.length ? true : false}
                  helperText={errorPassword2[0] || error[0]}
                  variant="filled"
                  placeholder="New Password"
                  type="password"
                  InputProps={{ disableUnderline: true }}
                  value={newPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl>
                <Button
                  onClick={() => handleAddSubject()}
                  disabled={loading}
                  type="submit"
                  disableElevation
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {loading ? "Changing" : "Change"}
                </Button>
              </FormControl>
            </form>
          </Stack>
        }
      />
    </>
  );
}
