import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/CustomDialog";
import { useState } from "react";
import { getAuthorization } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { DOMAIN } from "../../../utils/config";
import axios from "axios";
import { useParams } from "react-router-dom";
import { setNotify } from "../../../redux/utils/utilsSlice";

export const CustomTextField = styled(TextField)(({ theme }) => {
  const { palette } = useTheme();

  return {
    marginBottom: "20px",
    "label + &": {
      marginTop: theme.spacing(2),
    },
    "& .MuiInputBase-input": {
      borderBottom: "0px",
      fontSize: 16,
      padding: "10px 12px",
      backgroundColor: palette.neutral[100],
      border: `2px solid ${palette.neutral[400]}`,
      borderRadius: "6px",
      "&:focus": {
        borderColor: palette.primary[600],
        boxShadow: `0 0 0 2px #0C6FE280`,
      },
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      padding: 0,
    },
    overflow: "hidden",
    borderRadius: "4px",
  };
});

export const CustomInputLabel = ({ label, required }) => {
  const { palette } = useTheme();

  return (
    <InputLabel
      variant="standard"
      style={{
        marginTop: "-30px",
        width: "100%",
      }}
    >
      <Typography
        variant="c16px500"
        style={{
          color: palette.neutral[500],
        }}
      >
        {label}{" "}
        {required && <span style={{ color: palette.neutral[500] }}>*</span>}
      </Typography>
    </InputLabel>
  );
};

export default function EditTrial({
  trialName,
  trialDescription,
  updateValue,
  fetchUpdatedTrial,
}) {
  const { typography, palette } = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [open, setOpen] = useState(false);

  const [name, setName] = useState(trialName || "");
  const [description, setDescription] = useState(trialDescription || "");

  const [loading, setLoading] = useState(false);

  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);

  const handleUpdateTrial = async () => {
    setNameError(null);
    setLoading(true);

    if (!name && updateValue === "name") {
      setNameError("Name is required");
      setLoading(false);
      return;
    }

    if (!description && updateValue === "description") {
      setDescriptionError("Description is required");
      setLoading(false);
      return;
    }

    axios({
      method: "PATCH",
      url: `${DOMAIN}/trials/`,
      params: {
        id,
      },
      data: {
        name,
        description,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        setOpen(false);
        setLoading(false);
        fetchUpdatedTrial();
        dispatch(
          setNotify({
            open: true,
            action: "Trial updated successfully",
            severity: "info",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          setNotify({
            open: true,
            action: "Something went wrong",
            severity: "info",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <Button
        disableRipple
        size="small"
        sx={{
          padding: "4px",
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: typography.fontWeightMedium,
        }}
        onClick={() => setOpen(true)}
      >
        Edit
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <CustomDialogTitle
          data-cy="title__dialog"
          onClose={() => setOpen(false)}
        >
          Send Notification
        </CustomDialogTitle>
        <CustomDialogContent>
          {updateValue === "name" ? (
            <FormControl fullWidth>
              <CustomInputLabel label="Name" required />
              <CustomTextField
                data-cy="input__name"
                fullWidth
                error={nameError ? true : false}
                helperText={nameError}
                variant="filled"
                type="text"
                InputProps={{ disableUnderline: true }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </FormControl>
          ) : (
            <FormControl fullWidth>
              <CustomInputLabel label="Description" required />
              <CustomTextField
                data-cy="input__description"
                multiline
                rows={4}
                fullWidth
                error={descriptionError ? true : false}
                helperText={descriptionError}
                variant="filled"
                type="text"
                InputProps={{ disableUnderline: true }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </FormControl>
          )}
        </CustomDialogContent>
        <CustomDialogActions>
          <Button
            data-cy="button__done"
            type="submit"
            disabled={loading}
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: palette.neutral[100],
              borderRadius: "6px",
              backgroundColor: palette.primary[500],
              padding: "10px 16px",
              lineHeight: "19.36px",
              "&:hover": {
                color: palette.neutral[100],
                backgroundColor: palette.primary[500],
              },
            }}
            onClick={handleUpdateTrial}
          >
            {loading ? "Updating" : "Done"}
          </Button>
        </CustomDialogActions>
      </Dialog>
    </>
  );
}
