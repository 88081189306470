import { ExpandMore } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  FormControl,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/CustomDialog";
import {
  CustomInputLabel,
  CustomSelect,
} from "../../../components/CustomSelect";
import {
  CustomTextField,
  StyledInputLabel,
} from "../../../components/CustomTextField";
import useTasks from "../../../hooks/useTasks";
import { setNotify } from "../../../redux/utils/utilsSlice";
import { DOMAIN } from "../../../utils/config";
import {
  convertDayRangeArray,
  convertTimeArray,
  formatDuration,
  getAuthorization,
  reverseConvertTimeArray,
} from "../../../utils/helpers";
import { GhostText, NoteTypography } from "./AddTask";

export default function EditTask(props) {
  const { id } = useParams();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const {
    fetchDevices: fetchTaskDevices,
    fetchQuestionnaires: fetchTaskQuestionnaires,
  } = useTasks();

  const isLaptopScreen = useMediaQuery("(min-width: 1440px)");
  const isDesktopScreen = useMediaQuery("(min-width: 1920px)");

  const [deviceOrQuestionnaire, setDeviceOrQuestionnaire] = useState("");
  const [taskType, setTaskType] = useState(props.taskType || "");
  const [questionnaires, setQuestionnaires] = useState([]);
  const [devices, setDevices] = useState([]);
  const [durations, setDurations] = useState(
    props.duration.map((value) => formatDuration(value)) || []
  );
  const [timings, setTimings] = useState(
    reverseConvertTimeArray(props.timing) || []
  );

  const [title, setTitle] = useState(props.title || "");
  const [instructions, setInstructions] = useState(props.instruction || "");
  const [duration, setDuration] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [currentStep, setNextStep] = useState("taskInfo");

  const handleDeviceOrQuestionnaireChange = async (e) => {
    const value = e.target.value;
    setDeviceOrQuestionnaire(value);
    const currTaskType = value.title ? "questionnaire" : "device";
    setTaskType(currTaskType);
  };

  const handleDurationChange = (event) => {
    const value = event.target.value;

    // Check if the last character is a comma ',' or space ' '
    const isCommaOrSpace = /[,\s]/.test(value.slice(-1));

    if (isCommaOrSpace) {
      const cleanedValue = value.replace(/[,\s]/g, "");
      if (cleanedValue === "") {
        return;
      }
      setDurations((prevState) => [...prevState, formatDuration(cleanedValue)]);
      setDuration("");
    } else {
      setDuration(value);
    }
  };

  const handleAddTiming = () => {
    if (!endTime) {
      const timing = `${moment(startTime).format("LT")} - 11:59 PM`;
      setTimings((prev) => [...prev, timing]);
      setStartTime("");
    }
    if (endTime) {
      const timing = `${moment(startTime).format("LT")} - ${moment(
        endTime
      ).format("LT")}`;
      setTimings((prev) => [...prev, timing]);
      setStartTime("");
      setEndTime("");
    }
  };

  const handleRemoveTiming = (idx) => {
    setTimings((prev) => prev.filter((_, i) => i !== idx));
  };

  const handleRemoveDuration = (idx) => {
    setDurations((prev) => prev.filter((_, i) => i !== idx));
  };

  const fetchQuestionnaires = useCallback(async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/questionnaire/versions/all/`,
        params: { trial_id: id },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      setQuestionnaires(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  const fetchDevices = useCallback(async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/trials/devices/`,
        params: { trial_id: id },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      setDevices(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  const handleAddTask = async () => {
    const restValues =
      taskType === "questionnaire"
        ? { questionnaire_version: deviceOrQuestionnaire.id }
        : { trial_device: deviceOrQuestionnaire.id };
    try {
      const res = await axios({
        method: "PATCH",
        url:
          taskType === "questionnaire"
            ? `${DOMAIN}/tasks/questionnaires/`
            : `${DOMAIN}/tasks/devices/`,
        headers: {
          Authorization: getAuthorization(),
        },
        params: {
          id: props.taskId,
        },
        data: {
          name: title,
          description: instructions,
          ...restValues,
          schedule: convertDayRangeArray(durations),
          timing: convertTimeArray(timings),
        },
      });

      if (res.status === 200 || res.status === 204) {
        if (res.data.message) {
          dispatch(
            setNotify({
              open: true,
              action: res.data.message,
              severity: "info",
              autoHideDuration: 3000,
              vertical: "bottom",
              horizontal: "right",
            })
          );
        } else {
          dispatch(
            setNotify({
              open: true,
              action: "Task updated successfully!",
              severity: "success",
              autoHideDuration: 3000,
              vertical: "bottom",
              horizontal: "right",
            })
          );
        }
        fetchTaskDevices();
        fetchTaskQuestionnaires();
        setOpenDialog(false);
      }
    } catch (err) {
      console.error(err);

      // Display the error message in a toast
      const errorMessage = err.response?.data?.detail || "An error occurred";
      dispatch(
        setNotify({
          open: true,
          action: `Error: ${errorMessage}`,
          severity: "error",
          autoHideDuration: 3000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    }
  };

  useEffect(() => {
    fetchQuestionnaires();
    fetchDevices();
  }, [fetchQuestionnaires, fetchDevices]);

  return (
    <>
      <MenuItem onClick={() => setOpenDialog(true)}>
        <Stack direction="row" spacing={2}>
          <ListItemText
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: palette.neutral[700],
            }}
          >
            Edit
          </ListItemText>
          <ListItemIcon
            sx={{
              height: "16px",
              width: "16px",
              color: palette.neutral[700],
            }}
          >
            <EditOutlinedIcon />
          </ListItemIcon>
        </Stack>
      </MenuItem>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth={isDesktopScreen ? "1920" : isLaptopScreen ? "1536" : "1440"}
      >
        <Stack direction="row">
          <Stack
            width="212px"
            direction="column"
            justifyContent="space-between"
            py={3}
            px={2}
            sx={{ backgroundColor: palette.primary[100] }}
            height={
              isDesktopScreen ? "800px" : isLaptopScreen ? "760px" : "720px"
            }
          >
            <Stack spacing={2}>
              <Typography
                variant="c16px600"
                color={
                  currentStep === "taskInfo"
                    ? palette.neutral[900]
                    : palette.primary[600]
                }
              >
                Step 1: Task Info
              </Typography>
              <Typography
                variant="c16px600"
                color={
                  currentStep === "taskSchedule"
                    ? palette.neutral[900]
                    : palette.neutral[500]
                }
              >
                Step 2: Task Schedule
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="c14px600" color={palette.neutral[900]}>
                Note:
              </Typography>
              <NoteTypography>
                {currentStep === "taskInfo"
                  ? `Tasks are created for subjects' participation by using devices
                and questionnaires.`
                  : `Tasks can be added for a single day or for multiple days.`}
              </NoteTypography>
              <NoteTypography>
                {currentStep === "taskInfo"
                  ? `You can schedule each task and write instructions for the
                subjects.`
                  : `Tasks can have multiple timings.`}
              </NoteTypography>
            </Stack>
          </Stack>
          <Stack>
            <CustomDialogTitle onClose={() => setOpenDialog(false)}>
              Update Task
            </CustomDialogTitle>
            <CustomDialogContent>
              {currentStep === "taskInfo" ? (
                <>
                  <Stack>
                    <Typography
                      variant="c18px600"
                      color={palette.neutral[700]}
                      marginBottom={3}
                    >
                      Step 1: Task Info
                    </Typography>
                  </Stack>
                  <FormControl fullWidth>
                    <StyledInputLabel label="Enter task title" required />
                    <CustomTextField
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Task Title"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <CustomInputLabel
                      label="Select a device or a questionnaire you want to create task for"
                      required
                    />
                    <CustomSelect
                      fullWidth
                      displayEmpty
                      value={deviceOrQuestionnaire}
                      onChange={handleDeviceOrQuestionnaireChange}
                      disabled={!!deviceOrQuestionnaire}
                    >
                      <MenuItem value="" disabled>
                        Select a device or a questionnaire
                      </MenuItem>
                      {[...devices, ...questionnaires].map((item) => (
                        <MenuItem key={uuid()} value={item}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                            spacing="auto"
                          >
                            <Typography>
                              {(item.questionnaire &&
                                item.questionnaire.title) ||
                                item.device_details.name}
                            </Typography>
                            <Typography color={palette.neutral[500]}>
                              {item.questionnaire ? "Questionnaire" : "Device"}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                  <FormControl fullWidth>
                    <StyledInputLabel
                      label="Enter task instructions for the subjects"
                      required
                    />
                    <CustomTextField
                      multiline
                      rows={4}
                      fullWidth
                      variant="filled"
                      placeholder="Instructions"
                      value={instructions}
                      onChange={(e) => setInstructions(e.target.value)}
                    />
                  </FormControl>
                </>
              ) : null}
              {currentStep === "taskSchedule" ? (
                <>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="c18px600"
                      color={palette.neutral[700]}
                      marginBottom={3}
                    >
                      Step 2: Task Schedule
                    </Typography>
                    <GhostText>Trial duration is of 60 days</GhostText>
                  </Stack>
                  <FormControl fullWidth>
                    <StyledInputLabel
                      label="Enter the days on which subjects would participate in this task"
                      required
                    />
                    <CustomTextField
                      fullWidth
                      variant="filled"
                      placeholder="Ex: 1-5, 12-24, 35, 36, 38"
                      value={duration}
                      onChange={handleDurationChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              {durations.map((d, idx) => (
                                <GhostText
                                  key={idx}
                                  onRemove={() => handleRemoveDuration(idx)}
                                >
                                  {d}
                                </GhostText>
                              ))}
                            </Stack>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        marginBottom: "8px",
                        "& .MuiInputBase-root": {
                          border: `1px solid ${palette.neutral[400]}`,
                          backgroundColor: palette.neutral[100],
                          "&:hover": {
                            borderColor: palette.neutral[400],
                          },
                          "&:focus": {
                            borderColor: palette.primary[600],
                            boxShadow: `0 0 0 2px #0C6FE280`,
                          },
                          "& .MuiInputBase-input": {
                            border: "unset",
                            "&:focus": {
                              borderColor: "unset",
                              boxShadow: "unset",
                            },
                          },
                        },
                        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart":
                          {
                            marginTop: "0 !important",
                            marginLeft: durations.length ? "8px" : "0px",
                          },
                        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart:focus":
                          {
                            marginLeft: durations.length ? "8px" : "0px",
                          },
                      }}
                    />
                    <Typography
                      variant="c14px500"
                      color={palette.neutral[500]}
                      sx={{ mt: "unset", pt: "unset" }}
                    >
                      Note: Use dash (-) to add a range. Separate the days with
                      comma (,).
                    </Typography>
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography
                      variant="c16px500"
                      color={palette.neutral[700]}
                      sx={{ marginTop: "12px", marginBottom: "8px" }}
                    >
                      Timings:
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      flexWrap="wrap"
                      spacing={2}
                      sx={{ marginBottom: "12px" }}
                    >
                      {timings.length ? (
                        timings.map((timing, index) => (
                          <GhostText
                            key={index}
                            onRemove={() => handleRemoveTiming(index)}
                          >
                            {timing}
                          </GhostText>
                        ))
                      ) : (
                        <GhostText>All Day (Default)</GhostText>
                      )}
                    </Stack>

                    <Accordion
                      elevation={0}
                      sx={{
                        borderRadius: "6px",
                        border: `1px solid ${palette.neutral[300]}`,
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Add Custom Timings</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={3}
                            mb={2}
                          >
                            <Stack
                              direction="row"
                              alignItems="end"
                              spacing={1.5}
                            >
                              <FormControl>
                                <StyledInputLabel label="Start Time" required />
                                <DesktopTimePicker
                                  value={startTime}
                                  onChange={(selectedTime) =>
                                    setStartTime(selectedTime)
                                  }
                                  InputProps={{ disableUnderline: true }}
                                  renderInput={(params) => (
                                    <CustomTextField
                                      data-cy="timerpicker__startTime"
                                      fullWidth
                                      required
                                      variant="outlined"
                                      placeholder="HH:MM"
                                      type="text"
                                      InputProps={{ disableUnderline: true }}
                                      {...params}
                                      sx={{
                                        marginBottom: "20px",
                                        "label + &": {
                                          marginTop: "16px",
                                        },
                                        "& .MuiInputBase-root": {
                                          border: `1px solid ${palette.neutral[400]}`,
                                          backgroundColor: palette.neutral[100],
                                          "&:hover": {
                                            borderColor: palette.neutral[400],
                                          },
                                          "&:focus": {
                                            borderColor: palette.primary[600],
                                            boxShadow: `0 0 0 2px #0C6FE280`,
                                          },
                                          "& .MuiInputBase-input": {
                                            borderBottom: "0px",
                                            fontSize: 16,
                                            padding: "10px 12px",
                                            border: "unset",
                                            "&:focus": {
                                              borderColor: "unset",
                                              boxShadow: "unset",
                                            },
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="end"
                              spacing={1.5}
                            >
                              <FormControl>
                                <StyledInputLabel label="End Time" />
                                <DesktopTimePicker
                                  value={endTime}
                                  onChange={(selectedTime) =>
                                    setEndTime(selectedTime)
                                  }
                                  InputProps={{ disableUnderline: true }}
                                  renderInput={(params) => (
                                    <CustomTextField
                                      data-cy="timerpicker__endTime"
                                      fullWidth
                                      required
                                      variant="outlined"
                                      placeholder="HH:MM"
                                      type="text"
                                      InputProps={{ disableUnderline: true }}
                                      {...params}
                                      sx={{
                                        marginBottom: "20px",
                                        "label + &": {
                                          marginTop: "16px",
                                        },
                                        "& .MuiInputBase-root": {
                                          border: `1px solid ${palette.neutral[400]}`,
                                          backgroundColor: palette.neutral[100],
                                          "&:hover": {
                                            borderColor: palette.neutral[400],
                                          },
                                          "&:focus": {
                                            borderColor: palette.primary[600],
                                            boxShadow: `0 0 0 2px #0C6FE280`,
                                          },
                                          "& .MuiInputBase-input": {
                                            borderBottom: "0px",
                                            fontSize: 16,
                                            padding: "10px 12px",
                                            border: "unset",
                                            "&:focus": {
                                              borderColor: "unset",
                                              boxShadow: "unset",
                                            },
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Stack>
                          </Stack>
                        </LocalizationProvider>
                        <Button
                          disabled={!startTime}
                          variant="outlined"
                          color="primary"
                          fullWidth
                          sx={{ padding: "12px 16px" }}
                          onClick={handleAddTiming}
                        >
                          Add Time
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </FormControl>
                </>
              ) : null}
            </CustomDialogContent>
            <CustomDialogActions>
              {currentStep === "taskSchedule" ? (
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  sx={{ padding: "12px 16px", marginRight: "28px" }}
                  onClick={() => setNextStep("taskInfo")}
                >
                  Back
                </Button>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ padding: "12px 16px" }}
                disabled={!title || !instructions}
                onClick={() =>
                  currentStep === "taskInfo"
                    ? setNextStep("taskSchedule")
                    : handleAddTask()
                }
              >
                {currentStep === "taskInfo" ? "Next" : "Update Task"}
              </Button>
            </CustomDialogActions>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}
