import { Divider, Paper, Stack, Typography, useTheme } from "@mui/material";
import EditDevice from "./EditDevice";
import DeleteDevice from "./DeleteDevice";

export default function DeviceCard(props) {
  const { palette } = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        height: "100%",
        padding: "32px 24px",
        width: "100%",
        borderRadius: "6px",
        border: "1px solid #E8E8E8",
      }}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={3}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: "100%",
            alignItems: "center",
            alignSelf: "stretch",
          }}
        >
          <Typography variant="c16px500" sx={{ color: palette.neutral[700] }}>
            {props.device?.device_details?.name}
          </Typography>
          <Stack direction="row" spacing={1}>
            <EditDevice
              device={props.device}
              fetchDevices={props.fetchDevices}
            />

            <DeleteDevice
              deviceId={props.device?.id}
              fetchDevices={props.fetchDevices}
            />
          </Stack>
        </Stack>
        <Divider style={{ width: "100%", marginTop: "12px" }} />
        <Stack direction="row" spacing={25}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            spacing={2}
            sx={{
              width: "100%",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <Typography variant="c12px500" sx={{ color: palette.neutral[700] }}>
              Device
            </Typography>
            <Typography variant="c16px500" sx={{ color: palette.neutral[700] }}>
              {props.device?.device_details?.name}
            </Typography>{" "}
          </Stack>
          <Stack
            direction="column"
            justifyContent="flex-start"
            spacing={2}
            sx={{
              width: "100%",
            }}
          >
            {" "}
            <Typography variant="c12px500" sx={{ color: palette.neutral[700] }}>
              Services
            </Typography>
            {props.device?.trialdeviceservice_set
              .filter((service) => service.is_active)
              .map((service) => (
                <Typography
                  key={service.id}
                  variant="c16px500"
                  sx={{ color: palette.neutral[700] }}
                >
                  {service.service_details.name}
                </Typography>
              ))}
          </Stack>
        </Stack>
        <Divider style={{ width: "100%", marginTop: "12px" }} />
        <Stack direction="column" spacing={1}>
          <Typography variant="c12px500" color={palette.neutral[700]}>
            Submission(s) per subject
          </Typography>
          <Typography variant="c16px500" color={palette.neutral[700]}>
            {props.device?.subm_per_subj}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}
