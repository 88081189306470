import { Box, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../../redux/utils/utilsSlice";

export default function Questionnaires() {
  const { palette } = useTheme();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("questionnaires"));
  }, [dispatch]);

  return (
    <Box height="100vh" sx={{ display: "grid", placeItems: "center" }}>
      <Typography color={palette.primary[700]} fontSize="32px" fontWeight={600}>
        Coming Soon!
      </Typography>
    </Box>
  );
}

// import * as React from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import { styled } from "@mui/material/styles";
// import { Box, Paper, Typography } from "@mui/material";
// const columns = [
//   {
//     field: "id",
//     headerName: "Subject ID",
//     width: 100,
//     headerClassName: "super-app-theme--header",
//     headerAlign: "left",
//   },
//   { field: "day", headerName: "Day", width: 80 },

//   {
//     field: "timeStampOne",
//     headerName: "Time Stamp",
//     width: 132,
//   },
//   {
//     field: "bmOne",
//     headerName: "BM1",
//     width: 132,
//   },
//   {
//     field: "bmTwo",
//     headerName: "BM2",
//     width: 132,
//   },
//   {
//     field: "bmThree",
//     headerName: "BM3",
//     width: 132,
//   },
//   {
//     field: "bmFour",
//     headerName: "BM4",
//     width: 132,
//   },
//   {
//     field: "timeStampTwo",
//     headerName: "Time Stamp",
//     width: 132,
//   },
//   {
//     field: "bmFive",
//     headerName: "BM1",
//     width: 132,
//   },
//   {
//     field: "bmSix",
//     headerName: "BM2",
//     width: 132,
//   },
//   {
//     field: "bmSeven",
//     headerName: "BM3",
//     width: 132,
//   },
//   {
//     field: "bmEight",
//     headerName: "BM4",
//     width: 132,
//   },
//   {
//     field: "bmNine",
//     headerName: "BM4",
//     width: 132,
//   },
//   {
//     field: "bmTen",
//     headerName: "BM4",
//     width: 132,
//   },
//   {
//     field: "bmEleven",
//     headerName: "BM4",
//     width: 132,
//   },
//   {
//     field: "bmTwelve",
//     headerName: "BM4",
//     width: 132,
//   },
// ];

// const rows = [
//   { id: 1, day: 1, lastName: "Snow", firstName: "Jon", age: 35 },
//   { id: 2, day: 1, lastName: "Lannister", firstName: "Cersei", age: 42 },
//   { id: 3, day: 1, lastName: "Lannister", firstName: "Jaime", age: 45 },
//   { id: 4, day: 1, lastName: "Stark", firstName: "Arya", age: 16 },
//   { id: 5, day: 1, lastName: "Targaryen", firstName: "Daenerys", age: null },
//   { id: 6, day: 1, lastName: "Melisandre", firstName: null, age: 150 },
//   { id: 7, day: 1, lastName: "Clifford", firstName: "Ferrara", age: 44 },
//   { id: 8, day: 1, lastName: "Frances", firstName: "Rossini", age: 36 },
//   { id: 9, day: 1, lastName: "Roxie", firstName: "Harvey", age: 65 },
// ];

// const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
//   border: 0,
//   color: "#4A4A4A",

//   WebkitFontSmoothing: "auto",
//   letterSpacing: "normal",
//   "& .MuiDataGrid-columnsContainer": {
//     backgroundColor: "red",
//   },
//   "& .MuiDataGrid-iconSeparator": {
//     display: "none",
//   },
//   "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
//     borderRight: `1px solid ${"#E8E8E8"}`,
//   },
//   "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
//     borderBottom: `1px solid ${"#E8E8E8"}`,
//   },
//   "& .MuiDataGrid-cell": {
//     color:
//       theme.palette.mode === "light"
//         ? "rgba(0,0,0,.85)"
//         : "rgba(255,255,255,0.65)",
//   },
// }));

// const columnGroupingModel = [
//   {
//     groupId: "Device: Movsense",
//     headerClassName: "super-app-theme--header",
//     headerAlign: "center",

//     children: [
//       {
//         groupId: "Task 1: Breathing Exercise",
//         headerClassName: "super-app-theme--header",
//         headerAlign: "center",
//         children: [
//           {
//             groupId: " Schedule 1: 08:30 AM",
//             headerClassName: "super-app-theme--header",
//             headerAlign: "center",

//             children: [
//               { field: "timeStampOne" },
//               { field: "bmOne" },
//               { field: "bmTwo" },
//               { field: "bmThree" },
//               { field: "bmFour" },
//             ],
//           },

//           {
//             groupId: "Schedule 2: 05:45 PM",
//             headerClassName: "super-app-theme--header",
//             headerAlign: "center",
//             children: [
//               { field: "timeStampTwo" },
//               { field: "bmFive" },
//               { field: "bmSix" },
//               { field: "bmSeven" },
//               { field: "bmEight" },
//             ],
//           },
//         ],
//       },
//       {
//         groupId: "Task 2: Sleep Task",
//         headerClassName: "super-app-theme--header",
//         headerAlign: "center",
//         children: [
//           {
//             groupId: "Schedule 1: 09:00 PM",
//             headerClassName: "super-app-theme--header",
//             headerAlign: "center",

//             children: [
//               { field: "timeStampThree" },
//               { field: "bmNine" },
//               { field: "bmTen" },
//               { field: "bmEleven" },
//               { field: "bmTwelve" },
//             ],
//           },
//         ],
//       },
//     ],
//   },

//   // {
//   //   groupId: "Device: Garmin",
//   //   children: [
//   //     {
//   //       groupId: "Task 1: Breathing Exercise",
//   //       children: [{ field: "scheduleFive" }, { field: "scheduleSix" }],
//   //     },

//   //     {
//   //       groupId: "Task 2: Breathing Exercise",
//   //       children: [{ field: "scheduleSeven" }, { field: "scheduleEight" }],
//   //     },
//   //   ],
//   // },
// ];

// export default function BasicGroupingDemo() {
//   return (
//     <>
//       {/* <Stack direction="column" spacing={3}> */}
//       {/* <Box
//           sx={{ padding: "12px 24px 12px 24px", backgroundColor: "#ffffff" }}
//         >
//           <Typography variant="c16px500" color="#2D2D2D">
//             Longform Table
//           </Typography>
//         </Box> */}
//       <Paper
//         elevation={0}
//         sx={{
//           padding: "12px 24px 12px 24px",
//           margin: "12px",
//           borderBottom: "1px solid #E8E8E8",
//         }}
//       >
//         <Typography variant="c16px500">Longform Table</Typography>
//       </Paper>
//       <Paper sx={{ height: "100%", margin: "12px" }}>
//         <Box
//           sx={{
//             height: "100%",
//             width: "100%",
//             margin: "12px",
//             border: "1px solid #E8E8E8",
//           }}
//         >
//           <StyledDataGrid
//             experimentalFeatures={{ columnGrouping: true }}
//             hideFooterPagination
//             rows={rows}
//             columns={columns}
//             disableRowSelectionOnClick
//             columnGroupingModel={columnGroupingModel}
//           />
//         </Box>
//       </Paper>
//       {/* </Stack> */}
//     </>
//   );
// }
