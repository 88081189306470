import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Loading from "../../../components/Blockers/Loading";
import AddTrial from "./AddTrial";
import trash from "../../../assets/common/trash.svg";
import arrowback from "../../../assets/common/arrowLeft.svg";
import { CustomDialogTitle } from "../../../components/CustomDialog";
import { useState } from "react";
export default function NewTrial() {
  const [deleteTrialDialog, setDeleteTrialDialog] = useState(false);
  const [submitTrialDialog, setSubmitTrialDialog] = useState(false);

  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <Loading>
      <Grid
        container
        style={{
          height: "100%",
          // minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* START: Top Nav */}
            <Stack
              direction="column"
              // component={Paper}
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: palette.neutral[100],
                zIndex: 101,
              }}
            >
              {/* START: First Row */}
              <Stack
                px="32px"
                py="24px"
                paddingTop="24px"
                paddingBottom="24px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => navigate(-1)}
                  >
                    <IconButton>
                      {/* <ArrowBackIcon sx={{ color: palette.neutral.dark }} /> */}
                      <img src={arrowback} alt="" />
                    </IconButton>
                    <Typography
                      variant="c20px600"
                      style={{
                        textAlign: "left",
                        color: palette.neutral[900],
                      }}
                    >
                      New Trial
                    </Typography>
                  </Stack>
                  {/* START: Edit Schedule Button */}

                  {/* END: Edit Schedule Button */}
                </Stack>
              </Stack>
              {/* END: First Row */}
            </Stack>
            {/* END: Top Nav */}
            {/* START:Body*/}
            <Stack
              minHeight="90vh"
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              sx={{ paddingTop: "32px" }}
            >
              {/* START: Body */}

              {/* <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "688px",
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  width: "100%",
                }}
                spacing={1}
              >
                <Paper
                  elevation={0}
                  sx={{
                    height: "100%",
                    padding: "24px 24px 24px 24px",
                    width: "100%",
                  }}
                >
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={3}
                  >
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <FormControl fullWidth>
                        <InputLabel
                          variant="standard"
                          style={{
                            marginTop: "-25px",
                            width: "100%",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="c14px400"
                              style={{
                                textAlign: "left",
                                color: palette.neutral[500],
                              }}
                            >
                              Trial Name
                            </Typography>
                          </Stack>
                        </InputLabel>
                        <CustomTextField
                          fullWidth
                          required
                          variant="outlined"
                          placeholder="Enter Trial Name"
                          type="text"
                          InputProps={{ disableUnderline: true }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel
                          variant="standard"
                          style={{
                            marginTop: "-25px",
                            width: "100%",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="c14px400"
                              style={{
                                textAlign: "left",
                                color: palette.neutral[500],
                              }}
                            >
                              Trial Description
                            </Typography>
                          </Stack>
                        </InputLabel>
                        <CustomTextField
                          fullWidth
                          required
                          variant="outlined"
                          placeholder="Enter Trial Name"
                          type="text"
                          multiline
                          InputProps={{ disableUnderline: true }}
                        />
                      </FormControl>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Stack> */}
              <AddTrial />

              {/* END: Body */}
            </Stack>
            <Paper
              sx={{
                position: "sticky",
                bottom: 0,
                left: 0,
                right: 0,
                height: "91px",
                overflow: "hidden",
                padding: "24px 48px 24px 48px",
              }}
              elevation={0}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                // sx={{ padding: "36px 48px 0px 48px" }  }
              >
                <Button
                  disabled
                  variant="outlined"
                  endIcon={<img src={trash} alt="" />}
                  sx={{
                    padding: "12px, 16px, 12px, 16px",
                    border: "1px solid #BD5250",
                    borderRadius: "6px",
                    color: "#BD5250",
                    gap: "6px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  onClick={() => setDeleteTrialDialog(true)}
                >
                  Delete
                </Button>
                <Dialog
                  open={deleteTrialDialog}
                  onClose={() => setDeleteTrialDialog(false)}
                  fullWidth
                >
                  <CustomDialogTitle
                    onClose={() => setDeleteTrialDialog(false)}
                  >
                    <Typography variant="c24px600" color={palette.neutral[900]}>
                      Delete Trial
                    </Typography>
                  </CustomDialogTitle>
                  <Divider style={{ width: "100%" }} />
                  <DialogContent>
                    <Stack spacing={2}>
                      <Typography
                        variant="c18px500"
                        color={palette.neutral[900]}
                      >
                        Are you sure you want to delete this trial?
                      </Typography>
                      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                        <Button
                          variant="outlined"
                          sx={{
                            padding: "10px, 16px, 10px, 16px",
                            width: "50%",
                            color: palette.neutral[500],
                          }}
                        >
                          <Typography
                            variant="c16px500"
                            color={palette.neutral[700]}
                          >
                            No
                          </Typography>
                        </Button>
                        <Button
                          sx={{
                            padding: "10px, 16px, 10px, 16px",
                            width: "50%",
                            backgroundColor: "#BD5250",
                            "&:hover": {
                              backgroundColor: "#BD5250", // Hover background color
                            },
                          }}
                          variant="contained"
                        >
                          <Typography
                            variant="c16px500"
                            color={palette.neutral[100]}
                          >
                            Yes
                          </Typography>
                        </Button>
                      </Stack>
                    </Stack>
                  </DialogContent>
                </Dialog>
                <Button
                  disabled
                  sx={{
                    padding: "12px 16px 12px 16px",
                    borderRadius: "6px",
                    backgroundColor: palette.primary[500],
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  onClick={() => setSubmitTrialDialog(true)}
                  variant="contained"
                  disableElevation
                >
                  Submit for approval
                </Button>
                <Dialog
                  open={submitTrialDialog}
                  onClose={() => setSubmitTrialDialog(false)}
                  fullWidth
                >
                  <CustomDialogTitle
                    onClose={() => setSubmitTrialDialog(false)}
                  >
                    <Typography variant="c24px600" color={palette.neutral[900]}>
                      Submit for Approval
                    </Typography>
                  </CustomDialogTitle>
                  <Divider style={{ width: "100%" }} />
                  <DialogContent>
                    <Stack spacing={3}>
                      <Stack spacing={1}>
                        <Typography
                          variant="c18px500"
                          color={palette.neutral[900]}
                        >
                          Are you sure you want to submit this trial for
                          approval?{" "}
                        </Typography>
                        <Typography
                          variant="body1"
                          color={palette.neutral[700]}
                          alignSelf="stretch"
                        >
                          Once you submit this trial for approval, you won’t be
                          able to make any device related changes.
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                        <Button
                          variant="outlined"
                          sx={{
                            padding: "10px, 16px, 10px, 16px",
                            width: "50%",
                            color: palette.neutral[500],
                          }}
                        >
                          <Typography
                            variant="c16px500"
                            color={palette.neutral[700]}
                          >
                            No
                          </Typography>
                        </Button>
                        <Button
                          sx={{
                            padding: "10px, 16px, 10px, 16px",
                            width: "50%",
                            backgroundColor: palette.primary[500],
                            "&:hover": {
                              backgroundColor: palette.primary[500], // Hover background color
                            },
                          }}
                          variant="contained"
                        >
                          <Typography
                            variant="c16px500"
                            color={palette.neutral[100]}
                          >
                            Yes
                          </Typography>
                        </Button>
                      </Stack>
                    </Stack>
                  </DialogContent>
                </Dialog>
              </Stack>
            </Paper>
          </Box>

          {/* END: Body */}
        </Grid>
      </Grid>
    </Loading>
  );
}
