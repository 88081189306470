import { Divider, Paper, Stack, Typography, useTheme } from "@mui/material";
import Loading from "../../../components/Blockers/Loading";
import useDeviceAndQuestionnaire from "../../../hooks/useDevice&Questionnaire";

function ServiceCard(props) {
  const { palette } = useTheme();

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Typography
          variant="c14px500"
          sx={{
            backgroundColor: palette.neutral[200],
            padding: "4px 8px 4px 8px",
            borderRadius: "4px",
            color: palette.neutral[700],
          }}
        >
          {props.service.service_details.name}
        </Typography>
      </Stack>
    </>
  );
}

function DeviceCard(props) {
  const { palette } = useTheme();

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="column" spacing={1}>
          <Paper
            elevation={0}
            sx={{
              padding: "20px",
              borderRadius: "6px",
              border: "1px solid #E8E8E8",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                <Typography variant="c12px600" color={palette.neutral[500]}>
                  Devices
                </Typography>
                <Typography variant="c16px600" color={palette.neutral[700]}>
                  {props.device.device_details.name}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
            <Stack alignSelf="stretch" direction="column" spacing={0.5}>
              <Typography variant="c12px600" color={palette.neutral[500]}>
                Services{" "}
              </Typography>
              {props.device.trialdeviceservice_set.map((service) => (
                <ServiceCard key={service.id} service={service} />
              ))}
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </>
  );
}

export default function Devices() {
  const { devices, devisLoading } = useDeviceAndQuestionnaire();

  return (
    <Loading loading={devisLoading}>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="c18px600">Devices</Typography>
        </Stack>
        {devices.map((device) => (
          <DeviceCard key={device.id} device={device} />
        ))}
      </Stack>
    </Loading>
  );
}
