import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useState } from "react";
import { Stack, TextField, useTheme } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { setNotify, setSaver } from "../../../redux/utils/utilsSlice";
import { getAuthorization } from "../../../utils/helpers";
import { setValues } from "../../../redux/biomarkers/biomarkerSlice";

const SUBMIT_FILTER_STROKE_TIME = 500;

const NOTE_FIELD = "Note";

const DEFAULT_COLUMNS = [
  {
    field: "Subject",
    headerClassName: "data-grid-head",
    description:
      "The identification used by the person with access to the online service.",
  },
  {
    field: "Date",
    headerClassName: "data-grid-head",
    description:
      "The identification used by the person with access to the online service.",
  },
  {
    field: "Time",
    headerClassName: "data-grid-head",
    description:
      "The identification used by the person with access to the online service.",
  },
  {
    field: "Test",
    filterOperators: [
      {
        label: "Between",
        value: "between",
        getApplyFilterFn: (filterItem) => {
          if (
            !Array.isArray(filterItem.value) ||
            filterItem.value.length !== 2
          ) {
            return null;
          }
          if (filterItem.value[0] == null || filterItem.value[1] == null) {
            return null;
          }

          return ({ value }) => {
            return (
              value !== null &&
              filterItem.value[0] <= value &&
              value <= filterItem.value[1]
            );
          };
        },
        InputComponent: InputNumberInterval,
      },
    ],
    headerClassName: "data-grid-head",
    description:
      "The identification used by the person with access to the online service.",
  },
  {
    field: NOTE_FIELD,
    headerClassName: "data-grid-head",
    description: "Supervisor can add notes to the particular test.",
    editable: "true",
    width: 200,
  },
];

const getRowName = (id, list) => {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i]["id"] === id) {
      return list[i]["name"];
    }
  }
  return false;
};

const getRows = (data, biomarkerKeys) => {
  let rows = [];
  data.map((subject, subject_i) => {
    subject.sessions.map((session, session_i) => {
      session.tests.map((test, test_i) => {
        let dateTime = new Date(test.created_on);

        // Extract date parts
        let year = dateTime.getFullYear();
        let month = ("0" + (dateTime.getMonth() + 1)).slice(-2); // Zero-padding the month
        let date = ("0" + dateTime.getDate()).slice(-2); // Zero-padding the date
        // Extract time parts
        let hours = ("0" + dateTime.getHours()).slice(-2); // Zero-padding the hours
        let minutes = ("0" + dateTime.getMinutes()).slice(-2); // Zero-padding the minutes
        let seconds = ("0" + dateTime.getSeconds()).slice(-2); // Zero-padding the seconds

        let biomarkers = {};
        test.biomarkers.map((biomarker, test_i) => {
          biomarkers = {
            ...biomarkers,
            [getRowName(biomarker.biomarker, biomarkerKeys)]: biomarker.value,
          };
          return biomarkers;
        });
        rows = [
          ...rows,
          {
            Subject: subject.uid,
            Test: test.id,
            id: test.id,
            Date: `${year}-${month}-${date}`,
            Time: `${hours}:${minutes}:${seconds}`,

            [NOTE_FIELD]: test.note,
            ...biomarkers,
          },
        ];
        return rows;
      });
      return rows;
    });
    return rows;
  });
  return rows;
};

const exists = (obj, list) => {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }
  return false;
};

const getColumns = (data, selected) => {
  let columns = [];

  data.map((biomarker, index) => {
    if (exists(biomarker.id, selected)) {
      columns = [
        ...columns,
        {
          field: biomarker.name,
          headerClassName: "data-grid-head",
          editable: Boolean(biomarker.category.trial),
          description: biomarker.description || "No description provided!",
        },
      ];
      return columns;
    }
    return columns;
  });
  return [...DEFAULT_COLUMNS, ...columns];
};

function InputNumberInterval(props) {
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = React.useRef();
  const [filterValueState, setFilterValueState] = React.useState(
    item.value ?? ""
  );

  const [applying, setIsApplying] = React.useState(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound, upperBound) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [lowerBound, upperBound] });
    }, SUBMIT_FILTER_STROKE_TIME);
  };

  const handleUpperFilterChange = (event) => {
    const newUpperBound = event.target.value;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (event) => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "end",
        height: 48,
        pl: "20px",
      }}
    >
      <TextField
        name="lower-bound-input"
        placeholder="From"
        label="From"
        variant="standard"
        value={Number(filterValueState[0])}
        onChange={handleLowerFilterChange}
        type="number"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
      <TextField
        name="upper-bound-input"
        placeholder="To"
        label="To"
        variant="standard"
        value={Number(filterValueState[1])}
        onChange={handleUpperFilterChange}
        type="number"
        InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
      />
    </Box>
  );
}

InputNumberInterval.propTypes = {
  applyValue: PropTypes.func.isRequired,
  focusElementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
  item: PropTypes.shape({
    /**
     * The column from which we want to filter the rows.
     */
    columnField: PropTypes.string.isRequired,
    /**
     * Must be unique.
     * Only useful when the model contains several items.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * The name of the operator we want to apply.
     * Will become required on `@mui/x-data-grid@6.X`.
     */
    operatorValue: PropTypes.string,
    /**
     * The filtering value.
     * The operator filtering function will decide for each row if the row values is correct compared to this value.
     */
    value: PropTypes.any,
  }).isRequired,
};

export default function DataGridTable(props) {
  const { palette } = useTheme();
  const values = useSelector((state) => state.biomarkers.values);
  const selectedBiomarkerKeys = useSelector(
    (state) => state.biomarkers.selectedBiomarkerKeys
  );
  const biomarkerKeys = useSelector((state) => state.biomarkers.biomarkerKeys);

  const { id } = useParams();

  const getBiomarkerIdByName = (biomarkerName) => {
    const biomarkers = biomarkerKeys.filter((i) => i.name === biomarkerName);
    if (biomarkers.length) {
      return biomarkers[0].id;
    } else {
      return null;
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Stack spacing={3} direction="row">
          <GridToolbarExport />
        </Stack>
      </GridToolbarContainer>
    );
  }

  const [columns, setColumns] = useState(
    getColumns(biomarkerKeys, selectedBiomarkerKeys)
  );

  const dispatch = useDispatch();

  const [rows, setRows] = useState(getRows(values, biomarkerKeys));

  const handleEditNote = async (testId, note) => {
    try {
      dispatch(
        setSaver({
          open: true,
          title: "saving changes",
          action: {
            icon: "autoRenew",
          },
        })
      );
      // make API request
      const response = await axios({
        method: "POST",
        url: `${DOMAIN}/trials/tests/notes/`,
        params: {
          id: testId,
        },
        data: {
          note,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      // Update State
      const updatedValues = values.map((subject) => {
        if (subject.id === response.data.subject_id) {
          return {
            ...subject,
            sessions: subject.sessions.map((session) => {
              if (session.id === response.data.session) {
                return {
                  ...session,
                  tests: session.tests.map((test) => {
                    if (test.id === testId) {
                      return {
                        ...test,
                        note: response.data.metadata.note,
                      };
                    }
                    return test;
                  }),
                };
              }
              return session;
            }),
          };
        }
        return subject;
      });
      dispatch(setValues(updatedValues));

      // saver
      dispatch(
        setSaver({
          open: true,
          autoHideDuration: 3000,
          title: "changes saved",
          action: {
            icon: "Check",
          },
        })
      );
    } catch (err) {
      let error = "";
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        // set error
        error = error_object.message;
      } catch {
        // default error message
        error = "Unable to save";
      }
      // notify
      dispatch(
        setNotify({
          open: true,
          action: error,
          severity: "error",
          autoHideDuration: 3000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
      dispatch(
        setSaver({
          open: false,
          title: "",
          action: {},
        })
      );
    }
  };

  const handleEditBiomarker = async (biomarkerName, testId, value) => {
    try {
      dispatch(
        setSaver({
          open: true,
          title: "saving changes",
          action: {
            icon: "autoRenew",
          },
        })
      );
      // make API request
      const response = await axios({
        method: "POST",
        url: `${DOMAIN}/biomarkers/values/`,
        params: {
          id: testId,
          trial_id: id,
        },
        data: {
          value: value,
          biomarker: getBiomarkerIdByName(biomarkerName),
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      // Update State
      const updatedValues = values.map((subject) => {
        if (subject.id === response.data.subject_id) {
          return {
            ...subject,
            sessions: subject.sessions.map((session) => {
              if (session.id === response.data.session_id) {
                return {
                  ...session,
                  tests: session.tests.map((test) => {
                    if (test.id === testId) {
                      // check if the biomarker value exists
                      let found = false;
                      let tempBiomarkers = {
                        ...test,
                        biomarkers: test.biomarkers.map((biomarker) => {
                          // if exists, update the value
                          if (biomarker.id === response.data.id) {
                            found = true;
                            return {
                              ...biomarker,
                              value: response.data.value,
                            };
                          }
                          return biomarker;
                        }),
                      };
                      // else, create the value instance in grid table
                      if (!found) {
                        tempBiomarkers = {
                          ...tempBiomarkers,
                          biomarkers: [
                            ...tempBiomarkers.biomarkers,
                            {
                              id: response.data.id,
                              test: test.id,
                              value: response.data.value,
                              biomarker: response.data.biomarker,
                              subject_id: response.data.subject_id,
                              session_id: response.data.session_id,
                            },
                          ],
                        };
                      }
                      return tempBiomarkers;
                    }
                    return test;
                  }),
                };
              }
              return session;
            }),
          };
        }
        return subject;
      });
      dispatch(setValues(updatedValues));

      // saver
      dispatch(
        setSaver({
          open: true,
          autoHideDuration: 3000,
          title: "changes saved",
          action: {
            icon: "Check",
          },
        })
      );
    } catch (err) {
      let error = "";
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        // set error
        error = error_object.message;
      } catch {
        // default error message
        error = "Unable to save";
      }
      // notify
      dispatch(
        setNotify({
          open: true,
          action: error,
          severity: "error",
          autoHideDuration: 3000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
      dispatch(
        setSaver({
          open: false,
          title: "",
          action: {},
        })
      );
    }
  };

  const handleCellEdit = (e) => {
    switch (e.field) {
      case NOTE_FIELD:
        handleEditNote(e.id, e.value);
        break;
      default:
        handleEditBiomarker(e.field, e.id, e.value);
        break;
    }
  };

  useEffect(() => {
    setColumns(getColumns(biomarkerKeys, selectedBiomarkerKeys));
    setRows(getRows(values, biomarkerKeys));
  }, [selectedBiomarkerKeys, biomarkerKeys, values]);

  return (
    <Box
      sx={{
        height: "62vh",
        "& .data-grid-head": {},
      }}
    >
      <DataGrid
        checkboxSelection
        disableSelectionOnClick
        hideFooter
        hideFooterPagination
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: { printOptions: { disableToolbarButton: true } },
        }}
        rows={rows}
        columns={columns}
        onCellEditCommit={handleCellEdit}
        sx={{
          backgroundColor: palette.neutral[100],
          height: "100%",
        }}
      />
    </Box>
  );
}
