// 3rd party modules
import { Link } from "react-router-dom";

// material ui hooks
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/auth/authSlice";
import { setNotify } from "../redux/utils/utilsSlice";

// material ui modules
import {
  Tooltip,
  styled,
  tooltipClasses,
  Box,
  Drawer,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

// utils
import urls from "../utils/urls.json";

// images
import logo from "../assets/logo/UpdatedHubLogo.svg";
import neurobit from "../assets/sidebar/neurobit.svg";

import { ReactComponent as TrialIcon } from "../assets/sidebar/trial.svg";
import { ReactComponent as QuestionnaireIcon } from "../assets/sidebar/questionarre.svg";
import { ReactComponent as ProfileIcon } from "../assets/sidebar/user-circle.svg";
import { ReactComponent as ContactIcon } from "../assets/sidebar/send.svg";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary[600],
    color: theme.palette.neutral[100],
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const sidebar_links = [
  {
    name: "Trials",
    code: "trials",
    icon: TrialIcon,
    url: urls.home.path,
  },
  {
    name: "Questionnaires",
    code: "questionnaires",
    icon: QuestionnaireIcon,
    url: urls.questionnaires.path,
  },
  {
    name: "Profile",
    code: "profile",
    icon: ProfileIcon,
    url: urls.profile.path,
  },

  {
    name: "Contact Us",
    code: "contact",
    icon: ContactIcon,
    url: urls.contact.path,
  },
];

export default function SideBar({ drawerWidth, open, setOpen, children }) {
  const isSmallScreen = useMediaQuery("(max-width: 1440px)");

  const auth = useSelector((state) => state.auth);
  const utils = useSelector((state) => state.utils);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { palette } = useTheme();

  const handleLogout = () => {
    // logout on click
    dispatch(logout());

    // notify
    dispatch(
      setNotify({
        open: true,
        action: "Logged out Successfully!",
        severity: "success",
        autoHideDuration: 5000,
        vertical: "bottom",
        horizontal: "right",
      })
    );

    // redirect to login page
    navigate(urls.login.path);
  };

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { lg: drawerWidth },
          minHeight: "100vh",
        }}
        className="Sidebar-Component"
      >
        <Drawer
          variant={matches ? "permanent" : "temporary"}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,

              background: "#00162F",
            },
          }}
          open={matches ? open : !open}
          onClose={() => setOpen(!open)}
        >
          <Box
            sx={{
              width: "80%",
              mx: "auto",
              pt: "48px",
            }}
          >
            <Typography align="center">
              <img
                src={neurobit}
                alt="logo"
                style={{
                  width: isSmallScreen ? "72%" : "40%",
                  maxWidth: "72px",
                }}
              />
            </Typography>
            <Typography align="center">
              <img
                src={logo}
                alt="logo"
                style={{
                  width: isSmallScreen ? "72%" : "40%",
                  maxWidth: "72px",
                }}
              />
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexFlow: "nowrap column",
              justifyContent: "space-between",
              height: "calc(100vh - 112px)",
            }}
          >
            <Stack
              alignItems={isSmallScreen ? "center" : "flex-start"}
              justifyContent="space-between"
              marginTop={4}
              spacing={3}
            >
              {sidebar_links.map(({ name, code, icon: Icon, url }, index) => (
                <Box
                  sx={{
                    width: isSmallScreen ? "unset" : "100%",
                    paddingX: "16px",
                  }}
                  key={`sidebar-element-${index}`}
                >
                  <MenuItem
                    component={Link}
                    to={url}
                    sx={{
                      width: "100%",
                      padding: "0",
                    }}
                  >
                    {isSmallScreen ? (
                      <StyledTooltip
                        title={name}
                        placement="right"
                        followCursor
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            border: `1px solid transparent`,
                            "&:hover": {
                              border: `1px solid ${palette.primary[700]}`,
                              transition: "all 0.5s ease",
                            },
                            p: "12px 16px",
                            width: "100%",
                            borderRadius: "6px",
                            backgroundColor:
                              utils.activeTab === code
                                ? palette.primary[700]
                                : "transparent",
                          }}
                        >
                          <Icon
                            style={{
                              color:
                                utils.activeTab === code
                                  ? palette.neutral[100]
                                  : palette.neutral[400],
                              "&:hover": {
                                color: palette.neutral[100],
                                transition: "all 0.5s ease",
                              },
                              marginRight: isSmallScreen ? "unset" : "8px",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </Box>
                      </StyledTooltip>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          border: `1px solid transparent`,
                          "&:hover": {
                            border: `1px solid ${palette.primary[700]}`,
                            transition: "all 0.5s ease",
                          },
                          p: "12px 16px",
                          width: "100%",
                          borderRadius: "6px",
                          backgroundColor:
                            utils.activeTab === code
                              ? palette.primary[700]
                              : "transparent",
                        }}
                      >
                        <Icon
                          style={{
                            color:
                              utils.activeTab === code
                                ? palette.neutral[100]
                                : palette.neutral[400],
                            "&:hover": {
                              color: palette.neutral[100],
                              transition: "all 0.5s ease",
                            },
                            marginRight: isSmallScreen ? "unset" : "8px",
                          }}
                        />
                        {!isSmallScreen ? (
                          <Typography
                            variant="c18px500"
                            sx={{
                              color:
                                utils.activeTab === code
                                  ? palette.neutral[100]
                                  : palette.neutral[400],
                              "&:hover": {
                                color: palette.neutral[100],
                                transition: "all 0.5s ease",
                              },
                            }}
                            noWrap
                          >
                            {name}
                          </Typography>
                        ) : null}
                      </Box>
                    )}
                  </MenuItem>
                </Box>
              ))}
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                pb: "32px",
              }}
            >
              {auth.isAuthenticated ? (
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleLogout}
                >
                  <PowerSettingsNewIcon
                    fontSize="20px"
                    style={{
                      color: palette.neutral[100],
                      cursor: "pointer",
                    }}
                  />
                  {!isSmallScreen ? (
                    <Typography
                      variant="c16px600"
                      sx={{
                        color: palette.neutral[100],
                      }}
                      noWrap
                    >
                      Logout
                    </Typography>
                  ) : null}
                </Stack>
              ) : null}
            </Box>
          </Box>
        </Drawer>
      </Box>
    </>
  );
}
