import { useDispatch } from "react-redux";
import { setActiveTab } from "../../../redux/utils/utilsSlice";

import {
  Button,
  Grid,
  Stack,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "../../../components/Blockers/Loading";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import { toCamelCase } from "../../../utils/camelCase";
import { Add, Close } from "@mui/icons-material";
import arrowRight from "../../../assets/common/arrowRight.svg";
import PropTypes from "prop-types";
import userCircle from "../../../assets/common/usersCircle.svg";
import { getTrialStatus } from "../../../utils/status";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  const { palette } = useTheme();

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: palette.neutral[700] }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Trials() {
  const [loading, setLoading] = useState(true);
  const [trials, setTrials] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  // react-router-dom navigator
  const navigator = useNavigate();
  const userId = JSON.parse(localStorage.getItem("user")).id;

  // redux
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const handleClickOpen = (props) => {
    setDialogData(props);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setDialogData(null);
    setOpenDialog(false);
  };

  useEffect(() => {
    dispatch(setActiveTab("trials"));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${DOMAIN}/trials/`,
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        setTrials(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Card = (props) => (
    <Grid
      item
      xs
      sx={{
        minWidth: 356,
        maxWidth: 640,
      }}
    >
      <Stack spacing={0}>
        <Stack
          data-cy={`${toCamelCase(props.name)}`}
          direction="column"
          sx={{
            cursor: "pointer",
            height: "80%",
            borderTopRightRadius: "6px",
            borderTopLeftRadius: "6px",
            border: `1px solid ${palette.neutral[300]}`,
            "&:hover": {
              border: `1px solid ${palette.primary[500]}`,
            },
            "&:clicked": {
              border: `1.5px solid ${palette.primary[500]}`,
            },
            backgroundColor: palette.neutral[100],
          }}
          onClick={() =>
            props.status === "init"
              ? navigator(`/trial/${props.id}/start`)
              : navigator(`/trial/${props.id}`)
          }
        >
          <Stack
            direction="column"
            spacing={2}
            p="24px 24px 16px"
            height="180px"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                {props.status ? (
                  <Typography
                    variant="c14px600"
                    sx={{
                      color: getTrialStatus(props.status).color,
                      padding: "4px 8px",
                      backgroundColor: getTrialStatus(props.status)
                        .backgroundColor,
                      borderRadius: "4px",
                    }}
                  >
                    {getTrialStatus(props.status).label}
                  </Typography>
                ) : null}
              </Stack>
              <Stack
                direction="row"
                sx={{
                  padding: "4px 8px",
                  borderRadius: "4px",
                  border: "1px solid #E8E8E8",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                  width: "40% ",
                }}
              >
                <Typography
                  variant="c14px500"
                  sx={{
                    color: palette.neutral[500],
                  }}
                >
                  {userId === props.created_by ? "Owner" : "Collaborator"}
                </Typography>
                <img
                  src={userCircle}
                  alt="icon"
                  width="16px"
                  height="16px
                  "
                />
              </Stack>
            </Stack>
            <Stack height="48px">
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  textAlign: "left",
                  lineHeight: "normal",
                  color: palette.neutral[900],
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  textOverflow: "ellipsis",
                }}
              >
                {props.name}
              </Typography>
            </Stack>
            <Stack height="76px">
              <Typography
                variant="body1"
                style={{
                  textAlign: "left",
                  color: palette.neutral[500],
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 4,
                  textOverflow: "ellipsis",
                }}
              >
                {props.description}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center  "
          padding="12px 24px"
          sx={{
            cursor: "pointer",
            height: "20%",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            border: `1px solid ${palette.neutral[300]}`,
            "&:hover": {
              border: `1px solid ${palette.primary[500]}`,
            },
            "&:clicked": {
              border: `1.5px solid ${palette.primary[500]}`,
            },
            backgroundColor: palette.neutral[100],
          }}
        >
          {/* <Typography
            variant="c14px500"
            style={{
              textAlign: "left",
              color: palette.primary[500],
              backgroundColor: palette.primary[100],
              padding: "4px 8px",
              borderRadius: "4px",
            }}
          >
            {userId === props.partner ? "Owner" : "Collaborator"}
          </Typography> */}
          <Button
            endIcon={<img src={arrowRight} alt="icon" />}
            // onClick={(e) => {
            //   e.stopPropagation(); // Stop event propagation here
            //   navigator(`/trial/${props.id}/overview`);
            // }}
            onClick={(e) => {
              handleClickOpen(props);
            }}
            sx={{
              color: palette.neutral[700],
              fontSize: "16px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: palette.neutral[200],
              },
              "&:clicked": {
                backgroundColor: palette.neutral[300],
              },
            }}
          >
            Quick View
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      style={{
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Loading loading={loading}>
        {trials.length ? (
          <>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                alignItems="center"
                px={{ xs: "32px", ll: "48px" }}
                py="32px"
                // component={Paper}
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: palette.neutral[100],
                  zIndex: 101,
                  width: "100%",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.07)",
                }}
              >
                <Typography
                  variant="c24px600"
                  style={{
                    textAlign: "left",
                  }}
                >
                  All Trials
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    //   onClick={() => setAddTrialDialog(true)}
                    onClick={() => navigator(`/new`)}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disableElevation
                      startIcon={<Add />}
                    >
                      Start New Trial
                    </Button>
                  </Stack>{" "}
                </Stack>
              </Stack>

              <Grid
                container
                px={{ xs: "32px", xl: "48px" }}
                py={3}
                sx={{
                  backgroundColor: "transparent !important",
                  zIndex: 100,
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(356px, 1fr))",
                }}
                spacing={3}
              >
                {trials.map((item, index) => (
                  <Card key={index} {...item} />
                ))}
              </Grid>
              {dialogData && (
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  maxWidth="lg"
                >
                  <BootstrapDialogTitle onClose={handleCloseDialog}>
                    <Stack
                      borderBottom="1px solid #E8E8E8"
                      sx={{
                        padding: "24px 24px 24px 0px",
                      }}
                    >
                      <Typography
                        variant="c20px600"
                        color={palette.neutral[900]}
                      >
                        Trial Info
                      </Typography>
                    </Stack>
                  </BootstrapDialogTitle>
                  <DialogContent>
                    <Stack
                      spacing={2}
                      sx={{
                        width: "640px",
                      }}
                    >
                      <Stack
                        spacing={1}
                        sx={{
                          paddingBottom: "16px",
                          borderBottom: "1px solid #E8E8E8",
                        }}
                      >
                        <Typography
                          variant="c14px500"
                          color={palette.neutral[700]}
                        >
                          TRIAL NAME
                        </Typography>
                        <Typography
                          variant="c18px600"
                          color={palette.neutral[700]}
                        >
                          {dialogData.name}
                        </Typography>
                      </Stack>
                      <Stack
                        width="100%"
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          paddingBottom: "16px",
                          borderBottom: "1px solid #E8E8E8",
                        }}
                      >
                        <Stack spacing={1} width="50%">
                          <Typography
                            variant="c14px500"
                            color={palette.neutral[700]}
                          >
                            TRIAL STATUS{" "}
                          </Typography>
                          <Box sx={{ width: "100%" }}>
                            <Typography
                              variant="c14px600"
                              sx={{
                                color: getTrialStatus(dialogData.status).color,

                                padding: "4px 8px",
                                backgroundColor: getTrialStatus(
                                  dialogData.status
                                ).backgroundColor,
                                borderRadius: "4px",
                              }}
                            >
                              {getTrialStatus(dialogData.status).label}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack spacing={1} width="50%">
                          <Typography
                            variant="c14px500"
                            color={palette.neutral[700]}
                          >
                            TRIAL ACCESS{" "}
                          </Typography>
                          <Stack
                            direction="row"
                            sx={{
                              padding: "4px 8px",
                              borderRadius: "4px",
                              border: "1px solid #E8E8E8",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "4px",
                              width: "40% ",
                            }}
                          >
                            <Typography
                              variant="c14px500"
                              sx={{
                                color: palette.neutral[500],
                              }}
                            >
                              {userId === dialogData.created_by
                                ? "Owner"
                                : "Collaborator"}{" "}
                            </Typography>
                            <img
                              src={userCircle}
                              alt="icon"
                              width="16px"
                              height="16px
                  "
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack
                        spacing={1}
                        sx={{
                          paddingBottom: "16px",
                          borderBottom: "1px solid #E8E8E8",
                        }}
                      >
                        <Typography
                          variant="c14px500"
                          color={palette.neutral[700]}
                        >
                          TRIAL DESCRIPTION
                        </Typography>
                        <Typography
                          variant="c16px500"
                          color={palette.neutral[700]}
                        >
                          {dialogData.description}
                        </Typography>
                      </Stack>
                      <Stack
                        spacing={1}
                        sx={{
                          paddingBottom: "16px",
                          borderBottom: "1px solid #E8E8E8",
                        }}
                      >
                        <Typography
                          variant="c14px500"
                          color={palette.neutral[700]}
                        >
                          ORGANISATION NAME{" "}
                        </Typography>
                        <Typography
                          variant="c16px500"
                          color={palette.neutral[700]}
                        >
                          {dialogData.org_name}
                        </Typography>
                      </Stack>
                      <Stack
                        spacing={1}
                        sx={{
                          paddingBottom: "16px",
                          borderBottom: "1px solid #E8E8E8",
                        }}
                      >
                        <Typography
                          variant="c14px500"
                          color={palette.neutral[700]}
                        >
                          TOTAL NO. OF SUBJECTS
                        </Typography>
                        <Typography
                          variant="c16px500"
                          color={palette.neutral[700]}
                        >
                          {dialogData.max_sub}
                        </Typography>
                      </Stack>
                      <Stack
                        spacing={1}
                        sx={{
                          paddingBottom: "16px",
                          borderBottom: "1px solid #E8E8E8",
                        }}
                      >
                        <Typography
                          variant="c14px500"
                          color={palette.neutral[700]}
                        >
                          TRIAL DURATION
                        </Typography>
                        <Typography
                          variant="c16px500"
                          color={palette.neutral[700]}
                        >
                          {dialogData.duration} Days
                        </Typography>
                      </Stack>
                      <Stack
                        spacing={1}
                        sx={{
                          paddingBottom: "16px",
                          borderBottom: "1px solid #E8E8E8",
                        }}
                      >
                        <Typography
                          variant="c14px500"
                          color={palette.neutral[700]}
                        >
                          EXPECTED TRIAL END DATE
                        </Typography>
                        <Typography
                          variant="c16px500"
                          color={palette.neutral[700]}
                        >
                          {dialogData.end_date}
                        </Typography>
                      </Stack>
                    </Stack>
                  </DialogContent>
                </Dialog>
              )}
            </Grid>
          </>
        ) : (
          <Stack
            sx={{ width: "100%" }}
            direction="column"
            alignItems="center"
            justifyContent="center"
            //   onClick={() => setAddTrialDialog(true)}
            onClick={() => navigator(`/new`)}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              startIcon={<Add />}
            >
              Start New Trial
            </Button>
          </Stack>
        )}
      </Loading>
    </Grid>
  );
}

export default Trials;
