import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import Loading from "../../../components/Blockers/Loading";
import EditQuestionnaireInfo from "./EditQuestionnaireInfo";

export default function EditQuestionnaire() {
  const { palette } = useTheme();

  return (
    <Loading>
      <Grid
        container
        style={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* START: Top Nav */}
            <Stack
              direction="column"
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: palette.neutral[100],
                zIndex: 101,
              }}
            >
              {/* START: First Row */}
              <Stack
                px="32px"
                py="24px"
                paddingTop="24px"
                paddingBottom="24px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography
                      variant="c24px600"
                      style={{
                        textAlign: "left",
                        color: palette.neutral[900],
                      }}
                    >
                      New Questionnaire
                    </Typography>
                  </Stack>
                  {/* START: Edit Schedule Button */}

                  {/* END: Edit Schedule Button */}
                </Stack>
              </Stack>
              {/* END: First Row */}
            </Stack>
            {/* END: Top Nav */}
            {/* START:Body*/}
            <Stack
              minHeight="90vh"
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              sx={{ paddingTop: "32px" }}
            >
              {/* START: Body */}
              <Stack width="680px" alignItems="flex-start" spacing={3}>
                {/* <Button
                  variant="text"
                  startIcon={<KeyboardBackspace />}
                  sx={{
                    color: palette.primary[600],
                    padding: "unset",
                    "&:hover": {
                      color: palette.primary[700],
                      backgroundColor: palette.primary[100],
                    },
                  }}
                >
                  Back
                </Button> */}
                <Typography variant="c18px600" color={palette.neutral[700]}>
                  Step 1: Questionnaire Info
                </Typography>
              </Stack>
              <EditQuestionnaireInfo />
              {/* END: Body */}
            </Stack>
          </Box>

          {/* END: Body */}
        </Grid>
      </Grid>
    </Loading>
  );
}
