import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils/utilsSlice";
import {
  Button,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { getAuthorization } from "../../../utils/helpers";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import asterisk from "../../../assets/common/asterisk.svg";
import { useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";

// import chart from "../../../assets/Chart.svg";

export default function AddTrial(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [orgName, setOrgName] = useState("");
  const [subjectCount, setSubjectCount] = useState("");
  const [duration, setDuration] = useState("");
  const [endDate, setEndDate] = useState("");

  const { palette } = useTheme();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // redux
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateTrial = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      const res = await axios({
        method: "POST",
        url: `${DOMAIN}/trials/`,
        data: {
          name,
          description,
          org_name: orgName,
          max_sub: subjectCount,
          duration: duration,
          end_date: moment(endDate).format("YYYY-MM-DD"),
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Trial created successfully",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      // redirect if suceeded
      navigate(`/trial/${res.data.id}/start`);
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.message);
        // set error
        setError(error_object.message);
      } catch {
        // default error message
        setError("Error while creating trial");
      }
    }
  };

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "688px",
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{
            width: "100%",
          }}
          spacing={3}
        >
          <Paper
            elevation={0}
            sx={{
              height: "100%",
              padding: "32px 24px",
              width: "100%",
              border: "1px solid #E8E8E8",
              borderRadius: "6px",
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={3}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                  alignItems: "center",
                  alignSelf: "stretch",
                }}
              >
                <Typography variant="c20px500">Trial Info</Typography>
                <Button
                  disabled={loading}
                  type="submit"
                  onClick={handleCreateTrial}
                  sx={{
                    padding: "12px",
                    backgroundColor: palette.primary[500],
                    color: palette.neutral[100],
                    borderRadius: "6px",
                  }}
                >
                  Save
                </Button>
              </Stack>
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={3}
                sx={{ width: "100%" }}
              >
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    style={{
                      marginTop: "-25px",
                      width: "100%",
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Typography
                        variant="c14px400"
                        style={{
                          textAlign: "left",
                          color: palette.neutral[500],
                        }}
                      >
                        Trial Name
                      </Typography>
                      <img
                        src={asterisk}
                        alt="icon"
                        style={{ marginBottom: "10px" }}
                      />
                    </Stack>
                  </InputLabel>
                  <TextField
                    fullWidth
                    required
                    error={error ? true : false}
                    helperText={error}
                    variant="outlined"
                    placeholder="Enter Trial Name"
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => setName(e.target.value)}
                    sx={{
                      marginTop: "17px",
                      "& .MuiInputBase-input": {
                        padding: "12px",
                        borderRadius: "6px",
                      },
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    style={{
                      marginTop: "-25px",
                      width: "100%",
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Typography
                        variant="c14px400"
                        style={{
                          textAlign: "left",
                          color: palette.neutral[500],
                        }}
                      >
                        Trial Description
                      </Typography>
                      <img
                        src={asterisk}
                        style={{ marginBottom: "10px" }}
                        alt="icon"
                      />
                    </Stack>
                  </InputLabel>
                  <TextField
                    fullWidth
                    required
                    multiline
                    error={error ? true : false}
                    helperText={error}
                    variant="outlined"
                    placeholder="Enter Trial Description"
                    type="text"
                    InputProps={{ disableUnderline: true, padding: "2px" }}
                    onChange={(e) => setDescription(e.target.value)}
                    sx={{
                      marginTop: "17px",
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    style={{
                      marginTop: "-25px",
                      width: "100%",
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Typography
                        variant="c14px400"
                        style={{
                          textAlign: "left",
                          color: palette.neutral[500],
                        }}
                      >
                        Organisation Name
                      </Typography>
                      <img
                        src={asterisk}
                        style={{ marginBottom: "10px" }}
                        alt="icon"
                      />
                    </Stack>
                  </InputLabel>
                  <TextField
                    fullWidth
                    required
                    multiline
                    error={error ? true : false}
                    helperText={error}
                    variant="outlined"
                    placeholder="Enter Organisation Name"
                    type="text"
                    InputProps={{ disableUnderline: true, padding: "2px" }}
                    onChange={(e) => setOrgName(e.target.value)}
                    sx={{
                      marginTop: "17px",
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    style={{
                      marginTop: "-25px",
                      width: "100%",
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Typography
                        variant="c14px400"
                        style={{
                          textAlign: "left",
                          color: palette.neutral[500],
                        }}
                      >
                        Total no. of Subjects
                      </Typography>
                      <img
                        src={asterisk}
                        style={{ marginBottom: "10px" }}
                        alt="icon"
                      />
                    </Stack>
                  </InputLabel>
                  <TextField
                    fullWidth
                    required
                    error={error ? true : false}
                    helperText={error}
                    variant="outlined"
                    placeholder="Enter Total no. of Subjects"
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => setSubjectCount(e.target.value)}
                    sx={{
                      marginTop: "17px",
                      "& .MuiInputBase-input": {
                        padding: "12px",
                        borderRadius: "6px",
                      },
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    style={{
                      marginTop: "-25px",
                      width: "100%",
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Typography
                        variant="c14px400"
                        style={{
                          textAlign: "left",
                          color: palette.neutral[500],
                        }}
                      >
                        Trial Duration
                      </Typography>
                      <img
                        src={asterisk}
                        style={{ marginBottom: "10px" }}
                        alt="icon"
                      />
                    </Stack>
                  </InputLabel>
                  <TextField
                    fullWidth
                    required
                    error={error ? true : false}
                    helperText={error}
                    variant="outlined"
                    placeholder="Example: 32 (days)"
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    sx={{
                      marginTop: "17px",
                      "& .MuiInputBase-input": {
                        padding: "12px",
                        borderRadius: "6px",
                      },
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    style={{
                      marginTop: "-25px",
                      width: "100%",
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Typography
                        variant="c14px400"
                        style={{
                          textAlign: "left",
                          color: palette.neutral[500],
                        }}
                      >
                        Expected Trial End Date
                      </Typography>
                      <img
                        src={asterisk}
                        style={{ marginBottom: "10px" }}
                        alt="icon"
                      />
                    </Stack>
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      inputFormat="MM/DD/YYYY"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e);
                      }}
                      InputProps={{ disableUnderline: true }}
                      renderInput={(params) => (
                        <TextField
                          data-cy="datepicker__startDate"
                          fullWidth
                          required
                          variant="outlined"
                          placeholder="DD/MM/YYYY"
                          type="text"
                          InputProps={{ disableUnderline: true }}
                          {...params}
                          sx={{
                            marginTop: "17px",
                            "& .MuiInputBase-input": {
                              padding: "12px",
                              borderRadius: "6px",
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Stack>
            </Stack>
          </Paper>
          <Stack
            sx={{
              width: "100%",
            }}
          >
            <Button
              disabled
              variant="outlined"
              startIcon={<Add />}
              sx={{
                padding: "10px 16px",
                textTransform: "capitalize",
                marginBottom: "24px",
              }}
            >
              Add Device
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
