import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

export default function AddQuestionnaire() {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          padding: "8px 12px",
        }}
        startIcon={<Add />}
        onClick={() => navigate(`/trial/${id}/questionnaire/new`)}
        data-cy="link__addQuestionnaire"
      >
        Add Questionnaire
      </Button>
    </>
  );
}
