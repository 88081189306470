import DeviceCard from "./DeviceCard";
import useOnboarding from "../../../hooks/useOnboarding";

export default function TrialDevicesList(props) {
  const { trialDevices } = useOnboarding();

  return (
    <>
      {trialDevices.map((device) => (
        <DeviceCard key={device.id} device={device} />
      ))}
    </>
  );
}
