import {
  Button,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import asterisk from "../../../assets/common/asterisk.svg";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import Loading from "../../../components/Blockers/Loading";

export default function EditQuestionnaireInfo() {
  const { id, questionnaireId } = useParams();
  const { palette } = useTheme();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchQuestionnaireInfo = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/questionnaire/`,
        headers: {
          Authorization: getAuthorization(),
        },
        params: {
          trial_id: id,
          id: questionnaireId,
        },
      });

      if (res.status === 200) {
        setTitle(res.data.payload[0].title);
        setDescription(res.data.payload[0].description);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [questionnaireId, id]);

  const handleEditQuestionnaireInfo = async () => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `${DOMAIN}/questionnaire/`,
        headers: {
          Authorization: getAuthorization(),
        },
        params: {
          id: questionnaireId,
        },
        data: {
          trial: id,
          title,
          description,
        },
      });

      if (res.status === 200 || res.status === 204) {
        navigate(
          `/trial/${id}/questionnaire/${res.data.id}/${res.data.versions[0]}/questions`
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchQuestionnaireInfo();
  }, [fetchQuestionnaireInfo]);

  return (
    <Loading loading={loading}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "688px",
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{
            width: "100%",
          }}
          spacing={3}
        >
          <Paper
            elevation={0}
            sx={{
              height: "100%",
              padding: "32px 24px",
              width: "100%",
              border: "1px solid #E8E8E8",
              borderRadius: "6px",
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={3}
              sx={{ width: "100%" }}
            >
              <FormControl fullWidth>
                <InputLabel
                  variant="standard"
                  style={{
                    marginTop: "-25px",
                    width: "100%",
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="c14px400"
                      style={{
                        textAlign: "left",
                        color: palette.neutral[500],
                      }}
                    >
                      Questionnaire Title{" "}
                    </Typography>
                    <img
                      src={asterisk}
                      alt="icon"
                      style={{ marginBottom: "10px" }}
                    />
                  </Stack>
                </InputLabel>
                <TextField
                  fullWidth
                  required
                  data-cy="input__questionnaireTitle"
                  // error={error ? true : false}
                  // helperText={error}
                  variant="outlined"
                  placeholder="Questionnaire Title"
                  type="text"
                  InputProps={{ disableUnderline: true }}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  sx={{
                    marginTop: "17px",
                    "& .MuiInputBase-input": {
                      padding: "12px",
                      borderRadius: "6px",
                    },
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel
                  variant="standard"
                  style={{
                    marginTop: "-25px",
                    width: "100%",
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="c14px400"
                      style={{
                        textAlign: "left",
                        color: palette.neutral[500],
                      }}
                    >
                      Enter a short description of the questionnaire
                    </Typography>
                    <img
                      src={asterisk}
                      style={{ marginBottom: "10px" }}
                      alt="icon"
                    />
                  </Stack>
                </InputLabel>
                <TextField
                  fullWidth
                  required
                  multiline
                  data-cy="input__questionnaireDescription"
                  // error={error ? true : false}
                  // helperText={error}
                  variant="outlined"
                  placeholder="Short Description "
                  type="text"
                  InputProps={{ disableUnderline: true, padding: "2px" }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  sx={{
                    marginTop: "17px",
                  }}
                />
              </FormControl>
            </Stack>
          </Paper>

          <Stack
            sx={{
              width: "100%",
            }}
          >
            <Button
              data-cy="button__next"
              disabled={!title || !description}
              variant="contained"
              color="primary"
              endIcon={<ArrowForward sx={{ height: "16px", width: "16px" }} />}
              sx={{
                padding: "10px 16px",
                textTransform: "capitalize",
                marginBottom: "24px",
              }}
              onClick={handleEditQuestionnaireInfo}
            >
              Next
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Loading>
  );
}
